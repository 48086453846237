import { getCookie } from '@libs/utils/helpers/getSafeCookies';

const useACPSignInExperiment = () => {
  const testCookieName = 'dx-ac-acp-signin';

  const VARIANTS = {
    A: 'control',
    B: 'with-ac-acp-signin'
  };

  const currentResult = getCookie(testCookieName, false);

  return {
    resultIsWithACPSignIn: !!currentResult?.includes(VARIANTS.B),
  };
};

export default useACPSignInExperiment;