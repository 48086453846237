import { useImperativeHandle, useState, forwardRef } from 'react';

import { Text, IconCheck, IconX } from '@consumidor-positivo/aurora';

import './styles.scss';

interface CopiedCodeWarningProps {
  visible: boolean;
  typeCode: string;
}

const CopiedCodeWarning = forwardRef((_, ref) => {
  const [warningData, setWarningData] = useState<CopiedCodeWarningProps>({
    visible: false,
    typeCode: 'Boleto',
  });

  useImperativeHandle(ref, () => ({
    open({ visible, typeCode }: CopiedCodeWarningProps) {
      setWarningData({ visible, typeCode });
    },
  }));

  return (
    <div
      className={`copied-code-warning ${
        warningData?.visible
          ? 'copied-code-warning--visible'
          : 'copied-code-warning--hidden'
      }`}
    >
      <div className="copied-code-warning--container-desc">
        <IconCheck />
        <Text weight="bold">
          Código do {warningData?.typeCode}{' '}
          <Text as="span" weight="regular">
            copiado com sucesso!
          </Text>
        </Text>
      </div>
      <IconX />
    </div>
  );
});

export default CopiedCodeWarning;
