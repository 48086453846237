import { useEffect } from 'react';
import classnames from 'classnames';
import { ErrorBoundaryProps, withErrorBoundary } from 'react-error-boundary';
import { useAds } from './useAds';
import { AdFormat } from './constants';
import './styles.scss';

interface AdsSlotProps {
  id: string;
  path: string;
  className?: string;
  refreshTime?: number;
  format?: string;
  sizeMapping?: googletag.SizeMappingArray;
}

const errorBoundaryProps: ErrorBoundaryProps = {
  fallback: null,
  onError: (error, info) => {
    console.error('Error on loading AdsSlot:', error, info);
  },
};

export const AdsSlot = withErrorBoundary(
  ({
    id,
    path,
    className,
    refreshTime = 30,
    format,
    sizeMapping,
  }: AdsSlotProps) => {
    if (import.meta.env.VITE_ENV === 'dev')
      path = '/6355419/Travel/Europe/France/Paris';

    const { createSlot, destroySlot } = useAds();

    useEffect(() => {
      const slot = createSlot(id, path, {
        className,
        refreshTime,
        format: format as AdFormat,
        sizeMapping,
      });

      return () => {
        if (slot) destroySlot(slot);
      };
    }, [
      id,
      path,
      className,
      refreshTime,
      format,
      sizeMapping,
      createSlot,
      destroySlot,
    ]);

    return (
      <div className={classnames('admanager', className)}>
        <div className="admanager__content" id={id} />
      </div>
    );
  },
  errorBoundaryProps
);
