export const currencyToLocale = (value: number, showCents = true) => {
  if (isNaN(value)) return '';

  const options = {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: showCents ? 2 : 0,
  };

  return value.toLocaleString('pt-BR', options);
};

export interface TypesSeparateValueParts {
  wholePart: string;
  decimalPart: string;
  valuePTBR: undefined | string;
}
/**
 * Separates the whole and decimal parts of a given value and returns them along with the formatted number in Brazilian Portuguese currency.
 *
 * @param {number} value - The value to be separated into parts.
 * @returns {Object} An object containing the wholePart, decimalPart, and valuePTBR properties.
 */
export const separateValueParts = (value: number): TypesSeparateValueParts => {
  const resDefault = {
    wholePart: '',
    decimalPart: '',
    valuePTBR: undefined,
  };

  if (isNaN(value)) {
    console.error('[separateValueParts]: Please provide a valid number.');
    return resDefault;
  }

  const formattedNumber = currencyToLocale(value);
  const parts = formattedNumber.split(',');

  if (parts.length !== 2) {
    console.error(
      '[separateValueParts]: Error separating the parts of the formatted number.'
    );
    return resDefault;
  }

  return {
    wholePart: parts[0],
    decimalPart: parts[1],
    valuePTBR: formattedNumber,
  };
};

export const safeCurrencyValue = (value?: string | number, emptyChar = '-') => {
  if (value === undefined || value === null || value === '') {
    return emptyChar;
  }

  if (typeof value === 'number') {
    return currencyToLocale(value);
  }

  return `R$ ${value}`;
};
