import { lazy } from 'react';
import { Route } from 'react-router-dom';
import { pageNames } from '@common/routes/pageNames';

const MyScore = lazy(() => import('@tally/pages/MyScore'));
const ScoreKba = lazy(() => import('@tally/pages/ScoreKba'));
const ScoreError = lazy(() => import('@tally/pages/ScoreError'));

export function Router() {
  return (
    <>
      <Route path={pageNames.myScore.path} element={<MyScore />} />
      <Route path={pageNames.scoreKbaQuestions.path} element={<ScoreKba />} />
      <Route path={pageNames.scoreError.path} element={<ScoreError />} />
    </>
  );
}
