import { z } from 'zod';
import * as v from "valibot";

export const invalidEmailErrorMessage = 'Ops, parece que este e-mail não existe ou está errado. Verifique o e-mail e, caso o erro persista, fale conosco.';

export const isValidEmail = z
  .string()
  .email(
    'Parece que este e-mail não existe ou está errado. Verifique o e-mail e, caso o erro persista, fale conosco.'
  );


export const isValidEmailValibot = v.pipe(
  v.string(),
  v.nonEmpty("-"),
  v.rawCheck(({ addIssue, dataset }) => {
    const value = dataset.value as string;

    if (value.length > 0 && !value.includes("@")) {
      addIssue({ message: "-" });
      return false;
    }

    if (value.includes("@") && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
      addIssue({ message: invalidEmailErrorMessage });
      return false;
    }

    return true;
  })
);
