import { cpf } from 'cpf-cnpj-validator';
import { z } from 'zod';
import * as v from "valibot";

export const cpfErrorMessage = 'Ops, CPF inválido. Por favor, confira os dígitos do seu CPF e tente novamente.';

//TODO: remover depois que tudo estiver com valibot
export const isValidCpf = z
  .string()
	.optional()
  .refine((val) => {
		if(!val) return true;
		
		return cpf.isValid(val), {
			message: cpfErrorMessage
		}
	});

export const isValidCpfValibot = v.pipe(v.string(), v.nonEmpty(cpfErrorMessage),
	v.rawCheck(({ addIssue, dataset }) => {
		const value = dataset.value as string;

		if(value.length > 0 && value.length < 14) {
			addIssue({ message: "-" });
			return false;
		};

		if(value.length === 14) {
			if(cpf.isValid(value)) return true;

			addIssue({ message: cpfErrorMessage });
			return false;
		}
		
		return true;
	})
);