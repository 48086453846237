import classNames from 'classnames';
import { Conditional, Text } from '@consumidor-positivo/aurora';
import './styles.scss';

type TitleCardOfferProps = {
  text: string;
  description?: string;
  limit?: number;
  limitMessage?: string;
  smallCard?: boolean;
};

export const TitleOfferCard = ({
  text,
  description,
  limitMessage,
  limit,
  smallCard = false,
}: TitleCardOfferProps) => {

  const textClasses = classNames('common-offer-card__title', {
    'common-offer-card__title--small': smallCard,
  });

  if (Boolean(limit)) {
    return (
      <div className={textClasses}>
        <Text
          variant="body-small"
          variantDesk="body-medium"
          color="secondary"
          weight="semibold"
        >
          {text}
        </Text>
        <Text variant="heading-small" weight="bold">
          {limitMessage}
        </Text>
      </div>
    );
  }

  return (
    <div className={textClasses}>
      <Text variant="heading-micro" weight="bold">
        {text}
      </Text>
      <Conditional
        condition={smallCard}
        renderIf={
          <Text variant="body-small" color="secondary">
            {description}
          </Text>
        }
      />
    </div>
  );
};
