export const isSubscriptionStorage = (): Promise<Boolean> => {
  return new Promise((resolve, reject) => {
    var isSubscribed = window.localStorage.getItem('_hmsls');
    if (isSubscribed) {
      resolve(true);
    } else {
      resolve(false);
    }
  });
};
