import { Card } from '@consumidor-positivo/aurora';
import { SHAnnuity } from '../../../../services/sortingHat/types';
import { getConditionalAnnuity } from '../../../../helpers/getConditionalAnnuity';

type HighlightCardOfferProps = {
  highlights_ac: Array<{ description: string }>;
  category: string;
  annuity?: SHAnnuity;
};

export const HighlightsOfferCard = ({
  highlights_ac,
  category,
  annuity,
}: HighlightCardOfferProps) => {
  const highlightTitle = {
    first:
      category === 'loans'
        ? 'Valor'
        : category === 'collections'
        ? 'Benefício'
        : 'Anuidade',
    second:
      category === 'loans'
        ? 'Taxas'
        : category === 'collections'
        ? 'Condição'
        : 'Benefício',
  };

  const highlights = [
    {
      title: highlightTitle.first,
      description: annuity
        ? getConditionalAnnuity(annuity)
        : highlights_ac[0]?.description,
    },
    {
      title: highlightTitle.second,
      description: highlights_ac[1]?.description,
    },
  ]; // TODO: refactor this to use a map function, when storyblok is ready

  return <Card.Emphasis height={111} content={highlights} />;
};
