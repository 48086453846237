import { createPortal } from 'react-dom';
import { Card, Conditional } from '@consumidor-positivo/aurora';
import { Title, Highlights, Tag, Buttons } from './components';
import { RedirectModal } from '../GeneralComponents/RedirectModal';

import { useOfferRedirect } from '../../hooks/useOfferRedirect';
import { useOfferCard } from './hooks';

import { SHOffer } from '../../services/sortingHat/types';
import { ExtraFunctionsType, UserType } from '../../types';
import './styles.scss';

type OfferCardProps = {
  offer: SHOffer;
  user: UserType;
  smallCard?: boolean;
  hasTestAB?: boolean;
  onClickOffer?: ExtraFunctionsType;
  onClickDetailOffer?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onViewOffer?: (
    entries: IntersectionObserverEntry[],
    observer: IntersectionObserver
  ) => void;
};

const OfferCard = ({
  offer,
  user,
  smallCard,
  hasTestAB,
  onClickOffer,
  onClickDetailOffer,
  onViewOffer,
}: OfferCardProps) => {
  const { isLoading, handleClickOffer, isRedirectModalOpen } = useOfferRedirect(
    {
      offer,
      user,
      extraFunctions: onClickOffer,
      hasTestAB,
    }
  );
  const { cardRef } = useOfferCard({ onViewOffer });

  const {
    generalInfo: { limit, limitMessage, preApproved, annuity },
    content: {
      image_logo,
      category,
      title,
      highlights_ac,
      button_text,
      description,
      detail_page,
      partner_friendly_name,
      feature_information,
    },
  } = offer;

  const showDetailButton =
    Boolean(detail_page) && Boolean(partner_friendly_name);
  const partnerName = partner_friendly_name || 'o parceiro';
  const tagType = limit || preApproved ? 'approved' : 'default';

  if (smallCard) {
    return (
      <div ref={cardRef} className="creditkit-offer-card">
        <Card.Root border={false} color="secondary">
          <Card.Container gap={16}>
            <Tag text={feature_information} type={tagType} />
            <Card.Container direction="row" alignItems="center" gap={16}>
              <Card.Image
                height={64}
                src={image_logo.filename}
                alt={image_logo.alt}
              />
              <Title
                text={title}
                description={description}
                limit={limit}
                limitMessage={limitMessage}
                smallCard
              />
            </Card.Container>
            <Buttons
              text={button_text}
              showDetailButton={showDetailButton}
              onClickOffer={handleClickOffer}
              onClickDetailOffer={onClickDetailOffer}
              loading={isLoading}
              smallCard
            />
          </Card.Container>
        </Card.Root>
        <Conditional
          condition={isRedirectModalOpen}
          renderIf={
            <>
              {createPortal(
                <RedirectModal
                  isOpen={isRedirectModalOpen}
                  partnerName={partnerName}
                  offerImage={image_logo?.filename}
                />,
                document.body
              )}
            </>
          }
        />
      </div>
    );
  }

  return (
    <div ref={cardRef} className="creditkit-offer-card">
      <Card.Root maxWidth={308} height={474}>
        <Card.Container gap={16} alignItems="center">
          <Tag text={feature_information} type={tagType} />
          <Card.Image
            height={72}
            src={image_logo.filename}
            alt={image_logo.alt}
          />
          <Title
            text={title}
            description={description}
            limit={limit}
            limitMessage={limitMessage}
          />
          <Highlights
            highlights_ac={highlights_ac}
            category={category}
            annuity={annuity}
          />
          <Buttons
            text={button_text}
            showDetailButton={showDetailButton}
            onClickOffer={handleClickOffer}
            onClickDetailOffer={onClickDetailOffer}
            loading={isLoading}
          />
        </Card.Container>
      </Card.Root>
      <Conditional
        condition={isRedirectModalOpen}
        renderIf={
          <>
            {createPortal(
              <RedirectModal
                isOpen={isRedirectModalOpen}
                partnerName={partnerName}
                offerImage={image_logo?.filename}
              />,
              document.body
            )}
          </>
        }
      />
    </div>
  );
};

export default OfferCard;
