import constants from '@common/constants';
import { legacyBase64Decrypt } from '@libs/utils/helpers/base64';
import { addCookie, removeCookie, checkCookie } from '@libs/utils/helpers/getSafeCookies';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import useUserAuthentication from '@admission/hooks/useUserAuthentication';

export function useAtendeAuth() {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const atendeLoginData = searchParams.get('atende');

	const { addCookieRLTracksAuth } = useUserAuthentication();

	const handleFlowCookies = {
		signin: handleAtendeSignInCookies,
		signup: handleAtendeSignUpCookies
	}

  function isAtendeLogin() {
		const atendeFlow = atendeLoginData === "signup" ? "signup" : "signin";

    if (atendeLoginData) {
			handleFlowCookies[atendeFlow]();
    }

		addCookieRLTracksAuth('atende-sign-in');
  }

	function handleAtendeSignInCookies() {
		const tokenAtende = legacyBase64Decrypt(atendeLoginData!);
		const dataAtende = JSON.parse(tokenAtende);
		const oneHundredTwentyDays = new Date();
		oneHundredTwentyDays.setDate(oneHundredTwentyDays.getDate() + 120);

		addCookie({
			key: constants.legacyAuth.TOKEN,
			value: dataAtende.auth.access_token,
			expires: oneHundredTwentyDays,
		});

		addCookie({
			key: constants.legacyAuth.SUBJECT,
			value: JSON.stringify(dataAtende.user),
			encrypt: true,
			expires: oneHundredTwentyDays,
		});

		addCookie({
			key: 'ATENDE_TOKEN',
			value: atendeLoginData!,
		});
	}

	function handleAtendeSignUpCookies() {
		addCookie({
			key: 'ATENDE_SIGNUP',
			value: "true",
		});
	}

  useEffect(() => {
		if(!atendeLoginData) {
			const hasAtendeSession = checkCookie('ATENDE_TOKEN') || checkCookie('ATENDE_SIGNUP');

			if(hasAtendeSession) {
				removeCookie(constants.legacyAuth.SUBJECT);
				removeCookie(constants.legacyAuth.TOKEN);
			}

			removeCookie('ATENDE_TOKEN');
			removeCookie('ATENDE_SIGNUP');

		}else {
			isAtendeLogin();
		};
		
  }, [atendeLoginData]);

	return {
		atendeLoginData
	}
}
