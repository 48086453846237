import { getCookie } from '@libs/utils/helpers/getSafeCookies';
import { getAnonymousID, getSessionID } from 'redline-client-sdk';

import { getFbAvailableCookies } from '@libs/utils/helpers/getFacebookPixel';
import { getGAAvailableCookies } from '@libs/utils/helpers/getGoogleAnalyticsCookies';
import { maskCpf } from '@libs/utils/masks/cpf';
import { maskCellphone } from '@libs/utils/masks/cellphone';
import { getApp } from '@libs/utils/helpers/getApplication';
import { FireboltPayload } from './types';

export const formatFireboltPayload = (payload: FireboltPayload) => {
  const application =
    getApp() === 'acordocerto' ? 'ac' : 'consumidorpositivo-log';

  const item = {
    cpf: maskCpf(payload.cpf),
    full_name: payload.fullName,
    email: payload.email,
    main_phone: maskCellphone(payload.phone),
    date_of_birth: payload.birthdate,
    choosen_card: payload.chosenCard,
    issuer: payload.partner,
    product_slug: payload.chosenCard,
    application,
  };

  const metadata = {
    start_source_url: getCookie('start_source_url'),
    user_agent: window.navigator.userAgent,
    anonymousId: getAnonymousID(),
    sessionId: getSessionID(),
    fbCookies: {
      fcbId: getFbAvailableCookies().fbc,
      fbpId: getFbAvailableCookies().fbp,
    },
    gaCookies: {
      gaId: getGAAvailableCookies().ga,
    },
  };

  return { item, metadata };
};
