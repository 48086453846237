import { useEffect } from 'react';
import { matchRoutes, RouteMatch, useLocation } from 'react-router-dom';
import { Redline } from 'redline-client-sdk';
import untilLoad from '../helpers/untilLoad';

declare global {
  interface Window {
    DD_RUM: any;
    cpRedline: Redline;
  }
}

type UserDatadogTrackViewProps = {
  pagePaths: Record<string, string>[];
  privatePagePaths: string[];
  publicPagePaths: string[];
};

export default function useDatadogTrackView({
  pagePaths,
  privatePagePaths,
  publicPagePaths,
}: UserDatadogTrackViewProps) {
  let location = useLocation();

  async function runDatadogSetUser() {
    const getUser = localStorage.getItem('ac/AUTH_USER_DATA');
    const user = getUser ? JSON.parse(getUser || '') : {};
    const isPrivatePages = privatePagePaths.includes(location.pathname);
    const isPublicPages = publicPagePaths.includes(location.pathname);

    await untilLoad(() => window?.cpRedline?.ready, 7000);

    if (isPrivatePages) {
      window?.DD_RUM?.setUser({
        id: window?.cpRedline?.sessionID,
        sessionID: window?.cpRedline?.sessionID,
        appVersion: window?.cpRedline?.opts?.appVersion,
        customerIdHash: user?.customerIdHash,
        document: user?.documento,
        email: user?.email,
      });
    }

    if (isPublicPages) {
      window?.DD_RUM?.setUser({
        id: window?.cpRedline?.sessionID,
        sessionID: window?.cpRedline?.sessionID,
        appVersion: window?.cpRedline?.opts?.appVersion,
      });
    }
  }

  useEffect(() => {
    const routeMatches = matchRoutes(pagePaths, location.pathname);
    const viewName = routeMatches && computeViewName(routeMatches);
    if (viewName) {
      window?.DD_RUM?.onReady(function () {
        window?.DD_RUM?.startView({ name: viewName });
        runDatadogSetUser();
      });
    }
  }, [location.pathname]);
}

function computeViewName(routeMatches: RouteMatch[] | undefined) {
  let viewName = '';
  if (routeMatches) {
    for (let index = 0; index < routeMatches.length; index++) {
      const routeMatch = routeMatches[index];
      const path = routeMatch.route.path;
      if (!path) {
        continue;
      }

      if (path.startsWith('/')) {
        viewName = path;
      } else {
        viewName += viewName.endsWith('/') ? path : `/${path}`;
      }
    }
  }

  return viewName || '/';
}
