/* eslint-disable react-hooks/exhaustive-deps */
import { SHA256 } from 'crypto-js';
import React, { ReactNode, createContext, useCallback, useState } from 'react';
import { getAnonymousID, getSessionID } from 'redline-client-sdk';

import { delay } from '@libs/utils/helpers/delay';
import { getDDD } from '@libs/utils/helpers/getDDD';
import { base64Encrypt } from '@libs/utils/helpers/base64';
import { isAndroid, isIOS } from '@libs/utils/helpers/mobileOS';

import { getCookie } from '../../../helpers/getCookies';
import { getGAAvailableCookies } from '../../../helpers/getGACookies';
import { getFbAvailableCookies } from '../../../helpers/getFacebookPixel';
import {
  debugSHCache,
  debugSHDecideDetails,
  debugSHPayload,
  debugSHSingleCallPerPath,
  debugSHTimer,
} from '../../../helpers/debug';

import {
  SHContextType,
  DecisionParams,
  TrackParams,
  SHReqProps,
  SafeUser,
  Identifier,
  HandlePayloadParam,
  SHOffer,
} from '../types';

import { getRDG } from '../utils/getRDG';
import {
  SHCache,
  SHCacheClear,
  SHCacheCount,
  SHCacheDone,
  SHCachePaths,
} from '../utils/cache';

import { useApiSortingHat } from '../api/useApiSortingHat';

import { getOffersFromDecide } from '../utils/formatters';

const SHContext = createContext<SHContextType>({} as SHContextType);

type SHProvider = {
  children: ReactNode;
  mode: 'dev' | 'prod';
  debug?: boolean;
  appName: string;
  shKey?: string;
  baseURL?: string;
  maxCallNumber?: number;
  identifier?: Identifier;
  singleCallPerPath?: boolean;
};

const SHProvider: React.FC<SHProvider> = ({
  children,
  appName,
  shKey,
  baseURL,
  maxCallNumber,
  identifier = 'userId',
  singleCallPerPath,
}) => {
  const { SHTrack, SHDecide } = useApiSortingHat({ baseURL, shKey });
  const numberOfCalls = maxCallNumber || 2;

  const [trackDone, setTrackDone] = useState(SHCache().track?.done);
  const [decideDone, setDecideDone] = useState(SHCache().decide?.done);
  const [trackCount, setTrackCount] = useState(SHCacheCount('track', 'get'));
  const [decideCount, setDecideCount] = useState(SHCacheCount('decide', 'get'));
  const [SHLoading, setSHLoading] = useState(SHCache().offers.length < 1);
  const [SHError, setSHError] = useState(false);
  const [decisionsList, setDecisionsList] = useState<SHOffer[]>(
    SHCache().offers
  );

  const isAC = appName === 'ac';
  const device = isAndroid() ? 'android' : isIOS() ? 'ios' : 'web';
  const anonymousId = getAnonymousID();
  const sessionId = getSessionID();
  const workflow_id = isAC ? 'ac-tree-react' : 'cp-tree';

  const callSHTrack = useCallback(
    async (params?: TrackParams) => {
      try {
        const shouldForceCall = params?.forceCall || false;
        if (singleCallPerPath && !shouldForceCall) {
          const paths = SHCachePaths('track', 'get');
          debugSHSingleCallPerPath({
            paths,
            shouldForceCall,
            debugFor: 'TRACK',
          });
          if (paths.includes(window.location.href)) {
            return;
          }
        }

        if (trackCount >= numberOfCalls && !shouldForceCall) return;
        const payload = handlePayload({
          user: params?.user,
          payloadFor: 'track',
          customIdentifier: params?.customIdentifier,
        });

        params?.decisionEnrichedEvent();
        if (!anonymousId || !sessionId) {
          await delay(5000);
          SHTrack(payload);
        } else {
          SHTrack(payload);
        }

        if (!params?.forceCall) {
          setTrackCount(trackCount + 1);
          SHCacheCount('track', 'set');
          SHCachePaths('track', 'set');
        }

        if (!trackDone) {
          SHCacheDone('track', 'set');
          setTrackDone(true);
        }
      } catch (err) {
        console.error('[SH_TRACK]', err);
      }
    },
    [trackDone, trackCount, decisionsList]
  );

  const callSHDecision = useCallback(
    async (params?: DecisionParams) => {
      try {
        debugSHTimer({ status: 'start', timerFor: 'SH_DECISION' });
        const shouldForceCall = params?.forceCall || false;
        const offersFromCache = SHCache().offers;

        if (singleCallPerPath && !shouldForceCall) {
          const paths = SHCachePaths('decide', 'get');
          debugSHSingleCallPerPath({
            debugFor: 'DECIDE',
            shouldForceCall,
            paths,
          });

          if (paths.includes(window.location.href)) {
            return offersFromCache;
          }
        }
        if (decideCount >= numberOfCalls && !shouldForceCall) {
          return offersFromCache;
        }

        if (!params?.token && !params?.ignoreToken) {
          throw new Error('token not found');
        }

        if (offersFromCache?.length > 0 && decideCount > numberOfCalls) {
          debugSHCache({
            decideCount,
            numberOfCalls,
            offersFromCache,
          });

          setDecisionsList(offersFromCache);
          return offersFromCache;
        }

        const decideEncodedPayload = handlePayload({
          user: params?.user,
          payloadFor: 'decide',
          customIdentifier: params?.customIdentifier,
        });

        params?.decisionRequestEvent();

        if (params?.delayTime) await delay(params?.delayTime);
        const response = await SHDecide({
          data: decideEncodedPayload.data,
          token: params?.token,
        });

        const offers = getOffersFromDecide(response?.data?.result?.decisions);
        debugSHDecideDetails({ offers, offersFromCache, params, response });

        const noOffers = offers?.length < 1;
        const decisionOutcomeEventPayload = noOffers
          ? []
          : offers?.map((result) => {
              return {
                productCategory: result?.generalInfo?.category,
                productId: result?.generalInfo?.slug,
              };
            });

        params?.decisionOutcomeReceivedEvent(decisionOutcomeEventPayload);

        if (noOffers) {
          throw new Error('No content found in SH decide');
        }

        setDecisionsList(offers);
        if (!shouldForceCall) {
          setDecideCount(decideCount + 1);
          SHCacheCount('decide', 'set');
          SHCachePaths('decide', 'set');
        }

        setSHError(false);
        SHCache(offers);
        return offers;
      } catch (err) {
        console.error('[SH_DECISION]: ', err);
        setSHError(true);
        return [];
      } finally {
        setSHLoading(false);
        setDecideDone(true);
        SHCacheDone('decide', 'set');

        debugSHTimer({ status: 'end', timerFor: 'SH_DECISION' });
      }
    },
    [decideDone, decideCount, decisionsList]
  );

  function handlePayload({
    user,
    customIdentifier,
    payloadFor,
  }: HandlePayloadParam) {
    const safeUser: SafeUser = {
      document: user?.documento || user?.document || '',
      phone: user?.celular || user?.phone || '',
      birthdate: user?.dataNascimento || user?.birthdate || '',
      full_name: user?.nome || user?.full_name || '',
      email: user?.email || '',
      uuid: user?.uuid || '',
      created_at: user?.created_at || user?.createdAt || '',
    };

    const identifierKeys = {
      userId: safeUser?.uuid,
      userDocument: SHA256(safeUser.document).toString(),
      anonymousId,
      custom: customIdentifier,
    };

    const identifierValue = identifierKeys[identifier];

    const payload: Partial<SHReqProps> = {
      anonymousId,
      document: safeUser?.document,
      cpf: safeUser?.document,
      device,
      workflow_id,
      identifier: identifierValue,
    };

    if (payloadFor === 'track') {
      payload.mainPhone = safeUser?.phone;
      payload.fullName = safeUser?.full_name;
      payload.email = safeUser?.email;
      payload.birthdate = safeUser?.birthdate;
      payload.sessionId = getSessionID();
      payload.fbCookies = getFbAvailableCookies();
      payload.gaCookies = getGAAvailableCookies();
      payload.ddd = getDDD(safeUser?.phone || '');
      payload.rdg = getRDG(appName, safeUser?.document || '');
      payload.sourceUrl =
        getCookie('start_source_url') || window?.location.href;
      payload.created_at = safeUser?.created_at;
    }

    debugSHPayload({ payloadFor, customIdentifier, user: safeUser, payload });
    const encodedPayload = base64Encrypt(JSON.stringify(payload));
    return { data: encodedPayload };
  }

  const resetContext = () => {
    setTrackDone(false);
    setDecideDone(false);
    setTrackCount(0);
    setDecideCount(0);
    setSHLoading(true);
    setDecisionsList([]);

    SHCacheClear();
  };

  return (
    <SHContext.Provider
      value={{
        callSHDecision,
        callSHTrack,
        decisionsList,
        trackDone,
        decideDone,
        SHLoading,
        SHError,
        resetContext,
      }}
    >
      {children}
    </SHContext.Provider>
  );
};

export { SHProvider, SHContext };
