/* import { StrictMode } from 'react'; */
import * as ReactDOM from 'react-dom/client';
import { RedlineProvider } from '@libs/redline';
import { SHProvider } from '@libs/creditkit';
import { DogxProvider } from '@libs/dogx';
import { AdsProvider } from '@libs/ads';

import App from './App';
import { createRedline } from 'redline-client-sdk';
import handlePreloadError from '@common/helpers/handlePreloadError';

window.addEventListener('vite:preloadError', handlePreloadError);

const acCreateRedline = createRedline({
  writeKey: import.meta.env.VITE_REDLINE_WRITE_KEY,
  mode: import.meta.env.VITE_ENV,
  logs: false,
  domain: 'ac',
  appName: import.meta.env.VITE_REDLINE_APP_NAME,
  appVersion: import.meta.env.VITE_DEPLOY_VERSION,
  autoTracking: {
    documentHide: false,
  },
  /* nativeEvents: true, */
});

const cpCreateRedline = createRedline({
  writeKey:
    import.meta.env.VITE_ENV === 'prod'
      ? '67d0862e-c6dd-4c9b-af98-958815952b10'
      : '26566c23-2e31-44f9-bc93-1ba5593238c2',
  mode: import.meta.env.VITE_ENV,
  logs: false,
  appName: import.meta.env.VITE_REDLINE_APP_NAME,
  appVersion: import.meta.env.VITE_DEPLOY_VERSION,
  autoTracking: {
    documentHide: false,
  },
  /* nativeEvents: true, */
});

const rl = import.meta.env.VITE_CUSTOM_BUILD
  ? cpCreateRedline
  : acCreateRedline;

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <RedlineProvider
    instance={rl}
    appName={import.meta.env.VITE_REDLINE_APP_NAME}
  >
    <DogxProvider>
      <SHProvider
        appName="ac"
        mode={import.meta.env.VITE_ENV}
        debug={false}
        shKey={import.meta.env.VITE_SORTING_HAT_API_KEY}
        baseURL={import.meta.env.VITE_SORTING_HAT_API_URL}
        identifier="userDocument"
      >
        <AdsProvider>
          <App />
        </AdsProvider>
      </SHProvider>
    </DogxProvider>
  </RedlineProvider>
);
