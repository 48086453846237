import { legacyBase64Encrypt, legacyBase64Decrypt } from "@libs/utils/helpers/base64";

// [TEMP]:  to adapt to the legacy project
export const getFromLS = (key: string) => {
  const encryptedData = localStorage.getItem(key);
  const returnMyDebts = () => window.location.href = "/minhas-dividas";

  const isPaymentDate = key === "PAYMENT_DATE"
  if(!encryptedData && isPaymentDate) return null

  if (!encryptedData) {
    returnMyDebts();
    return null;
  }

  try {
    return JSON.parse(legacyBase64Decrypt(encryptedData));
  } catch (error) {
    returnMyDebts();
    return null;
  }
};

export const setInLS = (key: string, value: { [key: string]: string | number | any }) => {
  const encryptedValue = legacyBase64Encrypt(JSON.stringify(value))

  return localStorage.setItem(key, encryptedValue)

}

export const pushUrl = () => {
  const currentUrl = window.location.pathname + window.location.search;
  window.history.pushState(null, '', `${currentUrl}`);
}
