import {
  Button,
  IconBarCode,
  IconCopy,
  Text,
  IconWhatsapp,
} from '@consumidor-positivo/aurora';
import CopiedCodeWarning from '@common/components/DebtsCarousel/agreementsComponents/CopiedCodeWarning';
import { Billet as BilletProps } from '@dues/pages/Partner/ClosedAgreement/types';

import './styles.scss';

interface ClosedAgreementBilletProps {
  billet: BilletProps;
  shareOnWhatsApp: ({}) => void;
  warningRef: React.MutableRefObject<HTMLElement | null>;
  handleCopyBilletCode: any;
  buttonText: any;
  handleBilletDownload: any;
}

const Billet = ({
  billet,
  shareOnWhatsApp,
  warningRef,
  handleCopyBilletCode,
  buttonText,
  handleBilletDownload,
}: ClosedAgreementBilletProps) => {
  return (
    <div className="billet-section">
      <CopiedCodeWarning ref={warningRef} />
      <span className="payment-section-v2__title">
        Copie o código para pagar
        <IconBarCode />
      </span>

      <ol>
        <li>
          <Text color="secondary">
            Para pagar pelo Internet Banking ou app de pagamentos, copie o
            código.
          </Text>
        </li>
        <li>
          <Text color="secondary">
            Para pagar em qualquer banco, caixa eletrônico ou lotérica, por
            favor imprima o boleto.
          </Text>
        </li>
      </ol>

      <Text color="secondary" className="billet-section__billet-code">
        {billet?.linhaDigitavel}
      </Text>

      <div className="billet-section__btn-container">
        <Button
          expand="x"
          type="primary"
          onClick={() => handleCopyBilletCode(billet?.linhaDigitavel)}
        >
          <IconCopy />
          {buttonText}
        </Button>
        <Button expand="x" type="outlined" onClick={handleBilletDownload}>
          Baixar boleto
        </Button>
        <Button
          expand="x"
          type="ghost"
          onClick={() =>
            shareOnWhatsApp({
              code: billet?.linhaDigitavel,
              typeCode: 'Boleto',
            })
          }
        >
          <IconWhatsapp />
          Compartilhar
        </Button>
      </div>
    </div>
  );
};
export default Billet;
