import { dateToLocale } from '@libs/utils/helpers/dateToLocale';
import { currencyToLocale } from '@libs/utils/helpers/currencyToLocale';

import {
  SimulatedDebt,
  SimulatedPaymentMethod,
  SimulatedPaymentOption,
} from '@common/apis/simulated-debt/types';
import { UserResponseProps } from '@admission/apis/onboarding/types';
interface ExtraInfoServiceTypes {
  user?: UserResponseProps;
  debt?: SimulatedDebt;
  // agreement?: Agreement;
  paymentOption?: SimulatedPaymentOption;
  paymentMethod?: SimulatedPaymentMethod;
}

const getRenegotiationDate = (date: string) => {
  return `<strong> Data da renegociação </strong> </br> ${dateToLocale(date)}`;
};

const getProperty = (
  debt: SimulatedDebt | undefined,
  propertyName: string
): boolean => {
  return !!debt?.propriedades?.some(
    (propriedade: any) =>
      propriedade?.chave === propertyName && propriedade?.valorBoolean
  );
};

const selectorClickTermBlob = (partnerId: number | undefined) => {
  return `selector-term-${partnerId}-blob`;
};

const termsAndConditionsOfAtivossa = ({ user, debt, paymentOption }: any) => {
  return `<p><strong>Atenção: A efetivação deste acordo somente ocorrerá mediante a confirmação do pagamento do boleto bancário em anexo. Em caso de não recebimento do boleto, por gentileza, entrar em contato pelo telefone 0800-644-3030.</strong></p>

	<p>CONTRATO Nº ${debt?.codigoTitulo}<br />${user?.nome}</p>
  <p>A presente Carta de Confirmação de Acordo decorre de pacto negocial efetuado entre a Ativos S.A e V.Sa.e tem por objetivo informar os termos do acordo firmado entre as partes para a(s) operação(ões) abaixo relacionada(s), adquiridas do Banco Bradesco S.A., amparada pela resolução do CMN/Banco Central do Brasil no. 2686 de 26 de janeiro de 2000, e art. 286 e seguintes do Código Civil Brasileiro. A confirmação do pagamento do boleto bancário em anexo, referente a primeira parcela ou parcela única, representa a aceitação e a confirmação dos termos da renegociação descrito a seguir:</p>

	<p><strong>CONTRATO</strong><br />${debt?.codigoTitulo}</p>

	<p><strong>PRODUTO</strong><br />${debt?.produto}</p>

	${debt?.origem ? `<p><strong>ORIGEM</strong><br /> ${debt?.origem}</p>` : ''}

	<p><strong>SALDO DEVEDOR</strong><br />${currencyToLocale(
    paymentOption?.valorDividaOriginal
  )}</p>

	<p><strong>VALOR NEGOCIADO</strong><br />${currencyToLocale(
    paymentOption?.valorAcordo
  )}</p>

	<p><strong>DESCONTO</strong><br />
    ${currencyToLocale(
      paymentOption?.valorDividaOriginal * paymentOption?.percentualDesconto
    )}
  </p>

	<p><strong>PARCELAS</strong><br />${paymentOption?.qtdParcela}</p>

	<p><strong>PRIMEIRO VENCIMENTO</strong><br />${dateToLocale(
    debt?.dataSimulacao
  )}</p>

	<p><strong>TOTAL</strong><br />${currencyToLocale(
    paymentOption?.valorAcordo
  )}</p>

	<p>1 - confissão irrevogável e irretratável da totalidade dos créditos acima relacionados;</p>

	<p>2 - ciência de que, em caso de parcelamento superior a 12 vezes, as parcelas serão corrigidas mensalmente com juros de 1%;</p>

	<p>3 - ciência de que o desconto porventura concedido na negociação é mera liberalidade do credor e de que o valor negociado será válido para pagamento somente nos prazos e termos acordados;</p>

	<p>4 - ciência de que o presente acordo não pressupõe o direito de retomar linhas de crédito junto a instituição cedente do crédito, tendo em vista essa decisão competir exclusivamente a ela;</p>

	<p>5 - ciência de que a Ativos S.A., após a confirmação de pagamento da 1a parcela ou parcela única do presente acordo, dará conhecimento de tal fato ao banco cedente para baixa do (s) respectivo (s) débito (s) no sistema de informação de crédito do Banco Central do Brasil - cláusula aplica-se somente a operações oriundas do Banco do Brasil;</p>

	<p>6 - ciência de que a baixa de eventual (ais) anotação nos órgãos de proteção ao crédito, referente (s) à (s) operação (ões) envolvida (s) neste acordo dar-se-á após a confirmação de pagamento da primeira parcela ou parcela única;</p>

	<p>7 - ciência de que a inadimplência das demais parcelas aqui acordadas ensejará nova anotação nos órgãos de proteção ao crédito.</p>

	<p>8 - ciência de que o não-pagamento de qualquer parcela no prazo ajustado importa em rescisão automática da renegociação e a perda das condições ora oferecidas, independentemente de interpelação ou notificação judicial ou extrajudicial, dando conhecimento de tal fato a instituição cedente, cientificando esta do inadimplemento do presente acordo.</p>

	<p>9 - ciência de que pode (m) existir outro (s) débito (s) que porventura não foi (ram) cedido (s) para a Ativos S.A. e que essa informação somente pode ser verificada diretamente com a instituição cedente, tendo em vista o fato de a Ativos não possuir ingerência em seu sistema de informações.</p>

	<p>10 - ciência de que havendo entre o(s) crédito (s) acima operação (ões) envolvida (s) em demanda (s) judicial (ais), este acordo somente terá validade após a quitação dos honorários advocatícios e das custas judiciais por V.Sa.</p>

	<p>A quitação da parcela única (pagamento à vista) ou das parcelas (pagamento à prazo), do presente acordo que compõe a (s) operação (ões) acima relacionada (s), juntamente com este documento, expressa a LIQUIDAÇÃO deste. A diferença entre o saldo devedor no momento da negociação e o valor efetivamente recebido para liquidação da (s) operação (ões) será considerado com desconto, concedido a cargo desta empresa. Para baixa/liberação de eventual garantia vinculada à(s) operação(ões) cedida(s), após a liquidação da dívida o cliente poderá solicitar o Recibo de Quitação à Ativos e dirigir-se a qualquer agência do Banco cedente portando o recibo e os documentos do bem vinculado à operação (matrícula do imóvel em caso de hipoteca, ou documentos do veículo em caso de gravame).</p>

	<p><strong>ATIVOS S.A. SECURITIZADORA DE CRÉDITOS FINANCEIROS</strong></p>`;
};

const termOfConfessionAndNovationKroton = () => {
  return `<p><b>INSTRUMENTO PARTICULAR DE CONFISSÃO E NOVAÇÃO DE DÍVIDA</b></p>
    <p>ALUNO: [**********] RA: [**********] SÉRIE: [**********] CURSO: [**********]
    Pelo presente instrumento e na melhor forma de direito, as partes a seguir qualificadas, [**********], sediada [**********], inscrita no CNPJ/MF sob n.º [**********], mantenedora do (a)  [**********], neste ato representada na forma de seu Estatuto Social, doravante denominada CREDORA e, de outro lado [**********], [**********], [**********], portador da cédula de identidade RG nº [**********] e do CPF nº [**********], residente e domiciliado à  [**********], denominado DEVEDOR; Resolvem, na melhor forma de direito, estipular os termos desta confissão e novação de dívida, com base nos artigos 360 e seguintes do Código Civil, a saber:</p>
    <p>O DEVEDOR, confessa ser devedor da CREDORA, em caráter irrevogável e irretratável, da importância total de R$  [**********], relativa a mensalidades escolares em atraso conforme Contrato de Prestação de Serviços Educacionais firmado entre as partes em [**********], reconhecendo como boa a origem da dívida, como descrito na tabela abaixo:</p>
    <p>1.1. O valor indicado na Cláusula 1 se refere ao valor principal mais correção. Para efeito de financiamento, sobre este valor, incidirá ainda taxas e juros de financiamento conforme política vigente, sendo que o valor total a ser financiado é de R$  [**********].</p>
    <p>1.2. Caso o DEVEDOR seja contemplado com desconto (de acordo com a política vigente), este apenas será concedido se o DEVEDOR pagar pontual e tempestivamente todas as parcelas do acordo. A impontualidade no pagamento de qualquer das parcelas do acordo acarretará no cancelamento do desconto eventualmente concedido, devendo o DEVEDOR pagar à CREDORA o valor integral da dívida.</p>
    <p>A dívida ora confessada pelo DEVEDOR, que, por força deste instrumento, é líquida, certa e exigível, deverá ser por ele totalmente liquidada através de pagamento parcelado, sendo que a primeira parcela, no valor de R$  [**********] é devidamente paga pelo DEVEDOR à CREDORA nesta data.</p>

    <p>2.1. Uma vez paga a primeira parcela da dívida pelo DEVEDOR à CREDORA, e não havendo óbice por esta última, o valor restante da dívida, equivalente a  [**********],   será cedido à instituição financeira aprovada pela CREDORA, que poderá conceder financiamento ao DEVEDOR conforme ajuste pactuado entre este último e referida instituição financeira.</p>

    <p>Verificando-se o não pagamento de qualquer uma das parcelas pactuadas com a instituição financeira, fica facultado à CREDORA efetuar a cobrança do saldo devedor, bem como optar pelo vencimento antecipado de toda a dívida ora confessada e novada pelo DEVEDOR, de pleno direito, independentemente de qualquer notificação judicial ou extrajudicial por parte da CREDORA. Fica, a CREDORA, desde já, autorizada a tomar todas as medidas judiciais cabíveis para fazer valer os seus direitos oriundos deste instrumento.</p>

    <p>3.1. É facultado, igualmente, à CREDORA, optar pelo vencimento antecipado da dívida ora confessada e novada caso ocorra a insolvência do DEVEDOR.</p>
    <p>3.2. Em havendo inadimplemento por parte do DEVEDOR quanto ao pagamento do saldo devedor, total ou parcialmente, aos valores das mensalidades em atraso serão acrescidos, juros de mora de 1% ao mês, correção monetária pela variação do IPCA (Índice Nacional de Preços ao Consumidor Amplo) ou na falta deste aquele que vier a substituí-lo, além de multa de 2% (dois por cento).</p>
    <p>3.3. O DEVEDOR arcará, ainda, com os custos relativos à renegociação realizada pela CREDORA, em percentual de 10% (dez por cento) sobre o valor do débito. No caso de inadimplemento de qualquer uma das parcelas negociadas, fica facultado, ainda, à CREDORA, a negativação do CPF/MF em nome do DEVEDOR junto aos órgãos de Proteção ao Crédito, além de promover a Ação Executiva, nos termos do artigo 585, inciso II, do Código de Processo Civil, bem como não aceitar a matrícula do DEVEDOR para o período subsequente.</p>
    <p>3.4. Na hipótese de descumprimento do presente Instrumento, o DEVEDOR incorrerá no pagamento de multa indenizatória de 10% (dez por cento) sobre o valor total de seu saldo devedor, atualizado conforme variação do IPCA, sem prejuízo de sua rescisão.</p>
    <p>Os valores devidos pelo DEVEDOR somente serão considerados como quitados pela CREDORA após a devida compensação de cheques ou créditos em conta corrente que a CREDORA vier a indicar, mediante a demonstração do pagamento via chancela em boleto bancário ou por qualquer outro meio comprobatório de pagamento em direito admitido.</p>

    <p>O presente instrumento é celebrado em caráter irrevogável e irretratável e obriga as partes a cumpri-lo a qualquer título, bem como seus herdeiros e sucessores.</p>

    <p>Eventual tolerância de uma parte com relação à outra em face do atraso ou não cumprimento de quaisquer obrigações acordadas no presente Contrato não constituirá novação, sendo facultado à parte afetada, a qualquer momento, exigir o cumprimento das condições contratuais pactuadas.</p>

    <p>Este instrumento está revestido das formalidades previstas no artigo 585, inciso II do Código de Processo Civil, constituindo-se, assim, num título executivo extrajudicial.</p>

    <p>Fica eleito o [**********] como o competente para dirimir E por estarem assim justas e acordadas, as partes assinam o presente instrumento elaborado em 02 (duas) vias de igual teor e forma, na presença das testemunhas e dos intervenientes a seguir indicados.</p>`;
};

export const getExtraInfo = ({
  user,
  debt,
  // agreement,
  paymentOption,
  paymentMethod,
}: ExtraInfoServiceTypes) => {
  const confirmation: any = {
    ativossa: {
      partnerTermsAndConditions: {
        typeOfTerm: `condition`,
        descriptionOfAcceptance: `Ao concluir este acordo, declaro que li e concordo com os <strong>termos e condições da Ativos S.A.</strong>.`,
        titleOfPartnerTerm: `Carta de confirmação de acordo`,
        contentOfPartnerTerm: termsAndConditionsOfAtivossa({
          user,
          debt,
          paymentOption,
        }),
      },
    },
    bancodobrasil: {
      warnings: {
        importantInfoText: `O IOF (Imposto sobre Operações Financeiras) foi calculado conforme determinações do Banco Central do Brasil e já está incluso no valor informado para suas parcelas.<br /><br /><strong>Atenção:</strong> orientamos a não movimentar sua conta corrente desde a confirmação de sua renegociação até o pagamento de seu boleto, evitando a alteração de seu saldo e, consequentemente, o cancelamento do acordo.`,
      },
    },
    'banco-next': {
      warnings: {
        importantInfoText: `O IOF (Imposto sobre Operações Financeiras) foi calculado conforme determinações do Banco Central do Brasil e já está incluso no valor informado para suas parcelas.`,
      },
    },
    bradesco: {
      warnings: {
        importantInfoText: `<p>O IOF (Imposto sobre Operações Financeiras) foi calculado conforme determinações do Banco Central do Brasil e já está incluso no valor informado para suas parcelas.<p><br /> <p><strong>Atenção:</strong> orientamos a não movimentar sua conta corrente desde a confirmação de sua renegociação até o pagamento de seu boleto, evitando a alteração de seu saldo e, consequentemente, o cancelamento do acordo.</p>`,
      },
      partnerTermsAndConditions: {
        typeOfTerm: `authorization`,
        descriptionOfAcceptance: `Autorizo o débito do valor total ou parcial da(s) parcela(s) ${
          debt?.bankAccounts && debt?.bankAccounts?.length > 0
            ? 'na conta corrente indicada'
            : ''
        } até o término desta contratação, na data do vencimento ou após o vencimento, podendo  ser utilizado o limite de cheque especial, se contratado, a fim de evitar atrasos nos pagamentos.`,
      },
    },
    carrefour: {
      warnings: {
        ...(paymentMethod?.id === "BILLET" && { importantInfoText: `Seu boleto ficará disponível para pagamento em até 1 dia útil após a emissão.` }),
      },
    },
    credigy: {
      warnings: {
        importantInfoText: `Lembrando que esta é uma tentativa de acordo com a empresa ${debt?.partner?.name}. <strong>Caso a empresa aceite a sua proposta, você receberá o boleto em seu e-mail em até 24 horas úteis.</strong> Caso ela recuse, nós também te avisaremos por lá, combinado?`,
      },
    },
    'fundo-atlantico': {
      warnings: {
        importantInfoText: `Lembrando que esta é uma tentativa de acordo com a empresa ${debt?.partner?.name}. <strong>Caso a empresa aceite a sua proposta, você receberá o boleto em seu e-mail em até 24 horas úteis.</strong> Caso ela recuse, nós também te avisaremos por lá, combinado?`,
      },
    },
    itau: {
      warnings: {
        importantInfoTextWithTitle:
          paymentMethod?.id === 'DEBIT_ACCOUNT'
            ? `Orientamos a não movimentar sua conta corrente desde a confirmação de sua renegociação até o pagamento do boleto, evitando a alteração de seu saldo e, consequentemente, o cancelamento do acordo, ok?`
            : null,
      },
      partnerTermsAndConditions: {
        typeOfTerm: `authorization`,
        descriptionOfAcceptance: `Você autoriza o débito dos valores devidos de forma total ou parcial na(s) conta(s) corrente, poupança, salário e/ou pagamento indicada(s) na data de vencimento ou após o vencimento, podendo ser utilizado o limite da conta, evitando atrasos no pagamento. Em caso de alteração da numeração, categoria ou segmento da(s) conta(s) autorizada(s), os débitos autorizados serão mantidos, nas mesmas condições aprovadas.`,
        descWithoutAcceptance: `Ao continuar, você declara que leu e concorda com as informações importantes, os detalhes desta renegociação e os <a class="${selectorClickTermBlob(
          debt?.partner?.id
        )}">termos e condições do Itaú</a>.`,
        hasOpenTermsInBlob: true,
        selectorClickTermAccBlob: selectorClickTermBlob(debt?.partner?.id),
      },
    },
    kroton: {
      partnerTermsAndConditions: {
        typeOfTerm: `confession`,
        descriptionOfAcceptance: `Li e concordo com os termos da empresa ${debt?.partner?.name}.`,
        titleOfPartnerTerm: `Termo de confissão`,
        contentOfPartnerTerm: termOfConfessionAndNovationKroton(),
      },
      warningPartnerRenegotiation: {
        importantWarningForDebt: `Essa dívida está sendo renegociada, pois já possui um acordo ativo/em andamento em outro local.`,
        detailsWarningForDebt: `<p>Esse acordo se trata de uma renegociação, significa que essa dívida já possui um acordo ativo em outro local.</p> <br/> <p>Após concluir o acordo, os valores que vão passar a valer serão os deste acordo.</p>`,
        isRenegotiation: getProperty(debt, 'IS_RENEGOTIATION'),
      },
    },
    nubank: {
      partnerTermsAndConditions: {
        typeOfTerm: `condition`,
        descriptionOfAcceptance: `Li e concordo com as informações importantes, os detalhes desta renegociação e os <a class="${selectorClickTermBlob(
          debt?.partner?.id
        )}">termos e condições do Nubank</a>.`,
        hasOpenTermsInBlob: true,
        selectorClickTermAccBlob: selectorClickTermBlob(debt?.partner?.id),
      },
    },
    riachuelo: {
      warnings: {
        importantInfoText: `<strong>Importante:</strong> As demais parcelas do seu acordo ficarão disponíveis no site, aplicativo e lojas físicas da Riachuelo.`,
      },
    },
    vivo: {
      warnings: {
        importantInfoText: `Ao continuar, entendo que esta é uma <strong>proposta de acordo com a Vivo</strong> e que o boleto ficará disponível em até 1 dia útil, caso a minha proposta seja aprovada.`,
      },
      buttonText: `Enviar proposta`,
    },
  };
  confirmation['bradescard'] = { ...confirmation.bradesco };

  const closedAgreement = {
    kroton: {
      warningPartnerRenegotiation: {
        importantWarningAfterAgreement: `Este acordo foi renegociado, a partir de agora passam a valer apenas os valores dessa negociação.`,
        // detailsWarningForDebt: `<p>Esse acordo é uma renegociação, significa que essa dívida já possuia um acordo ativo em outro local.</p> </br>
        //      <p>Os valores que vão passar a valer a partir desse momento, serão os desta renegociação.</p> </br>
        // 		 ${getRenegotiationDate(agreement?.dataAcordo)} `,
        isRenegotiation: getProperty(debt, 'IS_RENEGOTIATION'),
      },
    },
  };

  const myAgreement = {
    kroton: {
      warningPartnerRenegotiation: {
        importantWarningForAgreement: `Este acordo foi renegociado, a partir de agora passam a valer apenas os valores dessa negociação.`,
        isRenegotiation: getProperty(debt, 'IS_RENEGOTIATION'),
      },
    },
  };

  return {
    confirmation,
    closedAgreement,
    myAgreement,
  };
};
