import { useRequest } from 'alova';
import { alovaInstancePartners } from './alovaInstancePartners';
import {
  ReqCancelAgreement,
  ReqCheckCancelAgreement,
  ResCheckCancelAgreement,
  UpdateAgreementPayloadProps,
} from './types';
import { AgreementResponseProps } from '@dues/apis/agreements/types';
import acInterceptor from '../acInterceptor';

// TODO: check why the blob in the interceptor, then implement the acInterceptor

const useApiPartners = (msUrl: string) => {
  const headers = !msUrl?.includes('geminio')
    ? {
        'Content-Type': 'application/json;charset=UTF-8',
        'Access-Control-Allow-Origin': '*',
      }
    : {};

  const apiGetPaymentTermsCash = useRequest(
    alovaInstancePartners(msUrl, true).Get<any>('/api/terms/a-vista', {
      headers: headers,
    }),
    { immediate: false }
  );

  const apiGetPaymentTermsInstallments = useRequest(
    alovaInstancePartners(msUrl, true).Get<any>('/api/terms/parcelado', {
      headers: headers,
    }),
    { immediate: false }
  );

  const apiGetPaymentTermsLending = useRequest(
    alovaInstancePartners(msUrl, true).Get<any>('/api/terms/emprestimo', {
      headers: headers,
    }),
    { immediate: false }
  );

  const apiGetAtivosAgreementLetter = useRequest(
    ({ installmentCode }) =>
      alovaInstancePartners(msUrl, true).Get<any>(
        `/agreement-letter/${installmentCode}/download`,
        {
          headers: headers,
        }
      ),
    { immediate: false }
  );

  const apiAgreementTermRecovery = useRequest(
    (payload: { agreement: number }) =>
      alovaInstancePartners(msUrl, true).Post<any>('/agreement-term', payload, {
        headers: headers,
      }),
    { immediate: false }
  );

  const apiCheckIfCanUpdateAgreement = useRequest(
    (payload: UpdateAgreementPayloadProps) =>
      acInterceptor({ baseURL: msUrl }).Post<boolean>(
        'api/acordo/atualizar/habilitado',
        payload,
        {
          headers: headers,
        }
      ),
    { immediate: false }
  );

  const apiUpdateAgreement = useRequest(
    (payload: UpdateAgreementPayloadProps) =>
      acInterceptor({ baseURL: msUrl }).Post<AgreementResponseProps>(
        'api/acordo/atualizar',
        payload,
        {
          headers: headers,
        }
      ),
    { immediate: false }
  );

  const apiCheckIfCanCancelAgreement = useRequest(
    (payload: ReqCheckCancelAgreement) =>
      acInterceptor({ baseURL: msUrl }).Post<ResCheckCancelAgreement>(
        '/api/v2/acordo/cancelar/habilitado',
        payload,
        {
          headers: headers,
        }
      ),
    { immediate: false }
  );

  const apiCancelAgreement = useRequest(
    (payload: ReqCancelAgreement) =>
      acInterceptor({ baseURL: msUrl }).Post<any>('/acordo/cancelar', payload, {
        headers: !msUrl?.includes('geminio') ? headers : {},
      }),
    { immediate: false }
  );

  return {
    apiGetPaymentTermsCash,
    apiGetPaymentTermsInstallments,
    apiGetPaymentTermsLending,
    apiGetAtivosAgreementLetter,
    apiAgreementTermRecovery,

    apiCheckIfCanUpdateAgreement,
    apiUpdateAgreement,

    apiCheckIfCanCancelAgreement,
    apiCancelAgreement,
  };
};

export default useApiPartners;
