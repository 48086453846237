
// TODO: Move to a global utility if the need for reuse arises.

/**
 * Retrieves the current and previous installments of a given agreement.
 *
 * The function checks the installments of the agreement in the following order:
 * 1. Looks for the first installment with the situation 'AWAITING_PAYMENT'.
 * 2. If none is found, it takes the first installment after the last 'PAYMENT_COMPLETED'.
 * 3. If neither condition is met, it selects the installment with the closest due date that is still upcoming.
 *
 * @param {Object} agreement - The agreement object containing installments.
 * @returns {{ currentInstallment: Object|null, previousInstallment: Object|null }}
 * An object containing the current installment and the previous installment, which may be null if not found.
 *
 * @example
 *  const { currentInstallment, previousInstallment } = getAgreementInstallments(agreement);
 *  console.log("currentInstallment", {currentInstallment, previousInstallment});
 *
 */
export function getAgreementInstallments(agreement: any) { //  getCurrentAndPreviousInstallmentsFromAgreement
  const installments = agreement?.installments || [];
  const currentDate = new Date();

  // Rule 1: Search for AWAITING_PAYMENT
  const awaitingPaymentIndex = installments?.findIndex((i: { situation: string; }) => i?.situation === 'AWAITING_PAYMENT');
  if (awaitingPaymentIndex !== -1) {
    const currentInstallment = installments[awaitingPaymentIndex];
    const previousInstallment = awaitingPaymentIndex > 0 ? installments[awaitingPaymentIndex - 1] : null;

    return { currentInstallment, previousInstallment };
  }

  // Rule 2: Take the first one after PAYMENT_COMPLETED
  const lastPaymentCompletedIndex = installments
    ?.map((installment: { situation: string; }, index: number) => ({ index, situation: installment?.situation }))
    ?.reverse()
    ?.find((i: { situation: string; }) => i?.situation === 'PAYMENT_COMPLETED')?.index;

  if (lastPaymentCompletedIndex !== undefined && lastPaymentCompletedIndex + 1 < installments.length) {
    const currentInstallment = installments[lastPaymentCompletedIndex + 1];
    const previousInstallment = installments[lastPaymentCompletedIndex];
    return { currentInstallment, previousInstallment };
  }

  // Rule 3: Take the closest dueDate
  const closestInstallment = installments?.reduce((closest: any, current: any) => {
    const currentDueDate = new Date(current.dueDate);
    if (currentDueDate > currentDate) {
      if (!closest || currentDueDate < new Date(closest.dueDate)) {
        return current;
      }
    }
    return closest;
  }, null);


  if (closestInstallment) {
    const closestIndex = installments.indexOf(closestInstallment);
    const previousInstallment = closestIndex > 0 ? installments[closestIndex - 1] : null;
    return { currentInstallment: closestInstallment, previousInstallment };
  }

  // If no installment is found
  return { currentInstallment: installments?.[0] ?? null, previousInstallment: null };
}
