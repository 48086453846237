// Hooks
import { useWidgetPrompt } from './hooks';

//Types
import type { PromptTypes } from './types';

// Styles
import './styles.css';

export const WidgetPrompt = ({
  position = 'right',
  fontFamily = 'verdana, sans-serif',
  promptButton = 'Sim, me envie as novidades',
  promptButtonColor = '#065FD4',
  promptDismiss = 'Não, valeu',
  promptMessage = 'Ative as notificações para receber as últimas notícias e atualizações.',
  promptTitle = 'Boas-vindas! <strong style="font-size: 1em; font-family: verdana, sans-serif; color: #000000;">Quer receber nossos avisos sobre novas oferas?</strong>',
}: PromptTypes) => {
  const {
    isOpenPrompt,
    alertTitle,
    handleClosePrompt,
    handleDismissPrompt,
    handleSubscribePrompt,
    setIsOpenPrompt,
  } = useWidgetPrompt();

  return (
    <div
      id="push-prompt"
      style={{ [position]: 0, display: isOpenPrompt ? 'block' : 'none' }}
      onKeyUp={(e) => {
        if (e.key === '27') {
          setIsOpenPrompt(false);
        }
      }}
    >
      <div id="push-margin">
        <a
          id="push-prompt-close"
          style={{ fontFamily }}
          onClick={handleClosePrompt}
        >
          X
        </a>
        <h1 style={{ fontFamily }}>
          <span
            dangerouslySetInnerHTML={{
              __html: `${promptTitle}`,
            }}
          ></span>
        </h1>
        <p style={{ fontFamily }}>
          <span
            dangerouslySetInnerHTML={{
              __html: `${promptMessage}<br>${alertTitle}`,
            }}
          ></span>
        </p>
        <div id="push-prompt-btns">
          <button
            id="push-prompt-button"
            style={{ backgroundColor: promptButtonColor }}
            onClick={handleSubscribePrompt}
          >
            {promptButton}
          </button>
          <button
            id="push-prompt-dismiss"
            style={{ fontFamily }}
            onClick={handleDismissPrompt}
          >
            {promptDismiss}
          </button>
        </div>
      </div>
    </div>
  );
};
