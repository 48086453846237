import { ReactNode, useState, useEffect } from 'react';
import classNames from 'classnames';
import Conditional from '../misc/Conditional';
import { IconCheckCircle, IconXCircle, IconInfo } from '../icons/default';
import './styles.scss';

interface MessageProps {
  type?: 'error' | 'success' | 'warning' | 'custom';
  children?: ReactNode;
  showTimer?: boolean;
  icon?: ReactNode;
  customClass?: string;
  dangerouslySetInnerHTML?: any;
}

const Message: React.FC<MessageProps> = ({
  children,
  type,
  showTimer = false,
  icon,
  customClass,
  dangerouslySetInnerHTML,
}) => {
  const [countDownPercentage, setCountDownPercentage] = useState(100);
  const COUNTDOWN_DURATION = 5000;

  const messageClassName = classNames('ac-message-info', {
    [`ac-message-info__${customClass}`]: !!customClass,
    [`ac-message-info--${type}`]: !!type,
    'timer-container--over': countDownPercentage === 0,
  });

  const messageTimerContainerClassName = classNames('timer-container', {
    [`timer-container--${type}`]: !!type,
  });

  useEffect(() => {
    if (showTimer) {
      const countDownDateTime = new Date().getTime() + COUNTDOWN_DURATION;

      const countDownInterval = setInterval(() => {
        const now = new Date().getTime();

        const distance = countDownDateTime - now;

        if (distance < 0) {
          clearInterval(countDownInterval);
        } else {
          const percentage = Math.round((distance / COUNTDOWN_DURATION) * 100);
          setCountDownPercentage(percentage);
        }
      }, 1);

      return () => clearInterval(countDownInterval);
    }
  }, []);

  const icons = {
    error: <IconXCircle />,
    success: <IconCheckCircle />,
    warning: <IconInfo />,
    custom: icon,
  };

  return (
    <div className={messageClassName}>
      {type ? icons[type] : null}
      <Conditional
        condition={dangerouslySetInnerHTML}
        renderIf={
          <span dangerouslySetInnerHTML={{ __html: dangerouslySetInnerHTML }} />
        }
        renderElse={<span>{children}</span>}
      />

      <Conditional
        condition={showTimer}
        renderIf={
          <div
            style={{ width: `${countDownPercentage}%` }}
            className={messageTimerContainerClassName}
          ></div>
        }
      />
    </div>
  );
};

export default Message;
