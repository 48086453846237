import classNames from 'classnames';
import { ReactNode } from 'react';

interface ReportIconProps {
  children?: ReactNode;
}

const ReportIcon = ({ children }: ReportIconProps) => {
  const reportIconClassName = classNames('ac-report-icon', {});

  return <div className={reportIconClassName}>{children}</div>;
};

export default ReportIcon;
