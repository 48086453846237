import { usePushWebSettingsStore } from '../storage/settings';

export const getApplicationServerKey = () => {
  try {
    const configurationJaiminho = usePushWebSettingsStore;

    if (
      !configurationJaiminho.getState().settings.applicationServerKey ||
      !configurationJaiminho.getState().settings.applicationServerKey?.length
    ) {
      throw new Error('Push Web: Server key is not invalid!');
    }

    return configurationJaiminho.getState().settings.applicationServerKey;
  } catch (error) {
    console.error(`Get application key: ${error}`);

    return error;
  }
};
