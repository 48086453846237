export function getDDD(phone: string | null | undefined): string | null {
  if (!phone) {
    return null;
  }

  const cleanedPhone = phone.replace(/[^\d]/g, '');

  if (cleanedPhone.length < 10) {
    return null;
  }

  const ddd = cleanedPhone.substring(0, 2);

  return ddd;
}
