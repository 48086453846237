import { useContext } from 'react';
import { AdsContext } from './AdsContext';

export const useAds = () => {
  const context = useContext(AdsContext);
  
  if (context === undefined) {
    throw new Error('useAds must be used within an AdsProvider');
  }
  
  return context;
};