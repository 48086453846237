import { useEffect } from 'react';
import SomethingIsWrong from '../SomethingIsWrong';
import ilu from '@common/assets/illustration-woman-error.svg';
import { useRedline } from '@libs/redline';

type ErrorBoundaryMessageProps = {
  error?: Error;
};

export default function ErrorBoundaryMessage({
  error,
}: ErrorBoundaryMessageProps) {
  const { track } = useRedline();
  useEffect(() => {
    track.experience.appCrashed({
      error: String(error),
      description: 'AC App white screen',
    });
  }, []);

  return <SomethingIsWrong image={ilu} iluSize={280} />;
}
