import { Modal } from '../Modal'
import { getApp } from '@libs/utils/helpers/getApplication';
import ACLogo from './logos/logo-ac.svg'
import CPLogo from './logos/logo-cp.svg'

import './styles.scss';

type RedirectModalProps = {
  isOpen: boolean;
  partnerName: string;
  offerImage: string;
};
export function RedirectModal({
  isOpen,
  partnerName,
  offerImage,
}: RedirectModalProps) {
  const isAC = getApp() === 'acordocerto'
  const brandLogo = isAC ? ACLogo : CPLogo
  const brandName = isAC ? 'Acordo Certo' : 'Consumidor Positivo'

  return (
    <div className="loading-modal">
      <Modal
        hasCloseButton={false}
        mobile="center"
        desktop="center"
        showModal={isOpen}
      >
        <div className="loading-modal__loader">
          <div className="loading-modal__partner">
            <img src={brandLogo} alt={`Logo ${brandName}`} />
          </div>
          <div className="loading-modal__bar"></div>
          <div className="loading-modal__partner">
            <img src={offerImage} alt="Oferta do parceiro" />
          </div>
        </div>
        <div className="loading-modal__message">
          <p>Em instantes você será redirecionado para {partnerName}</p>
        </div>
      </Modal>
    </div>
  );
}
