import { getApp } from '@libs/utils/helpers/getApplication';
import { getDeliveryIdParam } from '@libs/utils/helpers/getDeliveryIdParam';
import { getCookie } from '@libs/utils/helpers/getSafeCookies';
import { getSiteIdParam } from '@libs/utils/helpers/getSiteIdParam';
import { getSessionID } from 'redline-client-sdk';

export const fillUrlParams = async ({
  url,
  fireboltId,
  idUser,
}: {
  url: string;
  fireboltId: string;
  idUser?: string;
}) => {
  const marketplace = getApp() === 'acordocerto' ? 'ac' : 'cp';
  const startSourceUrl = getCookie('start_source_url') || '';
  const deliveryId = getDeliveryIdParam({ startSourceUrl }) || '';
  const siteId = (await getSiteIdParam({ marketplace, startSourceUrl })) || '';
  const sessionId = getSessionID();

  url = url
    .replace(/\(firebolt_id\)/g, fireboltId)
    .replace('(session_id)', sessionId)
    .replace('(af_siteid)', siteId)
    .replace('(delivery_id)', deliveryId);

  if (url.includes('noverde') && idUser) {
    url = url.replace('(utm_term)', idUser);
  }

  const urlObj = new URL(url);

  if (idUser) {
    // TODO: check if this logic is still used (legacy AC)
    const searchParams = urlObj.searchParams;
    const timestamp = Date.now();

    const filledParams: Record<string, string> = {
      anonymous_id: `${idUser}${timestamp}`,
      session_id: `${idUser}${timestamp}`,
      clickID: `${idUser}T${timestamp}`,
      aff_unique2: idUser,
      firebolt_id: fireboltId,
    };

    Object.keys(filledParams).forEach((key) => {
      if (searchParams.has(key)) {
        searchParams.set(key, filledParams[key]);
      }
    });
  }

  return urlObj.toString();
};
