import { getCookie } from '../helpers/getCookies'

export function getFbp() {
  return getCookie('_fbp');
}

export function getFbc() {
  return getCookie('_fbc');
}

export function getFbAvailableCookies(): { fbc?: string; fbp?: string } {
  const fbp = getFbp();
  const fbc = getFbc();
  if (!fbp && !fbc) return {};

  return {
    ...(!!fbp ? { fbp } : {}),
    ...(!!fbc ? { fbc } : {}),
  };
}
