import { checkCookie, getCookie } from '@libs/utils/helpers/getSafeCookies';
import untilLoad from '@libs/utils/helpers/untilLoad';
import { datalayerAcceptedVariables } from './constants';

type AcceptedGTMVariable = (typeof datalayerAcceptedVariables)[number];
type VariablePushPayload = {
  [Key in AcceptedGTMVariable]?: string | number;
};

export default function useGTM() {
  // TODO - kill mood
  function getMood() {
    return (window as any).mood;
  }

  function getObjectFromCookie(cookieName: string) {
    const hasCookie = checkCookie(cookieName);
    const getCookieValue = getCookie(cookieName, false);
    return hasCookie && getCookieValue && JSON.parse(getCookieValue);
  }

  function getUTM() {
    const mood = getMood();
    return mood ? mood.getUtm() : getObjectFromCookie('mood_utm');
  }

  function getGclid() {
    const mood = getMood();
    return mood ? mood.getGclid() : getObjectFromCookie('mood_gclid');
  }

  function getDatalayer() {
    const w = window as any;
    w.dataLayer = w.dataLayer || [];
    return w.dataLayer;
  }

  function validateDataLayerPayload(variablesRecord: VariablePushPayload) {
    const notAuthorizedKeys = Object.entries(variablesRecord).filter(
      ([key]) => !datalayerAcceptedVariables.includes(key as any)
    );
    const isValid = !notAuthorizedKeys.length;
    const invalidKeys = notAuthorizedKeys.map(([key]) => key).join(', ');

    return { isValid, invalidKeys };
  }

  function pushVariables(variables: VariablePushPayload) {
    const { isValid, invalidKeys } = validateDataLayerPayload(variables);
    if (!isValid) {
      console.log(`GTM variables ${invalidKeys}, not acceptable`);
      return;
    }
    const dataLayer = getDatalayer();
    dataLayer.push(variables);
  }

  function createTrigger(triggerValue: string) {
    return async (datalayerVariables: VariablePushPayload = {}) => {
      await untilLoad(() => getMood(), 2000);
      const { source, medium, term } = getUTM() || {};

      pushVariables({
        event: triggerValue,
        // used to some triggers like closeDeal [partner]
        utm_source: source,
        utm_medium: medium,
        utm_term: term,
        ...datalayerVariables,
      });
    };
  }

  const trigger = {
    loginWithSuccess: createTrigger('Login com sucesso'),
    registerWithSuccess: createTrigger('Cadastro com sucesso'),
    userFindSimulation: createTrigger('Simulação'),
    pixGenerated: createTrigger('Pix Gerado'),
    pixPayed: createTrigger('Pix Pago'),
    debtFound: createTrigger('Dívida localizada'),
    agreement: createTrigger('Acordo'),
    agreementP4: createTrigger('Acordo p4'),
    checkout: createTrigger('Checkout'),
  };

  return { pushVariables, trigger, getGclid };
}
