import { useEffect, useState, useCallback } from 'react';
import isClient from '../helpers/isClient';

export const useDeviceSize = () => {
  const [isDesktop, setIsDesktop] = useState<boolean>(false);
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [windowWidth, setWindowWidth] = useState<number | null>(null);

  const handleResize = useCallback((event: any) => {
    const width = event?.target?.innerWidth || event;
    const bigScreen = width > 720;
    setWindowWidth(width);
    setIsDesktop(bigScreen);
    setIsMobile(!bigScreen);
  }, []);

  useEffect(() => {
    if (!isClient()) return;
    handleResize(window?.innerWidth);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return {
    isDesktop,
    isMobile,
    windowWidth,
  };
};
