import CopiedCodeWarning from '@common/components/DebtsCarousel/agreementsComponents/CopiedCodeWarning';
import {
  Button,
  Conditional,
  IconBarCode,
  IconCopy,
  IconPix,
  LazyImage,
  Text,
  IconWhatsapp,
} from '@consumidor-positivo/aurora';
import {
  Billet,
  ClosedAgreementProps,
} from '@dues/pages/Partner/ClosedAgreement/types';
import isMobile from '@libs/utils/helpers/isMobile';

interface ClosedAgreementPixProps {
  isPixBillet: boolean;
  isPixExternal: boolean;
  qrCodeUrl: string;
  handleCopyPixCode: () => void;
  isPixLoading: boolean;
  isPixSuccess: boolean;
  closedAgreement: ClosedAgreementProps;
  billet: Billet;
  pixCode: string;
  shareOnWhatsApp: ({}) => void;
  warningRef: React.MutableRefObject<HTMLElement | null>;
  handleCopyBilletCode: any;
  buttonText: string;
  handleBilletDownload: any;
  pixText: string;
}

const Pix = ({
  isPixBillet,
  isPixExternal,
  qrCodeUrl,
  handleCopyPixCode,
  billet,
  pixCode,
  warningRef,
  shareOnWhatsApp,
  handleCopyBilletCode,
  buttonText,
  handleBilletDownload,
  pixText,
}: ClosedAgreementPixProps) => {
  const titleIcon = !isPixBillet ? <IconPix /> : <IconBarCode />;

  return (
    <>
      <CopiedCodeWarning ref={warningRef} />

      <Conditional
        condition={!!qrCodeUrl && !isMobile()}
        renderIf={
          <div>
            <span className="payment-section-v2__title">
              Escaneie este Código QR para pagar
              {titleIcon}
            </span>
            <ol>
              <li>
                <Text color="secondary">
                  Acesse seu Internet Banking ou app de pagamentos
                </Text>
              </li>
              <li>
                <Text color="secondary">
                  Vá na área do Pix, e dentro de Pix procure a opção QR Code
                </Text>
              </li>
              <li>
                <Text color="secondary">
                  Escolha a opção: Pagar um QR Code e realize o pagamento.
                </Text>
              </li>
            </ol>

            <div className="payment-section-v2__qrcode">
              <LazyImage
                className="payment-section-v2__qrcode-img"
                src={qrCodeUrl}
                alt="QR Code contendo informações de pagamento do PIX"
                lazy={false}
                width={146}
                height={146}
                style={{ ...(!isPixExternal && { padding: 7 }) }}
              />
            </div>

            <div className="payment-section-v2__divider" />

            <Text weight="bold" variant="heading-micro">
              Ou copie este código para fazer o pagamento
            </Text>

            <Text color="secondary" className="payment-section-v2__subheading">
              Em seguida escolha pagar pelo seu Internet Banking ou app de
              pagamentos.
            </Text>
          </div>
        }
        renderElse={
          <>
            <span className="payment-section-v2__title">
              Copie o código para pagar
              {titleIcon}
            </span>
            <Conditional
              condition={!isPixBillet}
              renderIf={
                <ol>
                  <li>
                    <Text color="secondary">
                      Acesse seu Internet Banking ou app de pagamentos.
                    </Text>
                  </li>
                  <li>
                    <Text color="secondary">Entre na área do Pix</Text>
                  </li>
                  <li>
                    <Text color="secondary">
                      Cole o código e realize o pagamento.
                    </Text>
                  </li>
                </ol>
              }
              renderElse={
                <ol>
                  <li>
                    <Text color="secondary">
                      Escolha abaixo como você gostaria de pagar este acordo.
                    </Text>
                  </li>
                  <li>
                    <Text color="secondary">
                      Acesse seu Internet Banking ou app de pagamentos.
                    </Text>
                  </li>
                  <li>
                    <Text color="secondary">
                      Cole o código e realize o pagamento.
                    </Text>
                  </li>
                </ol>
              }
            />
          </>
        }
      />

      <Conditional
        condition={!isPixBillet}
        renderIf={
          <div className="payment-section-v2__btn-container">
            <Button expand="x" type="primary" onClick={handleCopyPixCode}>
              <IconCopy />

              {pixText}
            </Button>
            <Button
              type="outlined"
              onClick={() =>
                shareOnWhatsApp({ code: pixCode, typeCode: 'Pix' })
              }
            >
              <IconWhatsapp />
              Compartilhar
            </Button>
          </div>
        }
        renderElse={
          <>
            <div className="payment-section-v2__btn-container">
              <Button type="primary" expand="x" onClick={handleCopyPixCode}>
                <IconCopy />
                {pixText}
              </Button>
              <Button
                expand="x"
                type="outlined"
                onClick={() => handleCopyBilletCode(billet.linhaDigitavel)}
              >
                <IconCopy />
                {buttonText}
              </Button>
            </div>

            <div className="payment-section-v2__ghost-btn-container">
              <Button type="ghost" onClick={handleBilletDownload}>
                Baixar boleto
              </Button>
              <Button
                type="ghost"
                onClick={() =>
                  shareOnWhatsApp({
                    code: billet.linhaDigitavel,
                    typeCode: 'boleto',
                  })
                }
              >
                <IconWhatsapp />
                Compartilhar
              </Button>
            </div>
          </>
        }
      />
    </>
  );
};

export default Pix;
