import { SHAnnuity } from '../services/sortingHat/types';

export const getConditionalAnnuity = (annuity: SHAnnuity) => {
  const annuityText: any = {
    first_year_free: 'Anuidade grátis no 1° ano',
    always_free: 'Anuidade grátis',
  };

  const conditionalAnnuity = annuityText[annuity];

  if (conditionalAnnuity) {
    return conditionalAnnuity;
  } else {
    return annuity;
  }
};
