import { useEffect, useState } from 'react';
import {
  Button,
  COLOR_NEUTRAL_50,
  IconChevronRight,
  LazyImage,
  Text,
} from '@consumidor-positivo/aurora';
import { useRedline } from '@libs/redline';
import { maskCellphone } from '@libs/utils/masks/cellphone';
import { useUserStore } from '@common/store/useUserStore';
import UpdateContactDataModal from './UpdateContactDataModal';
import WhatsappIcon from '@dues/assets/images/whatsapp-icon.svg';
import UpdatedWithSuccessWarning from './UpdatedWithSuccessWarning';

import './styles.scss';
interface UpdateContactDataProps {
  partnerLogo: string;
}
const UpdateContactData = ({ partnerLogo }: UpdateContactDataProps) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [showWarning, setShowWarning] = useState<boolean>(false);
  const [flowType, setFlowType] = useState<'cellphone' | 'email'>('cellphone');
  const { track: trackRedline } = useRedline();

  interface dataProps {
    cellphone: { type: 'celular'; warningText: string };
    email: { type: 'e-mail'; warningText: string };
  }

  const data: dataProps = {
    cellphone: {
      type: 'celular',
      warningText: 'Celular verificado',
    },
    email: {
      type: 'e-mail',
      warningText: 'E-mail alterado',
    },
  };

  const handleUpdateDateClick = (flowType: 'cellphone' | 'email') => {
    trackRedline.userTracking.elementClicked({
      elementType: 'button',
      name: 'update-contact-data',
      text: `Editar ${data[flowType as keyof typeof data].type}`,
      location: window.location.pathname,
    });
    setFlowType(flowType);
    setIsModalOpen(true);
  };

  const { user } = useUserStore();

  useEffect(() => {
    if (showWarning) {
      setTimeout(() => {
        setShowWarning(false);
      }, 5000);
    }
  }, [showWarning]);

  return (
    <div className="update-contact-data">
      <div className="update-contact-data__icons">
        <div className="update-contact-data__icons--logo">
          <LazyImage width={32} height={32} src={partnerLogo} />
        </div>
        <div className="update-contact-data__icons--chevrons">
          <IconChevronRight rawColor={COLOR_NEUTRAL_50} />
          <IconChevronRight rawColor={COLOR_NEUTRAL_50} />
        </div>

        <div className="update-contact-data__icons--logo">
          <LazyImage src={WhatsappIcon} width={32} height={32} />
        </div>
      </div>

      <div className="update-contact-data__content">
        <div className="update-contact-data__header">
          <Text variant="heading-micro" weight="bold">
            Não perca nenhuma atualização!
          </Text>
          <Text color="secondary">
            Atualize seus dados para que possamos te avisar pelo WhatsApp sobre
            seus vencimentos. É rápido e facilita sua vida!
          </Text>
        </div>

        <div className="update-contact-data__info">
          <div>
            <Text color="secondary">Celular:</Text>
            <Text color="secondary" weight="bold">
              {maskCellphone(user.celular)}
            </Text>
          </div>

          <Button
            type="ghost"
            onClick={() => handleUpdateDateClick('cellphone')}
          >
            Editar
          </Button>
        </div>
        <div className="update-contact-data__line" />
        <div className="update-contact-data__info">
          <div>
            <Text color="secondary">E-mail:</Text>
            <Text
              color="secondary"
              weight="bold"
              className="update-contact-data__email"
            >
              {user.email}
            </Text>
          </div>
          <Button type="ghost" onClick={() => handleUpdateDateClick('email')}>
            Editar
          </Button>
        </div>

        <UpdateContactDataModal
          currFlow={data[flowType as keyof typeof data]?.type}
          isOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          setShowWarning={setShowWarning}
        />
      </div>

      <UpdatedWithSuccessWarning
        warningText={data[flowType as keyof typeof data]?.warningText}
        showWarning={showWarning}
      />
    </div>
  );
};

export default UpdateContactData;
