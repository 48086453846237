import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

// Types
import type { PushWebSettingsType } from './types';

// data
import { initialState } from './data';

export const usePushWebSettingsStore = create<PushWebSettingsType>()(
  devtools(
    persist(
      (set, get) => ({
        ...initialState,
        handleSetSettings: (settings, options) => {
          if (!options) {
            return set({ settings: { ...get().settings, ...settings } });
          }

          const optionsData = {
            options: { ...get().settings.options, ...settings },
          };

          set({ settings: { ...get().settings, ...optionsData } });
        },
        resetStore: () => {
          usePushWebSettingsStore.persist.clearStorage();
          set(initialState);
        },
      }),
      {
        name: 'push-web-settings',
      }
    )
  )
);
