import constants from "@common/constants";

const { PROPERTIES } = constants.debts;
const { DEBIT_ACCOUNT, ENTRY } = PROPERTIES;

/**
 * Checks if the current installment of an agreement is associated with a debit account.
 *
 * @param {Object} agreement - The agreement object that contains its properties.
 * @param {Object} currentInstallment - The object of the current installment, which should include the installment number.
 * @returns {boolean} Returns true if the current installment is associated with a debit account, otherwise false.
 *
 * @example
 * const agreement = {
 *   properties: [
 *     { key: 'DEBIT_ACCOUNT', booleanValue: true },
 *     { key: 'ENTRY', decimalValue: 0 }
 *   ],
 *   partner: { identifier: 'bradesco' }
 * };
 * const currentInstallment = { installmentNumber: 2 };
 *
 * const result = currentInstallmentIsDebitAccount(agreement, currentInstallment);
 * console.log(result); // true
 */
const currentInstallmentIsDebitAccount = (agreement: any, currentInstallment: any) => {
  const agreementProperties = agreement?.properties || [];
  const agreementHasDebitAccount = agreementProperties?.find(({ key }: any) => key === DEBIT_ACCOUNT)?.booleanValue || false;

  const hasEntryDebitAcc = (agreementProperties?.find(({ key }: any) => key === ENTRY)?.decimalValue ?? 1) > 0;
  const isBradesco = agreement?.partner?.identificador === 'bradesco';

  const rule1DebitAcc = agreementHasDebitAccount && currentInstallment?.installmentNumber > 1;
  const rule2DebitAcc = !hasEntryDebitAcc && isBradesco;

  const hasDebitAccount = rule1DebitAcc || rule2DebitAcc;

  return hasDebitAccount;
};

export default currentInstallmentIsDebitAccount
