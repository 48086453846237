import React, {
  createContext,
  useState,
  useEffect,
  useCallback,
  useRef,
  ReactNode,
} from 'react';
import { useRedline } from '@libs/redline';
import {
  AdFormat,
  LAZY_LOAD_SETTINGS,
  AD_SIZE_MAPPINGS,
  OutOfPageTypes,
} from './constants';
import { getCookie, setContainerMinHeight } from './utils';

// Types
interface SlotInfo {
  id: string;
  path: string;
  slot: googletag.Slot | null;
  isRequesting?: boolean;
  isEmpty?: boolean;
  refreshTime?: number;
  format?: AdFormat;
  size?: string | number[];
}

interface SlotOptions {
  className?: string;
  refreshTime?: number;
  format?: AdFormat;
  sizeMapping?: googletag.SizeMappingArray;
}

interface OutOfPageSlotOptions {
  refreshTime?: number;
}

interface AdsContextType {
  slots: Record<string, SlotInfo>;
  createSlot: (
    id: string,
    path: string,
    options: SlotOptions
  ) => googletag.Slot | undefined;
  createOutOfPageSlot: (
    path: string,
    div: string | OutOfPageTypes,
    options?: OutOfPageSlotOptions
  ) => googletag.Slot | undefined;
  destroySlot: (slot: googletag.Slot) => void;
  refreshSlot: (id: string) => void;
  refreshAllSlots: () => void;
  getSlotInfo: (id: string) => SlotInfo | undefined;
}

// Create context
const AdsContext = createContext<AdsContextType | undefined>(undefined);

// Provider component
export const AdsProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [isInitialized, setIsInitialized] = useState(false);
  const [slots, setSlots] = useState<Record<string, SlotInfo>>({});

  const timeoutIdRef = useRef<Record<string, NodeJS.Timeout>>({});

  const { track } = useRedline();

  // Initialize Google Ad Manager
  useEffect(() => {
    if (!window.googletag?.cmd) console.warn('Google Ad Manager not loaded.');

    window.googletag = window.googletag || {};
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    googletag.cmd = googletag.cmd || [];

    const startURL = getCookie('start_source_url');

    let urlParams: Record<string, string> = {};
    if (startURL) {
      try {
        const url = new URL(startURL);
        urlParams = Object.fromEntries(url.searchParams.entries());
      } catch (error) {
        console.warn('Invalid URL in start_source_url cookie:', error);
      }
    }

    const mediumTargeting = ['pmax', 'display', 'cpc'].includes(
      urlParams['utm_medium']
    )
      ? urlParams['utm_medium']
      : 'others';

    window.googletag.cmd.push(() => {
      const pubads = googletag.pubads();
      pubads.disableInitialLoad();
      pubads.enableLazyLoad(LAZY_LOAD_SETTINGS);
      pubads.enableSingleRequest();
      pubads.setCentering(true);
      pubads.collapseEmptyDivs();
      pubads.setTargeting('medium', mediumTargeting);

      googletag.enableServices();

      setIsInitialized(true);
    });

    return () => {
      window.googletag.cmd.push(() => {
        // Destroy all slots
        googletag.destroySlots();

        // Clear all timeouts
        Object.values(timeoutIdRef.current).forEach(clearTimeout);
        timeoutIdRef.current = {};
      });
    };
  }, []);

  // Define event handlers with useCallback
  const handleSlotRequested = useCallback(
    (event: googletag.events.SlotRequestedEvent) => {
      const { slot } = event;
      const id = slot.getSlotElementId();
      console.log('SlotRequested', id);

      setSlots((prev) => ({
        ...prev,
        [id]: {
          ...prev[id],
          isRequesting: true,
        },
      }));
    },
    []
  );

  const handleSlotResponseReceived = useCallback(
    (event: googletag.events.SlotResponseReceived) => {
      const { slot } = event;
      const id = slot.getSlotElementId();

      setSlots((prev) => ({
        ...prev,
        [id]: {
          ...prev[id],
          isRequesting: false,
        },
      }));
    },
    []
  );

  const handleSlotOnload = useCallback(
    (event: googletag.events.SlotOnloadEvent) => {
      const { slot } = event;

      // Tracking code
      const payload = {
        location: window.location.pathname,
        adUnitPath: slot.getAdUnitPath(),
        slotElementId: slot.getSlotElementId(),
      };

      track.userTracking.adLoaded(payload);
    },
    [track.userTracking]
  );

  const handleImpressionViewable = useCallback(
    (event: googletag.events.ImpressionViewableEvent) => {
      const { slot } = event;
      const id = slot.getSlotElementId();
      const refreshTime = Number(slot.getTargeting('refresh_time')[0]) || 30;

      // Tracking code
      const payload = {
        location: window.location.pathname,
        adUnitPath: slot.getAdUnitPath(),
        slotElementId: slot.getSlotElementId(),
      };

      track.userTracking.adViewed(payload);

      // Set up automatic refresh
      if (timeoutIdRef.current[id]) {
        clearTimeout(timeoutIdRef.current[id]);
      }

      timeoutIdRef.current[id] = setTimeout(() => {
        googletag.pubads().refresh([slot]);
        slot.setTargeting('is_refreshed', '1');
      }, 1000 * refreshTime);
    },
    [track.userTracking]
  );

  const handleSlotRenderEnded = useCallback(
    (event: googletag.events.SlotRenderEndedEvent) => {
      const { slot, size, isEmpty } = event;
      const id = slot.getSlotElementId();

      setSlots((prev) => ({
        ...prev,
        [id]: {
          ...prev[id],
          isEmpty: !prev[id].isEmpty ? false : isEmpty,
          size: size || undefined,
        },
      }));
    },
    []
  );

  // Helper function to manage event listeners with proper typing
  const useGoogleTagEventListener = <
    K extends keyof googletag.events.EventTypeMap
  >(
    eventName: K,
    handler: (event: googletag.events.EventTypeMap[K]) => void,
    enabled = true
  ) => {
    useEffect(() => {
      if (!enabled) return;

      googletag.pubads().addEventListener(eventName, handler);

      return () => {
        googletag.pubads().removeEventListener(eventName, handler);
      };
    }, [eventName, handler, enabled]);
  };

  // Set up individual event listeners with proper event types
  useGoogleTagEventListener(
    'slotRequested',
    handleSlotRequested,
    isInitialized
  );
  useGoogleTagEventListener(
    'slotResponseReceived',
    handleSlotResponseReceived,
    isInitialized
  );
  useGoogleTagEventListener('slotOnload', handleSlotOnload, isInitialized);
  useGoogleTagEventListener(
    'impressionViewable',
    handleImpressionViewable,
    isInitialized
  );
  useGoogleTagEventListener(
    'slotRenderEnded',
    handleSlotRenderEnded,
    isInitialized
  );

  // Create a slot
  const createSlot = useCallback(
    (id: string, path: string, options: SlotOptions) => {
      if (!isInitialized) {
        console.warn('Google Ad Manager not initialized');
        return;
      }

      const container = document.getElementById(id);
      if (!container) {
        console.warn(`Ad container not found: ${id}`);
        return;
      }

      // Configure container with potential custom size mapping
      setContainerMinHeight(
        container,
        options.format as AdFormat,
        options.sizeMapping
      );

      // Define slot
      const slot = googletag.defineSlot(path, [0, 0], id);
      if (!slot) {
        console.warn(`Failed to define slot for ID: ${id}`);
        return;
      }

      // Set slot configuration
      slot.setTargeting('format', options.format || 'custom');
      slot.setTargeting('is_refreshed', '0');
      slot.setTargeting('refresh_time', String(options.refreshTime) || '30');

      // Configure size mapping
      const effectiveMapping =
        options.sizeMapping ||
        (options.format
          ? AD_SIZE_MAPPINGS[options.format as AdFormat]
          : AD_SIZE_MAPPINGS['responsive']);

      const mapping = googletag.sizeMapping();
      effectiveMapping.forEach(([viewport, sizes]) => {
        mapping.addSize(viewport, sizes);
      });
      const builtMapping = mapping.build();

      if (builtMapping) slot.defineSizeMapping(builtMapping);

      slot.addService(googletag.pubads());

      console.log('Created slot', id);

      // Update state
      setSlots((prev) => ({
        ...prev,
        [id]: {
          id,
          path,
          slot,
          refreshTime: options.refreshTime,
          format: options.format as AdFormat,
        },
      }));

      // Display ad
      googletag.display(id);
      googletag.pubads().refresh([slot]);

      return slot;
    },
    [
      isInitialized,
      // handleSlotResize
    ]
  );

  // Create an out of page slot
  const createOutOfPageSlot = useCallback(
    (
      path: string,
      div: string | OutOfPageTypes,
      options?: OutOfPageSlotOptions
    ) => {
      if (!isInitialized) {
        console.warn('Google Ad Manager not initialized');
        return;
      }

      const slot = googletag.defineOutOfPageSlot(
        path,
        div as string | googletag.enums.OutOfPageFormat
      );
      if (!slot) return;
      const refreshTime = options?.refreshTime || 30;

      slot.setTargeting('is_refreshed', '0');
      slot.setTargeting('refresh_time', String(refreshTime));

      slot.addService(googletag.pubads());

      const id = slot.getSlotElementId();

      // Update state
      setSlots((prev) => ({
        ...prev,
        [id]: {
          id,
          path,
          slot,
          refreshTime: refreshTime,
        },
      }));

      // Display ad
      googletag.display(id);
      googletag.pubads().refresh([slot]);

      return slot;
    },
    [isInitialized]
  );

  // Destroy a slot
  const destroySlot = useCallback((slot: googletag.Slot) => {
    const id = slot.getSlotElementId();

    // Clear refresh timeout if it exists
    if (timeoutIdRef.current[id]) {
      clearTimeout(timeoutIdRef.current[id]);
      delete timeoutIdRef.current[id];
    }

    window.googletag.cmd.push(() => {
      if (slot) {
        googletag.destroySlots([slot]);
      }

      // Update state
      setSlots((prev) => {
        const newSlots = { ...prev };
        delete newSlots[id];
        return newSlots;
      });
    });
  }, []);

  // Refresh a specific slot
  const refreshSlot = useCallback(
    (id: string) => {
      const slotInfo = { ...slots[id] };
      if (!slotInfo || !slotInfo.slot) return;

      // Clear refresh timeout if it exists
      if (timeoutIdRef.current[id]) {
        clearTimeout(timeoutIdRef.current[id]);
        delete timeoutIdRef.current[id];
      }

      window.googletag.cmd.push(() => {
        if (slotInfo.slot) {
          googletag.pubads().refresh([slotInfo.slot]);
        }
      });
    },
    [slots]
  );

  // Refresh all slots
  const refreshAllSlots = useCallback(() => {
    const activeSlots = Object.values(slots)
      .map((slotInfo) => slotInfo.slot)
      .filter(Boolean) as googletag.Slot[];

    if (activeSlots.length === 0) return;

    // Clear all refresh timeouts
    Object.keys(timeoutIdRef.current).forEach((id) => {
      clearTimeout(timeoutIdRef.current[id]);
      delete timeoutIdRef.current[id];
    });

    window.googletag.cmd.push(() => {
      googletag.pubads().refresh(activeSlots);
    });
  }, [slots]);

  // Get information about a specific slot
  const getSlotInfo = useCallback(
    (id: string) => {
      const slot = { ...slots[id] };
      return slot;
    },
    [slots]
  );

  // Context value
  const contextValue = {
    slots,
    createOutOfPageSlot,
    createSlot,
    destroySlot,
    refreshSlot,
    refreshAllSlots,
    getSlotInfo,
  };

  return (
    <AdsContext.Provider value={contextValue}>{children}</AdsContext.Provider>
  );
};

export { AdsContext };
