import useApiOnboarding from '@admission/apis/onboarding/useApiOnboarding';
import { useUserStore } from '@common/store/useUserStore';
import useAuth from '@common/hooks/useAuth';

export default function useIsAdmin() {
  const { apiOnboardingCustomer } = useApiOnboarding();
  const { addUser: addUserStore } = useUserStore();
  const { updateAuthUserLocalStorage } = useAuth();

  function updateUserAdmin(res: any) {
    apiOnboardingCustomer.send().then((customer) => {
      const isAdmin = !!customer?.roles?.find(
        (role: string) => role === 'ROLE_ADMIN'
      );
      if (isAdmin) {
        const newUserResponse = {
          ...res.data.user,
          admin: true,
        };

        const newAllResponse = {
          ...res,
          data: {
            ...res.data,
            user: newUserResponse,
          },
        };

        addUserStore(newUserResponse);
        updateAuthUserLocalStorage(newAllResponse);
      }
    });
  }

  return {
    updateUserAdmin
  };
}
