import { getCookie } from '@libs/utils/helpers/getSafeCookies';

const useACPSignUpExperiment = () => {
  const testCookieName = 'dx-acp-signup';

  const VARIANTS = {
    A: 'control',
    B: 'with-acp-signup'
  };

  const currentResult = getCookie(testCookieName, false);

  return {
    resultIsWithACPSignUp: !!currentResult?.includes(VARIANTS.B),
  };
};

export default useACPSignUpExperiment;