import { Fragment } from 'react';
import classNames from 'classnames';
import { DebtData } from '@common/hooks/useDebts/types';
import { LazyImage, Text } from '@consumidor-positivo/aurora';
import isMobile from '@libs/utils/helpers/isMobile';

import './styles.scss';

interface CardSectionProps {
  isFirstInstallmentPaid: boolean;
  debtData: DebtData;
  firstInstallmentDate: string;
  withoutPaymentData: boolean;
}

const CardSection = ({
  isFirstInstallmentPaid,
  debtData,
  firstInstallmentDate,
  withoutPaymentData,
}: CardSectionProps) => {
  const formattedLabel = (label: string) => {
    return isMobile() ? label.replace(/g([^g]*)$/, 'g.:') : label;
  };

  const pendingData = [
    {
      title: `Parcela 1 de ${debtData.paymentOpt.qtyInstallment}:`,
      value: debtData.paymentOpt.display.entryValue,
    },
    { title: `Vencimento:`, value: firstInstallmentDate },
  ];

  const paidData = [
    {
      title: 'Parcela:',
      value: `1 de ${debtData.paymentOpt.qtyInstallment}`,
    },
    { title: formattedLabel('Forma de pagamento:'), value: 'Pix' },
    {
      title: formattedLabel('Data de pagamento:'),
      value: firstInstallmentDate,
    },
    { title: 'Total pago:', value: debtData.paymentOpt.display.entryValue },
  ];

  const data = isFirstInstallmentPaid ? paidData : pendingData;

  return (
    <div
      className={classNames('card-section', {
        'card-section__success': isFirstInstallmentPaid,
        'card-section__without-payment-data': withoutPaymentData,
      })}
    >
      <div className="card-section__card">
        <LazyImage width={40} height={40} src={debtData.display.partnerImage} />
        <div className="card-section__card-content">
          {data.map(({ title, value }) => (
            <Fragment key={`card-content-${title}`}>
              <div className="card-section__card-line">
                <Text color="secondary">{title}</Text>
                <Text color="secondary" weight="bold">
                  {value}
                </Text>
              </div>
              <div className="closed-agreement-page__divider" />
            </Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CardSection;
