import { useContext } from 'react';
import type { Context } from 'react';

interface Config {
  name: string;
  provider: string;
}

const useContextWrapper = <T>(ReactContext: Context<T>, config: Config) => {
  const context = useContext(ReactContext);
  const { name, provider } = config;

  if (!context) {
    throw new Error(
      `Context ${name} not found. Did you forget to wrap your app with ${provider}?`
    );
  }

  return context;
};

export default useContextWrapper;
