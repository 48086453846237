import { ReactNode } from 'react';
import { useDeviceSize } from '@libs/utils/hooks/useDeviceSize';

import { Button, Text, Conditional } from '@consumidor-positivo/aurora';
import { OfferEmptyState } from '@hub/components/OfferEmptyState';
import Ilustration from '@common/assets/ilustration-woman-with-credit-card.svg';

import './styles.scss';

type FeaturedOfferTemplateProps = {
  title?: string;
  onClickMoreOffersBtn?: () => void;
  noOffer?: boolean;
  children: ReactNode | ReactNode[];
};

export function FeaturedOfferTemplate({
  title,
  onClickMoreOffersBtn,
  noOffer = false,
  children,
}: FeaturedOfferTemplateProps) {
  const { isMobile } = useDeviceSize();

  const buttonText = (isMobile ? 'Conhecer' : 'Ver') + ' mais ofertas';
  const buttonElement = (className: string) => (
    <Button
      type="outlined"
      className={`offers-featured__button ${className}`}
      onClick={onClickMoreOffersBtn}
    >
      {buttonText}
    </Button>
  );

  return (
    <div className="offers-featured">
      <div className="offers-featured__container">
        <Conditional
          condition={noOffer}
          renderIf={<OfferEmptyState />}
          renderElse={
            <>
              <div className="offers-featured__container-content">
                <Text
                  as="h2"
                  variant="heading-small"
                  variantDesk="heading-medium"
                  weight="bold"
                >
                  {title}
                </Text>

                {buttonElement('only-desk')}
                <img
                  className="offers-featured__container-content__img"
                  src={Ilustration}
                  alt="ilustração de uma garota apontando para um cartão"
                />
              </div>
              <div className="offers-featured__container-list">
                {children}
                <Text
                  variant="body-small"
                  weight="regular"
                  color="secondary"
                  className="offers-featured__container-list__disclaimer"
                >
                  Sujeito à análise de crédito
                </Text>
                {buttonElement('only-mob')}
              </div>
            </>
          }
        />
      </div>
    </div>
  );
}
