export const parseJwt = (token: string) => {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const rawData = window.atob(base64);
    const decoder = new TextDecoder('utf-8');
    const decodedData = decoder.decode(new Uint8Array([...rawData].map((char) => char.charCodeAt(0))));

    return JSON.parse(decodedData);
  } catch (e) {
    return null;
  }
};
