import { create } from 'zustand';
import {
  persist,
  createJSONStorage,
  StateStorage,
  devtools,
} from 'zustand/middleware';
import { UserStateStore } from './types';
import safestorage from '@libs/utils/helpers/getSafeStorage';
import { initialState } from './initialState';

export const useUserStore = create<UserStateStore>()(
  devtools(
    persist(
      (set, get) => ({
        ...initialState,
        addAuth: (payload) => set({ auth: { ...get().auth, ...payload } }),
        addUser: (payload) => set({ user: { ...get().user, ...payload } }),
        addFindUser: (payload) =>
          set({ findUser: { ...get().findUser, ...payload } }),
        addScore: (payload) => set({ score: { ...get().score, ...payload } }),
        addSortingDebt: (payload) =>
          set({ sortingDebt: { ...get().sortingDebt, ...payload } }),
        addMyDebt: (payload) =>
          set({ myDebt: { ...get().myDebt, ...payload } }),
        resetStore: () => {
          useUserStore.persist.clearStorage();
          set(initialState);
        },
      }),
      {
        name: 'ac-user-store',
        storage: createJSONStorage(() => safestorage as StateStorage),
        partialize: (state) => {
          const scoreData = state?.score;
          if (scoreData) { 
            const { negativeDebts: _, ...score } = scoreData;
            return { ...state, score }
          };
          return state;
        },
      }
    )
  )
);
