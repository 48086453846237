import { getCookie } from '@libs/utils/helpers/getSafeCookies'

export function getGa() {
  return getCookie('_ga');
}

export function getGAAvailableCookies(): { ga?: string} {
  const ga = getGa();
  if (!ga) return {};

  return { ga };
}
