import { getCookie } from '@libs/utils/helpers/getSafeCookies';

interface useShowPaymentMethodSelectorExperimentProps {
  partnerIdentifier: string;
  paymentMethodName?: string;
  singlePaymentMethod?: boolean;
}

const useShowPaymentMethodSelectorExperiment = ({
  partnerIdentifier = '',
  paymentMethodName,
  singlePaymentMethod,
}: useShowPaymentMethodSelectorExperimentProps) => {
  const testCookieName = 'dx-show-payment-method-selector';

  const VARIANTS = {
    A: 'default-pixleto',
    B: 'show-pix-pre-selected',
    C: 'show-billet-pre-selected',
  };

  const currentResult = getCookie(testCookieName, false);

  const partnersWithTwoSelectors = ['ativossa', 'cielo'];
  const currentPartnerHasTwoSelectors =
    partnersWithTwoSelectors?.includes(partnerIdentifier);

  const resultIsPixPreselect = !!currentResult?.includes(VARIANTS.B);
  const resultIsBilletPreselect = !!currentResult?.includes(VARIANTS.C);

  const shouldStartWithPixPreselected =
    resultIsPixPreselect && currentPartnerHasTwoSelectors;

  const showTwoSelectors =
    shouldStartWithPixPreselected ||
    (currentPartnerHasTwoSelectors && resultIsBilletPreselect);

  const shouldHidePixSection =
    !singlePaymentMethod && showTwoSelectors && paymentMethodName === 'BILLET';
  const shouldHideBilletSection =
    !singlePaymentMethod && showTwoSelectors && paymentMethodName == 'PIX';

  return {
    showTwoSelectors,
    shouldStartWithPixPreselected,
    shouldHidePixSection,
    shouldHideBilletSection,
  };
};

export default useShowPaymentMethodSelectorExperiment;
