import { Redline } from 'redline-client-sdk';
import { RLEcommerceEvents } from '../types';
import { pixel } from './pixel';
import { appsFlyerBridge } from './appsFlyer';

export const ecommerceEvents = (rl: Redline): RLEcommerceEvents => ({
  productClicked: (payload = {}) => {
    const event = 'ecommerce.ProductClicked.v1';
    rl.track(event, { event, ...payload });
    pixel(event, { event, ...payload });
    appsFlyerBridge().logEvent(event, { event, ...payload });
  },
  productListViewed: (payload = {}) => {
    const event = 'ecommerce.ProductListViewed.v1';
    rl.track(event, { event, ...payload });
    pixel(event, { event, ...payload });
    appsFlyerBridge().logEvent(event, { event, ...payload });
  },
  productViewed: (payload = {}) => {
    const event = 'ecommerce.ProductViewed.v1';
    rl.track(event, { event, ...payload });
    pixel(event, { event, ...payload });
    appsFlyerBridge().logEvent(event, { event, ...payload });
  },
  productListFiltered: (payload = {}) => {
    const event = 'ecommerce.ProductListFiltered.v1';
    rl.track(event, { event, ...payload });
    pixel(event, { event, ...payload });
    appsFlyerBridge().logEvent(event, { event, ...payload });
  },
});
