import SHA1 from 'crypto-js/sha1';
import { useEffect, useRef, useState } from 'react';
import { useRedline } from '@libs/redline';
import { legacyBase64Encrypt } from '@libs/utils/helpers/base64';
import constants from '@common/constants';
import { useDebts } from '@common/hooks/useDebts';
import { useUserStore } from '@common/store/useUserStore';
import useBillet from '@common/hooks/useBillet';
import useEngageTrack from '@common/hooks/useEngageTrack';
import usePix from '@common/hooks/usePix';
import { getFromLS } from '../../Confirmation/hooks/helpers';
import { getExtraInfo } from '../../helpers/extraInfo';
import { ClosedAgreementProps, CurrentExtraInfoProps } from '../types';

import useApiPartners from '@common/apis/partners/useApiPartners';
import useGTM from '@common/hooks/useGTM';
import useApiSortingDebt from '@common/apis/sorting-debt/useApiSortingDebt';
import { dateToLocale } from '@libs/utils/helpers/dateToLocale';
import useItapevaDueDateExperiment from '@common/abtest/experiments/useItapevaDueDateExperiment';

const useClosedAgreement = () => {
  const user = useUserStore((state) => state.user);
  const { apiSortingDebtInvalidateCache } = useApiSortingDebt();

  const { track: trackRedline } = useRedline();
  const { sendAgreementSettledEvent } = useEngageTrack();
  const GTM = useGTM();

  const { createDebtData } = useDebts();

  const [closedAgreement] = useState<ClosedAgreementProps>(() =>
    getFromLS(constants.debts.CLOSED_AGREEMENT)
  );

  const { apiCheckIfCanUpdateAgreement, apiUpdateAgreement } = useApiPartners(
    closedAgreement?.debt?.partner?.msUrl
  );

  const {
    billet,
    generateBillet,
    pollingGetBillet,
    hasBillet,

    loading,
    setLoading,
    showPollingBilletComp,
    showSkeleton,
  } = useBillet({
    installment: closedAgreement?.agreement?.acordoParcelas[0],
    msUrl: closedAgreement?.debt?.partner?.msUrl,

    closedAgreement,
  });

  const warningRef = useRef(null);

  const {
    pixData,
    paymentStatus,
    setPaymentStatus,

    isPixExternal,
    pixCode,
    qrCodeUrl,

    handleCopyPixCode,
    handleGeneratePixCode,
    isLoadingTryAgain,
    buttonText: pixText,
    statusLabels,

    isPixLoading,
    isPixSuccess,
    isCodeCopied,
  } = usePix({
    debt: closedAgreement?.debt,
    paymentOption: closedAgreement?.paymentOption,
    installment: closedAgreement?.agreement?.acordoParcelas[0],
    agreement: closedAgreement?.agreement,
    billetInternalPix: closedAgreement?.billetData?.linhaDigitavel,

    closedAgreement,
    refCopiedWarning: warningRef,
  });

  const [debtData] = useState(
    createDebtData({
      debt: closedAgreement?.debt,
      paymentOption: closedAgreement?.paymentOption,
      paymentMethod: closedAgreement?.paymentMethod,
    })
  );

  const { resultIsItapevaWithSelection } = useItapevaDueDateExperiment(
    debtData?.partnerIdentifier
  );

  const [currentExtraInfo, setCurrentExtraInfo] =
    useState<CurrentExtraInfoProps>();

  const getCurrentExtraInfo = (): void => {
    const extraInfo = getExtraInfo({
      user,
      debt: closedAgreement?.debt,
      paymentOption: closedAgreement?.paymentOption,
      paymentMethod: closedAgreement?.paymentMethod,
    })?.closedAgreement?.[
      closedAgreement?.debt?.partner?.partnerIdentifier as keyof object
    ];

    if (extraInfo) {
      setCurrentExtraInfo(currentExtraInfo);
    }
  };

  const getFirstInstallmentExpiration = () => {
    const hasAvailableDates =
      closedAgreement?.paymentOption?.propriedades?.some((prop) =>
        prop?.chave?.includes(constants.debts.PROPERTIES.PAYMENT_DATE)
      );
    const showSelectPaymentDate =
      hasAvailableDates &&
      (debtData?.identifier === 'recovery' || resultIsItapevaWithSelection);

    const installmentDueDate = dateToLocale(
      closedAgreement?.agreement?.acordoParcelas?.[0]?.dataVencimento
    );
    const dueDate = showSelectPaymentDate
      ? installmentDueDate
      : debtData?.paymentOpt?.dueDate;

    return dueDate;
  };

  const handleGenerateBillet = () => {
    const { isBradesco } = debtData.currentPartner;
    const { hasInstallments } = debtData.paymentOpt;
    const { hasEntryForDebitInAcc } = debtData.paymentMeth;

    if (isBradesco && !hasEntryForDebitInAcc && hasInstallments) return;

    const isBilletSelected =
      closedAgreement?.paymentOption?.paymentMethodName === 'BILLET';
    const noBilletData = !closedAgreement?.billetData?.hasBillet;

    const shouldGenerateBillet = isBilletSelected && noBilletData;

    if (shouldGenerateBillet) {
      const installmentCode =
        closedAgreement?.agreement?.acordoParcelas?.[0]?.installmentCode;

      const payload = {
        installmentCode: installmentCode,
        parameters: {
          partnerSystemIdentifier: closedAgreement?.debt?.partner?.identifier,
          requestParameters: {
            dividaId: closedAgreement?.debt?.id,
            opcaoPagamentoId: closedAgreement?.paymentOption.id,
          },
        },
      };

      generateBillet({ payload, closedAgreement });
    }
  };

  const ctrlSendErrorPix = useRef(true);
  const ctrlSendPixPaid = useRef(true);
  const trackPix = () => {
    if (!closedAgreement) return; // TODO: pixCode >> || !pixCode
    const { agreement, debt, paymentOption, paymentMethod } = closedAgreement;

    const isStatusErrorPix = paymentStatus === 'error';
    const isStatusPixPaidPix = paymentStatus === 'pixPaid';

    if (isStatusErrorPix && ctrlSendErrorPix.current) {
      ctrlSendErrorPix.current = false;
    }

    if (isStatusPixPaidPix && ctrlSendPixPaid.current) {
      ctrlSendPixPaid.current = false;

      trackRedline.debts.pixPayed({
        pixCode: pixData?.data?.pix_code,
        paymentOption,
        debt,
        agreement,
      });

      GTM.trigger.pixPayed({ valorAcordo: agreement?.valorAcordo });
    }
  };

  const sendEventEngage = () => {
    const fmtDateEvt = (dateStr: string) => {
      const safeDateStr = dateStr || '';
      const [day, month, year] = safeDateStr.split('/');
      return new Date(`${year}-${month}-${day}`).getTime();
    };
    const partner = closedAgreement?.debt?.partner?.name;
    const engageAgreement = localStorage.getItem('ENGAGE_AGREEMENT');
    const agreementId = closedAgreement?.agreement?.id.toString();

    if (engageAgreement !== agreementId) {
      localStorage.setItem('ENGAGE_AGREEMENT', agreementId);

      const installmentCode =
        closedAgreement?.agreement?.acordoParcelas?.[0].installmentCode || 0;

      const installmentDueDate = dateToLocale(
        closedAgreement?.agreement?.acordoParcelas?.[0]?.dataVencimento
      );

      const closedAgreementProps =
        closedAgreement?.paymentOption?.propriedades || [];

      const hasAvailableDates = closedAgreementProps.some((prop) =>
        prop?.chave?.includes(constants.debts.PROPERTIES.PAYMENT_DATE)
      );

      const showSelectPaymentDate =
        hasAvailableDates && (debtData?.identifier === 'recovery' || resultIsItapevaWithSelection);

      const dueDate = showSelectPaymentDate
        ? installmentDueDate
        : debtData?.paymentOpt?.dueDate;

      sendAgreementSettledEvent({
        billet_due_date: fmtDateEvt(dueDate),
        customer_id: SHA1(user.email).toString(),
        partner,
        agreement_value: closedAgreement?.agreement?.valorAcordo,
        ...(closedAgreement?.billetData?.linhaDigitavel && {
          barcode: closedAgreement?.billetData?.linhaDigitavel,
        }),
        installments_number: closedAgreement?.agreement?.qtdParcela,
        ...(installmentCode && {
          billet_link: `.... validate the url to be sent ...`,
        }),
        ...(installmentCode && {
          billet_link_end: installmentCode,
        }),
      });
    }
  };

  const handleUpdateAgreement = (request: any) => {
    const statusPixPaid = 'pixPaid';
    const SECONDS_IN_MS = 10000;

    const intervalUpdAgreement = setInterval(() => {
      apiUpdateAgreement
        .send(request)
        .then((response) => {
          const currentInstallment = response?.installments?.[0];
          const situationInstallment = currentInstallment?.situation;

          if (situationInstallment === 'PAYMENT_COMPLETED') {
            setPaymentStatus(statusPixPaid);
            localStorage.setItem(
              constants.debts.PIX_STATUS,
              legacyBase64Encrypt(statusPixPaid)
            );

            clearInterval(intervalUpdAgreement);
            return;
          }
        })
        .catch((err: any) => {
          console.error('[handleUpdateAgreement]:', err);
        });
    }, SECONDS_IN_MS);
  };

  const handleIfCanUpdateAgreement = () => {
    const { partnerIdentifier } = closedAgreement?.debt?.partner || {};
    const { PARTNERS_WHO_CAN_UPDATE_AGREEMENT } = constants.debts;

    const hasUpdateAgreement =
      PARTNERS_WHO_CAN_UPDATE_AGREEMENT.includes(partnerIdentifier);

    if (!hasUpdateAgreement || paymentStatus !== 'pending') return;

    const request = {
      agreementId: closedAgreement?.agreement?.id,
      documento: user?.documento,
    };

    apiCheckIfCanUpdateAgreement
      .send(request)
      .then((response) => {
        const hasUpdate = response;

        if (hasUpdate) handleUpdateAgreement(request);
      })
      .catch((err) => {
        console.error('handleIfCanUpdateAgreement ', err);
      });
  };

  useEffect(() => {
    trackPix();

    handleIfCanUpdateAgreement();
  }, [paymentStatus]);

  useEffect(() => {
    apiSortingDebtInvalidateCache.send({
      documento: user.documento,
      provider_identifier: closedAgreement?.debt?.partner?.identifier,
    });
    getCurrentExtraInfo();
    sendEventEngage();
    setLoading(false);
  }, []);

  useEffect(() => {
    if (hasBillet && billet.linhaDigitavel) return;
    pollingGetBillet(handleGenerateBillet);
  }, [billet.linhaDigitavel, hasBillet]);

  // TODO: knife
  const importantWarningAfterAgreement = (): string => {
    const warning = currentExtraInfo?.warningPartnerRenegotiation;
    return warning?.importantWarningAfterAgreement || '';
  };

  // TODO: knife
  const isRenegotiation = (): boolean => {
    const hasDebtAndExtraInfo = !!(closedAgreement?.debt && currentExtraInfo);
    if (hasDebtAndExtraInfo) {
      const warning = currentExtraInfo.warningPartnerRenegotiation;
      return warning ? warning.isRenegotiation : false;
    }
    return false;
  };

  return {
    debtData,
    selectedBankAccount: closedAgreement?.bankData?.selectedBankAccount,

    billet,
    hasBillet,
    loading,
    firstInstallmentDate: getFirstInstallmentExpiration(),
    paymentStatus,

    isPixExternal,
    qrCodeUrl,
    pixCode,
    pixText,

    closedAgreement,
    handleCopyPixCode,
    isLoadingTryAgain,
    isPixLoading,
    isPixSuccess,

    isCodeCopied,
    statusLabels,
    handleGeneratePixCode,

    importantWarningAfterAgreement: importantWarningAfterAgreement(),
    isRenegotiation: isRenegotiation(),

    showPollingBilletComp,
    showSkeleton,
    warningRef,
  };
};
export default useClosedAgreement;
