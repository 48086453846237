import { useRequest } from 'alova';
import acInterceptor from '@common/apis/acInterceptor';

const headers = {
  'Content-Type': 'application/json;charset=UTF-8',
  'Access-Control-Allow-Origin': '*',
};

const baseURL = import.meta.env.VITE_AC_API;

const useApiAgreements = () => {
  const apiGetAgreements = useRequest(
    acInterceptor({ baseURL }).Post<any>(
      '/marketplace/v1/app/agreement',
      {},
      {
        headers: headers,
      }
    ),
    { immediate: false }
  );

  return {
    apiGetAgreements,
  };
};

export default useApiAgreements;
