//Types
import type { BellTypes } from './types';

// images
import BadgeBell from '../../assets/badge.png';

// Hooks
import { useWidgetBellHook } from './hooks';

// Utils
import { subscribePushWeb } from '../../utils/subscribe';

// Styles
import './styles.css';

export const WidgetBell = ({ position = 'right' }: BellTypes) => {
  const { isVisible, margin, backgroundImage } = useWidgetBellHook();

  const bgImage = backgroundImage
    ? `url("${backgroundImage}")`
    : `url(${BadgeBell})`;

  return (
    <button
      id="push-bell"
      style={{
        [position]: 0,
        margin,
        backgroundImage: bgImage,
        display: isVisible ? 'block' : 'none',
      }}
      onClick={subscribePushWeb}
    ></button>
  );
};
