import classNames from 'classnames';
import { Alert } from '@consumidor-positivo/aurora';
import './styles.scss';

interface UpdatedWithSucessWarningProps {
  showWarning: boolean;
  warningText: string;
}
const UpdatedWithSuccessWarning = ({
  warningText,
  showWarning,
}: UpdatedWithSucessWarningProps) => {
  return (
    <div
      className={classNames('updated-with-success-warning', {
        'updated-with-success-warning--visible': showWarning,
        'updated-with-success-warning--hidden': !showWarning,
      })}
    >
      <Alert
        countdown={5}
        closeButton
        status="success"
        title={{
          content: `${warningText} com sucesso!`,
        }}
      />
    </div>
  );
};

export default UpdatedWithSuccessWarning;
