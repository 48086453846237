import { Redline } from 'redline-client-sdk';
import { RLMobileEvents } from '../types';
import { pixel } from './pixel';
import { appsFlyerBridge } from './appsFlyer';

export const mobileEvents = (rl: Redline): RLMobileEvents => ({
  appInfoTracked: (payload = {}) => {
    const event = 'mobile.AppInfoTracked.v1';
    rl.track(event, { event, ...payload });
    pixel(event, { event, ...payload });
    appsFlyerBridge().logEvent(event, { event, ...payload });
  },
  appSessionStarted: async (payload = {}) => {
    const event = 'mobile.AppSessionStarted.v1';
    await rl.track(event, { event, ...payload });
    pixel(event, { event });
    await appsFlyerBridge().logEvent(event, { event, ...payload });
  },
});
