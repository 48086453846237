import React, {
  Children,
  cloneElement,
  createContext,
  isValidElement,
  PropsWithChildren,
} from 'react';
import useContextWrapper from './hooks/useContextWrapper';

type DogxContextType = {
  experiments: Record<string, string>;
  getExperimentVariant: (name: string) => string;
};

type ExperimentProps = {
  experiment: string;
  children: React.ReactNode;
};

type VariantProps = {
  children?: React.ReactNode;
  name?: string;
  shouldRender?: boolean;
  shouldRenderDefault?: boolean;
  default?: boolean;
};

export const DogxContext = createContext<DogxContextType | null>(null);

export const useDogxContext = () =>
  useContextWrapper(DogxContext, { name: 'Dogx', provider: 'DogxProvider' });

export const DogxProvider = ({ children }: PropsWithChildren) => {
  const cookies = document.cookie.split(';');
  const dogxRawCookies = cookies.filter((cookie) =>
    cookie.trim().startsWith('dx-')
  );

  const dogxCookies = dogxRawCookies.reduce((acc, cookie) => {
    const [key, value] = cookie.trim().split('=');
    acc[key] = value.split(':')[0];
    return acc;
  }, {} as Record<string, string>);

  const getExperimentVariant = (name: string) => {
    return dogxCookies[name];
  };

  return (
    <DogxContext.Provider
      value={{ experiments: dogxCookies, getExperimentVariant }}
    >
      {children}
    </DogxContext.Provider>
  );
};

export const Experiment = ({ children, experiment }: ExperimentProps) => {
  const variant = useDogxContext().getExperimentVariant(experiment);

  const injectExperimentNameOnVariants = (
    children: React.ReactNode
  ): React.ReactNode => {
    return Children.map(children, (child) => {
      if (!isValidElement(child)) return child;

      if (child.type === Variant) {
        return cloneElement(
          child as React.ReactElement<VariantProps>,
          {
            shouldRender: !!variant && child.props.name === variant,
            shouldRenderDefault: !variant,
          },
          injectExperimentNameOnVariants(child.props.children)
        );
      }

      if (child.type === Experiment) return child;

      return cloneElement(
        child,
        child.props,
        injectExperimentNameOnVariants(child.props.children)
      );
    });
  };

  return injectExperimentNameOnVariants(children);
};

export const Variant = ({
  shouldRender,
  shouldRenderDefault,
  default: isDefault,
  children,
}: VariantProps) => {
  if (shouldRender || (shouldRenderDefault && isDefault)) {
    return <>{children}</>;
  }
  return null;
};

export const Dogx = {
  Experiment,
  Variant,
};
