import { COLOR_BRAND_BLUE_40, Spinner } from '@consumidor-positivo/aurora';

import './styles.scss';

export default function PageLoader() {
  return (
    <div className="page-loader">
      <Spinner size={32} color={COLOR_BRAND_BLUE_40} />
    </div>
  );
}

