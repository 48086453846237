/* eslint-disable @typescript-eslint/no-explicit-any */
import { useLocation } from 'react-router-dom';
import { getAnonymousID } from 'redline-client-sdk';

declare global {
  interface Window {
    gtag: any;
  }
}

interface ITrackingEventPayload {
  eventName: string;
  payload?: any;
}

export const useGoogleAnalytics = () => {
  const location = useLocation();

  function trackGA({ eventName, payload }: ITrackingEventPayload) {
    if (!window?.gtag) {
      return;
    } else {
      return new Promise<void>((resolve) => {
        const anonymousId = getAnonymousID();
        const url = location.pathname;

        const callback = {
          event_callback: function () {
            resolve();
          },
        };

        const safePayload = payload
          ? { ...payload, anonymousId, url, ...callback }
          : { anonymousId, url, ...callback };

        window.gtag('event', eventName, safePayload);
      });
    }
  }

  return { trackGA };
};
