import { createAlova, useRequest } from "alova";
import { addXAuthTokenHeaders } from '@common/apis/customAddHeaders';
import customStorageAdapter from '@common/apis/customStorageAdpter';
import { getAnonymousID, getSessionID } from "redline-client-sdk";
import GlobalFetch from 'alova/GlobalFetch';
import ReactHook from 'alova/react';

const alovaInstanceLoginEnrichment = createAlova({
  statesHook: ReactHook,
  requestAdapter: GlobalFetch(),
  baseURL: import.meta.env.VITE_AC_API,
  storageAdapter: customStorageAdapter,
  async beforeRequest(method) {
    await addXAuthTokenHeaders(method);
  },
});

const useApiLoginEnrichment = () => {
  const headers = {
    'Content-Type': 'application/json;charset=UTF-8',
    'Access-Control-Allow-Origin': '*',
    'x-anonymous-id': getAnonymousID(),
    'x-session-id': getSessionID(),
  };

  const apiLoginEnrichment = useRequest(
    () => alovaInstanceLoginEnrichment.Patch<any>(
        '/marketplace/v1/app/customer/login-enrichment',
        {},
        {
          headers: headers,
        }
      ),
    {
      immediate: false,
    }
  );

  return {
    apiLoginEnrichment
  }
};

export default useApiLoginEnrichment;
