/**
 * this array must contain all variables that can be sent to datalayer
 * it must match with registered variables on GTM
 */
export const datalayerAcceptedVariables = [
  'event',
  'email',
  'acordoId',
  'valorAcordo',
  'acordoId',
  'dividaId',
  'utm_source',
  'utm_medium',
  'utm_term',
  'partner'
] as const;
