import { useNavigate } from 'react-router-dom';
import { useUserStore } from '@common/store/useUserStore';
import useTrackOffer from '@common/hooks/useTrackOffer';

import { pageNames } from '@common/routes/pageNames';
import { delay } from '@libs/utils/helpers/delay';
import { OfferProps } from '../../types';
import { useDogxContext } from '@libs/dogx';

export const useOffer = ({ offer, index, location }: OfferProps) => {
  const navigate = useNavigate();
  const { user } = useUserStore((state) => state);
  const {
    trackProductViewed,
    trackProductClicked,
    trackProposalStarted,
    trackElementClicked,
    trackRedirectRequested,
  } = useTrackOffer();

  //#region TEST AB - New BB-Ourocard Flow
  const { getExperimentVariant } = useDogxContext();
  const hasNewBBFlow =
    getExperimentVariant('dx-bb-npf-newflow') === 'bb-npf-newflow';
  //#endregion

  const userFormatted = {
    id: user.customerIdHash,
    cpf: user.documento,
    fullName: user.nome,
    email: user.email,
    phone: user.celular,
    birthdate: user.dataNascimento,
  };

  const extraFunctions = {
    afterSendProposal: (fireboltId: string) => {
      trackProductClicked(offer, location, index, fireboltId);
    },
    beforeRedirect: (fireboltId: string, url: string) => {
      sendEventProposalStarted(url, location, fireboltId);
    },
    afterRedirect: (fireboltId: string, url: string) => {
      trackRedirectRequested(offer, location, url, fireboltId);
    },
  };

  const sendEventProposalStarted = async (
    redirectUrl: string,
    location: string,
    fireboltId: string
  ) => {
    const { content } = offer;
    const { slug, category, full_funnel } = content;

    if (full_funnel) return;

    const payload = {
      initialSlug: slug,
      finalSlug: slug,
      category,
      redirectUrl,
      location,
      fireboltId,
    };
    await trackProposalStarted(payload);
  };

  const handleDetailRedirect = async () => {
    trackElementClicked({
      elementType: 'card/button',
      name: offer.content.slug,
      location: location,
      text: 'Ver detalhes',
    });

    const detailUrl = pageNames.details.path.replace(
      ':slug',
      offer.content.slug
    );
    await delay(150);
    return navigate(detailUrl);
  };

  const sendEventProductViewed = () => {
    const { content, generalInfo } = offer;
    const { slug, title, category, partner, variant } = content;

    const payload = {
      slug,
      category,
      variant,
      name: title,
      brand: partner,
      price: generalInfo?.limit || 0,
      position: index,
      location: location,
    };

    trackProductViewed(payload);
  };

  return {
    userFormatted,
    extraFunctions,
    handleDetailRedirect,
    sendEventProductViewed,
    hasNewBBFlow
  };
};
