import { getCookie } from '@libs/utils/helpers/getSafeCookies';

export default function useItapevaDueDateExperiment(partnerIdentifier: string) {
  const testCookieName = 'dx-itapeva-select-duedate';

  const variants = {
    '0': 'without-date-select',
    '1': 'with-date-select',
  };

  const currentResult = getCookie(testCookieName);
  const isItapeva = partnerIdentifier === 'itapeva';

  return {
    resultIsItapevaWithSelection:
      !!currentResult?.includes(variants[1]) && isItapeva,
  };
}
