const RLAgreementsPayloadCreator = {
  EventsAgreementsContext(props: any) {
    const { partner = {}, debt = {}, ...agreement } = props || {};

    return {
      agreementContext: RLAgreementsPayloadCreator.agreementContext(agreement),
      partnerContext: RLAgreementsPayloadCreator.partnerContext(partner),
      debtContext: RLAgreementsPayloadCreator.debtContext(debt)
    };
  },

  agreementContext: ({
    agreementDate = '',
    agreementValue = 0,
    contract = '',
    id = '',
    originalInstallments = 0,
    paymentMethod = '',
    providerAgreementValue = 0,
    providerInstallments = 0,
    situation = '',
    status = ''
  }: any) => {
    return {
      agreementDate,
      agreementValue,
      contract,
      id,
      originalInstallments,
      paymentMethod,
      providerAgreementValue,
      providerInstallments,
      situation,
      status
    };
  },

  partnerContext: (partner: any) => {
    return {
      partnerId: partner?.id || '',
      identifier: partner?.identificador || '',
      name: partner?.nome || ''
    };
  },

  debtContext: (debt: any) => {
    return {
      debtId: debt?.id || 0,
      product: debt?.produto || '',
      contract: debt?.contrato || '',
      identifier: debt?.identificador || '',
      currentValue: debt?.valor || 0,
      minimalValue: debt?.valorMinimo || 0,
      originalValue: debt?.valorDivida || 0,
      originalDebtDate: debt?.detalhes?.[0]?.dataOriginalDivida || '',
      status: debt?.status || ''
    };
  },

  installment: (installment: any) => {
    const properties = installment?.properties || [];
    const agreementProperties = installment?.agreementProperties || [];

    const hasBillet = properties?.some(({ key, booleanValue }: any) => key === "boleto" && booleanValue) || false;
    const hasPix = properties?.some(({ key, stringValue }: any) => key === "AGREEMENT_EXTERNAL_PIX_CODE" && !!stringValue) || false;

    const agreementHasDebitAccount = agreementProperties?.find(({ key }: any) => key === "DEBITO_CONTA")?.booleanValue || false;
    const hasEntryDebitAcc = (agreementProperties?.find(({ key }: any) => key === "ENTRADA")?.decimalValue ?? 1) > 0;
    const isBradesco = installment?.partnerIdentifier === "bradesco";

    const rule1DebitAcc = agreementHasDebitAccount && (installment?.installmentNumber > 1);
    const rule2DebitAcc = !hasEntryDebitAcc && isBradesco;
    const hasDebitAccount = rule1DebitAcc || rule2DebitAcc;

    return {
      date: installment?.dueDate || 'No installment is found',
      id: installment?.id || 0,
      installment: installment?.installmentNumber || 0,
      status: installment?.status || 0,
      value: installment?.installmentValue || 0,
      hasBillet,
      hasPix,
      hasDebitAccount,
    }
  },

  paymentCodeCopiedPayload: (props: any) => {
    return {
      code: props?.code || '',
      codeType: props?.codeType || '',
      agreementId: props?.agreementId || 0,
      debtId: props?.debtId || 0,
      installmentId: props?.installmentId || 0,
      installment: props?.installment || 0,
      installmentValue: props?.installmentValue || 0
    };
  }
};

export default RLAgreementsPayloadCreator;
