import safestorage from '@libs/utils/helpers/getSafeStorage';

const customStorageAdapter = {
  set(key: string, value: any) {
    safestorage.setItem(key, value);
  },
  get(key: string) {
    safestorage.getItem(key);
  },
  remove(key: string) {
    safestorage.removeItem(key);
  },
};

export default customStorageAdapter
