// hooks
import { usePushWebSettingsStore } from '../../storage/settings';

export const useWidgetBellHook = () => {
  const { settings: configurationJaiminho } = usePushWebSettingsStore();
  const { options, isSubscribed, isInitialized } = configurationJaiminho;

  const isVisible =
    isInitialized && (options.bell || isSubscribed) && !options.prompt;

  return {
    isVisible,
    margin: options.margin,
    backgroundImage: options.bellBackgroundImage,
  };
};
