
/**
 * Opens a PDF in a new tab using a base64-encoded string.
 *
 * @param {string} pdf - The base64-encoded string representing the PDF content.
 * @param {number} [timeRevokeUrl=15000] - The time in milliseconds to revoke the PDF URL (default is 25000ms).
 *
 * @throws {Error} If the pdf parameter is empty or not a valid string.
 *
 * @example
 * const base64PDF = 'JVBERi0xLjcK...'; // Base64 string of the PDF
 * openPDFInBlob(base64PDF);
 */
export function openPDFInBlob(pdf: string, timeRevokeUrl = 25000) {
  if (!!pdf) {
    const byteCharacters = atob(pdf);
    const byteNumbers = new Uint8Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const blob = new Blob([byteNumbers], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);
    const newTab = window.open(url);

    if (!newTab) alert('Por favor, permita que o pop-up seja aberto.');

    setTimeout(() => {
      URL.revokeObjectURL(url);
    }, timeRevokeUrl);
  } else {
    console.error('Error: No data for PDF.');
  }
}
