import { Buffer } from 'buffer';

import { getCookie } from '../../../helpers/getCookies';
import { SHOffer } from '../types';

type SHStorage = {
  track: { count: number; done: boolean; paths: string[] };
  decide: { count: number; done: boolean; paths: string[] };
  triggerList: string[];
  offers: SHOffer[];
};

export const SH_STORAGE_KEY = 'SH';

export function SHCacheCount(
  countFor: 'decide' | 'track',
  action: 'get' | 'set'
) {
  try {
    const storage = SHCacheHandler('get');
    if (action === 'get') {
      return Number(storage[countFor]?.count || 0);
    }

    const currentCount = Number(storage[countFor]?.count || 0);
    SHCacheHandler('set', {
      [countFor]: { ...storage[countFor], count: currentCount + 1 },
    });
    return currentCount + 1;
  } catch (err) {
    console.error('[SH_STORAGE_COUNT]: ', err);
    return 0;
  }
}

export function SHCacheDone(
  doneFor: 'decide' | 'track',
  action: 'get' | 'set'
) {
  try {
    const storage = SHCacheHandler('get');
    if (action === 'get') {
      return Boolean(storage[doneFor]?.done);
    }

    SHCacheHandler('set', {
      [doneFor]: { ...storage[doneFor], done: true },
    });
    return true;
  } catch (err) {
    console.error('[SH_STORAGE_DONE]: ', err);
    return false;
  }
}

export function SHCachePaths(
  pathsFor: 'decide' | 'track',
  action: 'get' | 'set'
) {
  try {
    const storage = SHCacheHandler('get');
    if (action === 'get') {
      return storage[pathsFor]?.paths || [];
    }

    SHCacheHandler('set', {
      [pathsFor]: {
        ...storage[pathsFor],
        paths: [...storage[pathsFor].paths, window.location.href],
      },
    });
    return storage[pathsFor]?.paths || [];
  } catch (err) {
    console.error('[SH_STORAGE_PATHS]: ', err);
    return [];
  }
}

export function SHCache(offers?: SHOffer[]) {
  if (offers && offers?.length > 0) {
    return SHCacheHandler('set', { offers });
  }

  return SHCacheHandler('get');
}

export function SHCacheClear() {
  sessionStorage.removeItem(SH_STORAGE_KEY);
}

function SHCacheHandler(
  action: 'get' | 'set',
  value?: Partial<SHStorage>
): SHStorage {
  const hasDebug = getCookie('DEBUG_SH');
  const defaultValue = {
    track: { count: 0, done: false, paths: [] },
    decide: { count: 0, done: false, paths: [] },
    triggerList: [],
    offers: [],
  };

  try {
    const rawDefault = JSON.stringify(defaultValue);
    const safeDefault = hasDebug
      ? rawDefault
      : Buffer.from(rawDefault, 'utf-8').toString('base64');

    const rawValue = sessionStorage.getItem(SH_STORAGE_KEY) || safeDefault;
    const decodedValue = hasDebug
      ? rawValue
      : Buffer.from(rawValue, 'base64').toString('utf-8');

    const cachedValue = JSON.parse(decodedValue);
    if (action === 'get') return cachedValue;

    const newValue = { ...cachedValue, ...value };
    const safeValue = JSON.stringify(newValue);
    const encodedValue = hasDebug
      ? safeValue
      : Buffer.from(safeValue, 'utf-8').toString('base64');

    sessionStorage.setItem(SH_STORAGE_KEY, encodedValue);
    return newValue;
  } catch (err) {
    console.error('[SH_STORAGE]: ', err);
    return defaultValue;
  }
}
