import { UserTokenProps } from '@admission/apis/onboarding/types';
import constants from '@common/constants';
import { getCookie } from '@libs/utils/helpers/getSafeCookies';
import safestorage from '@libs/utils/helpers/getSafeStorage';
import { getAnonymousID, getSessionID } from 'redline-client-sdk';

export async function addXAuthTokenHeaders(method: any) {
  await safestorage.getItem(constants.auth.AUTH_USER_TOKEN).then((res) => {
    const response = res as UserTokenProps;
    const legacyAuthToken = getCookie(constants.legacyAuth.TOKEN);

    if (legacyAuthToken || response?.access_token) {
      method.config.headers['Accept'] = 'application/json, text/plain, */*';
      method.config.headers['Content-Type'] = 'application/json';

			const isEngageSignIn = method?.url === "/marketplace/v1/engage/signin";
			if(!isEngageSignIn) {
				method.config.headers['X-Auth-Token'] = legacyAuthToken || response?.access_token
			}
    }
  });
}

export async function addSessionHeaders(method: any) {
  method.config.headers['x-anonymous-id'] = getAnonymousID();
  method.config.headers['x-session-id'] = getSessionID();
}
