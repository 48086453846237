import Cookies from 'js-cookie';
import Base64 from 'crypto-js/enc-base64';

import { AdFormat, AD_SIZE_MAPPINGS } from './constants';

function getAdMinHeight(
  format: AdFormat = AdFormat.RESPONSIVE,
  customSizeMapping?: googletag.SizeMappingArray
) {
  const viewport = window.innerWidth;
  const sizeMapping = customSizeMapping || AD_SIZE_MAPPINGS[format];

  // Find the appropriate size mapping for the current viewport
  const matchingMapping = sizeMapping
    .filter(([breakpoint]) => viewport >= breakpoint[0])
    .sort(([breakpointA], [breakpointB]) => breakpointB[0] - breakpointA[0])[0];

  if (!matchingMapping) return 0;

  // Extract sizes from the mapping
  const sizes = matchingMapping[1];

  // Find the maximum height from the available ad sizes
  const maxHeight = (sizes as Array<googletag.SingleSizeArray>).reduce(
    (acc, [, height]) => (height > acc ? height : acc),
    0
  );

  return maxHeight;
}

export function setContainerMinHeight(
  container: HTMLElement,
  format?: AdFormat,
  customSizeMapping?: googletag.SizeMappingArray
) {
  const minHeight = getAdMinHeight(format, customSizeMapping);

  requestAnimationFrame(() => {
    container.style.minHeight = `${minHeight}px`;
  });
}

export function debounce<T extends (...args: any[]) => any>(
  func: T,
  wait: number,
  immediate = false
): (...args: Parameters<T>) => void {
  let timeout: NodeJS.Timeout | null;

  return function (this: any, ...args: Parameters<T>) {
    const later = () => {
      timeout = null;
      if (!immediate) func.apply(this, args);
    };

    const callNow = immediate && !timeout;
    clearTimeout(timeout as NodeJS.Timeout);
    timeout = setTimeout(later, wait);

    if (callNow) func.apply(this, args);
  };
}

export function getCookie(key: string, decrypt?: boolean) {
  const valueEncoded = Cookies.get(key);

  return decrypt && valueEncoded
    ? Base64.parse(valueEncoded).toString()
    : valueEncoded;
}
