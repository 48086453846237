import { useNavigate, useLocation } from "react-router-dom";

import { FindUserDataResponseProps } from "@admission/apis/onboarding/types";
import { pageNames } from "@common/routes/pageNames";

import { useUserStore } from "@common/store/useUserStore";
import { delay } from "@libs/utils/helpers/delay";

const useImplicitMFA = () => {
	const navigate = useNavigate();
	const location = useLocation();

	const { search } = location;

	const {
		addFindUser: addFindUserStore,
	} = useUserStore();

	const handleImplicitMFA = async (user: FindUserDataResponseProps) => {
		const {
			documento,
			celular,
			registrado,
			cutOffValue,
			customerIdHash,
			email,
		} = user;

		addFindUserStore({
			documento,
			celular,
			registrado,
			cutOffValue,
			customerIdHash,
			email,
		});

		navigate(`${pageNames.signin.path}${search}`, { state: { isMyDebtsEngageSignin: true } });
	}

	return {
		handleImplicitMFA
	}
}

export default useImplicitMFA;