import { lazy } from 'react';
import { Route } from 'react-router-dom';
import { pageNames } from '@common/routes/pageNames';

const MyDebts = lazy(() => import('@dues/pages/MyDebts'));
const Debts = lazy(() => import('@dues/pages/Debts'));

const Confirmation = lazy(() => import('@dues/pages/Partner/Confirmation'));
import ClosedAgreement from '@dues/pages/Partner/ClosedAgreement';
const ClosedAgreementError = lazy(() => import('@dues/pages/Partner/ClosedAgreementError'));

const MyAgreements = lazy(() => import('@dues/pages/MyAgreements'));
const MyAgreementsDetail = lazy(() => import('@dues/pages/MyAgreements/V1/MyAgreementsDetail'));
const MyAgreementsDetailConditions = lazy(() => import('@dues/pages/MyAgreements/V1/MyAgreementsDetail/components/MyAgreementsDetailConditions'));
const MyAgreementsDetailCompositions = lazy(() => import('@dues/pages/MyAgreements/V1/MyAgreementsDetail/components/MyAgreementsDetailCompositions'));

const BilletNotFoundError = lazy(() => import('@dues/components/BilletNotFoundError'));

export function Router() {
  return (
    <>
      <Route path={pageNames.myDebts.path} element={<MyDebts />} />
      <Route path={pageNames.debts.path} element={<Debts />} />
      <Route path={pageNames.confirmation.path} element={<Confirmation />} />
      <Route path={pageNames.agreementClosed.path} element={<ClosedAgreement />} />
      <Route path={pageNames.agreementClosedError.path} element={<ClosedAgreementError />} />
      <Route path={pageNames.myAgreements.path} element={<MyAgreements />} />
      <Route path={pageNames.myAgreementsDetail.path} element={<MyAgreementsDetail />} />
      <Route path={pageNames.myAgreementsDetailConditions.path} element={<MyAgreementsDetailConditions />} />
      <Route path={pageNames.myAgreementsDetailCompositions.path} element={<MyAgreementsDetailCompositions />} />
      <Route path={pageNames.billetNotFoundError.path} element={<BilletNotFoundError />} />
    </>
  );
}
