import classNames from 'classnames';
import { Conditional, LazyImage, Text } from '@consumidor-positivo/aurora';
import SuccessIcon from '@dues/assets/icons/success-check-icon.svg';
import { DebtData } from '@common/hooks/useDebts/types';
import './styles.scss';

import illuClosedAgreement from '@dues/assets/images/illustration-closed-agreement.svg';
import illuClosedAgreementMobile from '@dues/assets/images/illustration-closed-agreement-mobile.svg';
import { useDeviceSize } from '@libs/utils/hooks/useDeviceSize';

interface HeadingSectionProps {
  debtData: DebtData;
  isFirstInstallmentPaid?: boolean;
  withoutPaymentData?: boolean;
  hasDebitAccount?: boolean;
  firstInstallmentDate: string;
}
interface DataProps {
  pretitle: string;
  title: string;
  subtitle?: string;
}
interface HeadingSectionDataProps {
  paid: DataProps;
  pending: DataProps;
  withoutPaymentData?: DataProps;
  hasDebitAccount?: DataProps;
}

const HeadingSection = ({
  debtData,
  isFirstInstallmentPaid,
  withoutPaymentData,
  hasDebitAccount,
  firstInstallmentDate,
}: HeadingSectionProps) => {
  const [day, month] = firstInstallmentDate.split('/');
  const dueDate = `${day}/${month}`;

  const { isMobile } = useDeviceSize();

  const headingSectionData: HeadingSectionDataProps = {
    paid: {
      pretitle: 'Tudo certo!',
      title: 'Recebemos o seu pagamento!',
      subtitle: `Ele está sendo analisado pelo nosso time, a confirmação do pagamento do acordo
      ${!isMobile ? '<br />' : ''} pode levar até 5 dias úteis.`,
    },
    pending: {
      pretitle: 'Acordo feito com sucesso!',
      title: `Pague
        <span>
          ${debtData.paymentOpt.display.entryValue} até o dia ${dueDate}
          ${!isMobile ? '<br />' : ''}
        </span>
        para garantir as condições do acordo.`,
    },
    hasDebitAccount: {
      pretitle: 'Acordo feito com sucesso!',
      title: `O valor <span>${debtData.paymentOpt.display.entryValue}</span> será debitado da sua conta <span>no dia ${dueDate}.</span>`,
    },
  };

  const headingDataKey = isFirstInstallmentPaid
    ? 'paid'
    : hasDebitAccount
    ? 'hasDebitAccount'
    : 'pending';

  const headingData = headingSectionData[headingDataKey];

  const isTablet = window.innerWidth >= 720 && window?.innerWidth <= 1024;

  const illustration =
    isMobile || isTablet
      ? { src: illuClosedAgreementMobile, width: 320, height: 94 }
      : { src: illuClosedAgreement, width: 700, height: 164 };

  return (
    <div
      className={classNames('heading-section', {
        'heading-section__success':
          isFirstInstallmentPaid,
        'heading-section__debit-account': hasDebitAccount,
        'heading-section__without-payment-data': withoutPaymentData,
      })}
    >
      <Conditional
        condition={!!hasDebitAccount}
        renderIf={
          <LazyImage
            lazy={false}
            src={illustration.src}
            width={illustration.width}
            height={illustration.height}
          />
        }
      />
      <div className="heading-section__title">
        <Conditional
          condition={!!isFirstInstallmentPaid}
          renderIf={
            <LazyImage src={SuccessIcon} width={64} height={64} alt="" />
          }
        />

        <Text color="secondary" weight="semibold">
          {headingData?.pretitle}
        </Text>
        <Text
          variant={
            withoutPaymentData && !isMobile ? 'heading-medium' : 'heading-small'
          }
          as="span"
          weight="bold"
          dangerouslySetInnerHTML={headingData?.title}
        />
        <Conditional
          condition={!!isFirstInstallmentPaid}
          renderIf={
            <Text
              color="secondary"
              dangerouslySetInnerHTML={headingData?.subtitle}
            />
          }
        />
      </div>
    </div>
  );
};

export default HeadingSection;
