import { Redline } from 'redline-client-sdk';
import { RLUserAuthenticationEvents } from '../types';
import { pixel } from './pixel';
import { appsFlyerBridge } from './appsFlyer';

export const userAuthenticationEvents = (
  rl: Redline,
  appName?: string
): RLUserAuthenticationEvents => ({
  checkRegisterStatus: (payload = {}) => {
    const event = 'userAuthentication.CheckRegisterStatus.v1';

    rl.track(event, { event, ...payload });
    pixel(event, { event, ...payload });
    appsFlyerBridge().logEvent(event, { event, ...payload });
  },
  signUpStarted: (payload = {}) => {
    const event = 'userAuthentication.SignUpStarted.v1';
    rl.track(event, { event, ...payload });
    pixel(event, { event, ...payload });
    appsFlyerBridge().logEvent(event, { event, ...payload });
  },
  signUpCompleted: (payload = {}) => {
    const event = 'userAuthentication.SignUpCompleted.v1';
    rl.track(event, { event, ...payload });
    pixel(event, { event, ...payload });
    appsFlyerBridge().logEvent(event, { event, ...payload });
  },
  signUpErrored: (payload = {}) => {
    const event = 'userAuthentication.SignUpErrored.v1';
    rl.track(event, { event, ...payload });
    pixel(event, { event, ...payload });
    appsFlyerBridge().logEvent(event, { event, ...payload });
  },
  signInStarted: (payload = {}) => {
    const event = 'userAuthentication.SignInStarted.v1';
    rl.track(event, { event, ...payload });
    pixel(event, { event, ...payload });
    appsFlyerBridge().logEvent(event, { event, ...payload });
  },
  signInCompleted: (payload = {}) => {
    const event = 'userAuthentication.SignInCompleted.v1';
    rl.track(event, { event, ...payload });
    pixel(event, { event, ...payload });
    appsFlyerBridge().logEvent(event, { event, ...payload });
  },
  signInErrored: (payload = {}) => {
    const event = 'userAuthentication.SignInErrored.v1';
    rl.track(event, { event, ...payload });
    pixel(event, { event, ...payload });
    appsFlyerBridge().logEvent(event, { event, ...payload });
  },
  userAuthenticated: (payload = {}) => {
    const event = 'userAuthentication.UserAuthenticated.v1';
    rl.track(event, { event, ...payload });

    pixel(event, { event, ...payload });
    appsFlyerBridge().logEvent(event, { event, ...payload });
  },
  userIdentified: (payload = {}) => {
    const event = 'userAuthentication.UserIdentified.v1';
    rl.track(event, { ...payload });
    pixel(event, { event, ...payload });
    appsFlyerBridge().logEvent(event, { event, ...payload });
  },
  userAttributeUpdated: (payload = {}) => {
    const event = 'userAuthentication.UserAttributeUpdated.v1';
    rl.track(event, { ...payload });
    pixel(event, { event, ...payload });
    appsFlyerBridge().logEvent(event, { event, ...payload });
  },
});
