export const parseDuration = (duration: string) => {
  const unitsInSeconds: { [x: string]: number } = {
    second: 1,
    minute: 60,
    hour: 3600,
    day: 86400,
    week: 604800,
    month: 2629746,
    year: 31556952,
  };
  const valueAndUnit = duration.split(' ', 2);
  const value = parseInt(valueAndUnit[0]);
  const unit =
    valueAndUnit[1].slice(-1) == 's'
      ? valueAndUnit[1].slice(0, -1)
      : valueAndUnit[1];
  return value * unitsInSeconds[unit] * 1000;
};
