import { getCookie } from '@libs/utils/helpers/getSafeCookies';

const usePixKroton = () => {
  const testCookieName = 'dx-pix-kroton';

  const variants = {
    '0': 'with-pix-kroton',
    '1': 'without-pix-kroton'
  };

  const resultIsWithPixKroton = () => {
    const currentResult = getCookie(testCookieName, false);

    if (!currentResult) return false;

    return currentResult.includes(variants[0]);
  };

  return {
    resultIsWithPixKroton,
  };
};

export default usePixKroton;