import { Component, ErrorInfo, ReactNode } from 'react';
import ErrorBoundaryMessage from './Message';

interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
  error?: Error;
}

export default class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error): {
    hasError: boolean;
    error: Error;
  } {
    return { hasError: true, error };
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    const renderingError = new Error(error.message);
    renderingError.name = `ReactRenderingError`;
    renderingError.stack = info.componentStack;
    if((renderingError as any).cause){
      (renderingError as any).cause = error;
    }
    
    if( window?.DD_RUM){
      window?.DD_RUM.onReady(function() {
        window?.DD_RUM.addError(renderingError);
     });
    }
  }

  render() {
    if (this.state.hasError && this.state.error) {
      return <ErrorBoundaryMessage error={this.state.error}  />;
    }

    return this.props.children;
  }
}
