import constantsRedline from "./redline";
import constantsAuth from "./auth";
import constantsLegacyAuth from "./legacyAuth";
import constantsRiachuelo from "./riachuelo";
import cookies from "./cookies";
import constantsDebts from "./debts";
import constantsLoans from "./loans";

const constants = {
  auth: constantsAuth,
  cookies: cookies,
  legacyAuth: constantsLegacyAuth,
  redline: constantsRedline,
  riachuelo: constantsRiachuelo,
  debts: constantsDebts,
  loans: constantsLoans
}

export default constants
