import { Redline } from 'redline-client-sdk';

import RLAgreementsPayloadCreator from './rl-agreements-payload.creator';
import * as H from './helpers';

import * as TPayload from './types/agreements.payload';
import {
  controlEventSending,
  removeCtrlCookieOnEventError,
} from '../../helpers/send-events-by-session';

const CTRL_AGREEMENTS_COOKIE_KEY = 'rl_ctrl_agreements_react';

export const agreementsEvents = (rl: Redline) => ({
  /**
   * @description Deve ser disparado quando o usuário visualizar o acordo na lista de acordos.
   * @param {object} agreement - Objeto do acordo.
   *
   * @example
   * track.agreements.agreementViewed(agreement);
   */
  agreementViewed(agreement: any) {
    const idEvent = `AgreementViewed|${agreement?.id}`;

    const data: TPayload.AgreementViewed = {
      ...RLAgreementsPayloadCreator.EventsAgreementsContext(agreement),
    };
    return controlEventSending(CTRL_AGREEMENTS_COOKIE_KEY, idEvent, () => {
      return rl
        .track('agreements.AgreementViewed.v1', { ...data })
        .catch((err) => {
          removeCtrlCookieOnEventError(
            CTRL_AGREEMENTS_COOKIE_KEY,
            idEvent,
            err
          );
        });
    });
  },

  /**
   * @description Deve ser disparado quando o usuário clicar em um acordo na lista de acordos.
   * @param {object} agreement - Objeto do acordo.
   *
   * @example
   * track.agreements.agreementClicked(agreement);
   */
  agreementClicked(agreement: any) {
    const data: TPayload.AgreementClicked = {
      ...RLAgreementsPayloadCreator.EventsAgreementsContext(agreement),
    };

    return rl.track('agreements.AgreementClicked.v1', { ...data });
  },

  agreementsListLoaded(agreements: any) {
    const safeAgreements = agreements || [];
    const formattedAgreements = safeAgreements.map((a: any) =>
      RLAgreementsPayloadCreator.agreementContext(a)
    );

    const payload = {
      agreements: formattedAgreements,
    };

    return rl.track('agreements.AgreementsListLoaded.v1', { ...payload });
  },

  /**
   * @description Deve ser disparado quando o usuário acessar a tela do acordo selecionado.
   * @param {object} agreement - Objeto do acordo.
   *
   * @example
   * track.agreements.agreementDisplayed(agreement);
   */
  agreementDisplayed(agreement: any) {
    const agreementProperties = agreement?.properties || [];
    const partnerIdentifier = agreement?.partner?.identificador || '';

    const addAgreementProps = (installment: any) => {
      return { ...installment, agreementProperties, partnerIdentifier };
    };

    const { currentInstallment, previousInstallment } =
      H.getAgreementInstallments(agreement);

    const data: TPayload.AgreementDisplayed = {
      ...RLAgreementsPayloadCreator.EventsAgreementsContext(agreement),
      currentInstallment: RLAgreementsPayloadCreator.installment(
        addAgreementProps(currentInstallment)
      ),
      previousInstallment: RLAgreementsPayloadCreator.installment(
        addAgreementProps(previousInstallment)
      ),
    };

    return rl.track('agreements.AgreementDisplayed.v1', { ...data });
  },

  /**
   * @description Deve ser disparado quando o usuário clicar para copiar o código do PIX ou boleto.
   * @param {string} props.code - Código que está sendo copiado.
   * @param {string} props.codeType - Tipo de código que está sendo copiado (Pix ou Boleto).
   * @param {number} props.agreementId - ID do acordo.
   * @param {number} props.debtId - ID da dívida.
   * @param {number} props.installmentId - ID da parcela.
   * @param {number} props.installment - Número da parcela.
   * @param {number} props.installmentValue - Valor da parcela.
   *
   * @example
   * track.agreements.paymentCodeCopied({
   *   code: '123456789',
   *   codeType: 'PIX',
   *   agreementId: 1,
   *   debtId: 2,
   *   installmentId: 3,
   *   installment: 1,
   *   installmentValue: 100.00
   * });
   */
  paymentCodeCopied(props: TPayload.PaymentCodeCopied) {
    const data: TPayload.PaymentCodeCopied = {
      ...RLAgreementsPayloadCreator.paymentCodeCopiedPayload(props),
    };

    return rl.track('agreements.PaymentCodeCopied.v1', { ...data });
  },

  /**
   * @description Deve ser disparado quando ocorrer um erro ao tentar copiar o código do PIX ou boleto.
   * @param {string} props.code - Código que está sendo copiado.
   * @param {string} props.codeType - Tipo de código que está sendo copiado (Pix ou Boleto).
   * @param {number} props.agreementId - ID do acordo.
   * @param {number} props.debtId - ID da dívida.
   * @param {number} props.installmentId - ID da parcela.
   * @param {number} props.installment - Número da parcela.
   * @param {number} props.installmentValue - Valor da parcela.
   * @param {string} props.errorMessage - Mensagem de erro.
   *
   * @example
   * track.agreements.PaymentCodeCopyErrored({
   *   code: '40302010',
   *   codeType: 'BILLET',
   *   agreementId: 123,
   *   debtId: 345,
   *   installmentId: 122,
   *   installment: 3,
   *   installmentValue: 140.50,
   *   errorMessage: 'interrupted copying'
   * });
   */
  paymentCodeCopyErrored(props: TPayload.PaymentCodeCopyErrored) {
    const data: TPayload.PaymentCodeCopyErrored = {
      ...RLAgreementsPayloadCreator.paymentCodeCopiedPayload(props),
      errorMessage: props?.errorMessage || 'no error message',
    };

    return rl.track('agreements.PaymentCodeCopyErrored.v1', { ...data });
  },

  /**
   * @description Deve ser disparado quando ocorrer um erro para listar os acordos.
   * @param {string} props.errorDetails - Detalhes do erro.
   * @param {string} props.errorMessage - Mensagem de erro.
   * @param {string} props.errorType - Tipo do erro.
   *
   * @example
   * track.agreements.agreementListingErrored({
   *   errorDetails: 'Unknown error',
   *   errorMessage: 'Error: no installment for the agreement'
   *   errorType: '400 - Bad request',
   * });
   */
  agreementListingErrored(props: TPayload.AgreementListingErrored) {
    const data: TPayload.AgreementListingErrored = {
      errorDetails: props?.errorDetails || '',
      errorMessage: props?.errorMessage || '',
      errorType: props?.errorType || '',
    };

    return rl.track('agreements.PaymentCodeCopyErrored.v1', { ...data });
  },
});
