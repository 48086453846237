// @ts-nocheck
interface iPixelPayloadParams {
  name: string;
  contentType?: string;
  separator?: string;
  properties: any;
  context: any;
}

export const keysPixel = {
  cp: '328658652481268',
};

const fbEventName: { [key: string]: string } = {
  'userTracking.FormSubmitted.v1': 'AddToCart',
};

const generateFlatPayload: (
  payload: any | null,
  separator?: string,
  sub?: string
) => void = (payload: any | null, separator = '.', sub?: string) => {
  return payload === null
    ? payload
    : Object.keys(payload).reduce((flatPayload, key) => {
        const value = payload[key];
        const subKey = sub ? `${sub}${separator}${key}` : key;

        if (typeof value === 'object') {
          const subPayload: any = generateFlatPayload(value, separator, subKey);
          return { ...subPayload, ...flatPayload };
        } else {
          return {
            ...flatPayload,
            [subKey]: value,
          };
        }
      }, {});
};

export const generatePixelPayload = ({
  name,
  properties = {},
  contentType = 'product',
  context = {},
  separator,
}: iPixelPayloadParams) => {
  const isSpecialEvent = Object.keys(fbEventName).includes(name);

  const getSpecialEventProp = (prop: any) => {
    return isSpecialEvent ? prop : null;
  };

  const contentIds = properties?.captureContext?.valueProp;
  const content_type = getSpecialEventProp(contentType);
  const content_ids = getSpecialEventProp(contentIds);

  const newProperties = {
    ...properties,
    field: properties?.field,
  };

  const flatPayload: any = generateFlatPayload(
    {
      properties: newProperties,
      context,
    },
    separator
  );

  return { ...flatPayload, content_type, content_ids };
};

export const pixel = (
  name: string,
  properties = {},
  contentType?: string,
  context = {}
) => {
  const isSpecialEvent = Object.keys(fbEventName).includes(name);
  const eventName = isSpecialEvent ? fbEventName[name] : name;
  const eventPayload = generatePixelPayload({
    name: eventName,
    properties,
    contentType,
    context,
  });

  if (import.meta.env.VITE_CUSTOM_BUILD) return;

  const isDebtSimulated = name === 'user_authenticated_with_debt';
  const isNegativeDebtSimulated =
    name === 'user_authenticated_with_negative_debt';
  const shouldDispatchBothContainers = isDebtSimulated || isNegativeDebtSimulated;

  if (window?.isNativeApp && window?.NativeFeatures?.FacebookEvents) {
    const payload = {
      action: 'sendFacebookEvent',
      payload: { eventName, data: eventPayload },
    };
    const stingfiedPayload = JSON.stringify(payload);
    window.ReactNativeWebView.postMessage(stingfiedPayload);
    if (!shouldDispatchBothContainers) return;
  }

  if (typeof fbq !== 'undefined') {
    fbq('trackSingleCustom', keysPixel.cp, eventName, eventPayload);
  }
};
