import classNames from 'classnames';
import { Button, Conditional } from '@consumidor-positivo/aurora';
import './styles.scss';

type ButtonsCardOfferProps = {
  text: string;
  showDetailButton?: boolean;
  smallCard?: boolean;
  loading?: boolean;
  onClickOffer?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onClickDetailOffer?: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

export const ButtonsOfferCard = ({
  text,
  showDetailButton = false,
  smallCard = false,
  loading = false,
  onClickOffer,
  onClickDetailOffer,
}: ButtonsCardOfferProps) => {
  const buttonClasses = classNames('common-offer-card__btn-container', {
    'common-offer-card__btn-container--small': smallCard,
  });

  return (
    <div className={buttonClasses}>
      <Button
        expand="x"
        onClick={onClickOffer}
        loading={loading}
        disabled={loading}
      >
        {text}
      </Button>
      <Conditional
        condition={showDetailButton}
        renderIf={
          <Button expand="x" type="outlined" onClick={onClickDetailOffer}>
            Ver detalhes
          </Button>
        }
      />
    </div>
  );
};
