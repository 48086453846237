// Data
import { RECREATE_SUBSCRIPTION } from '../constants';

// Hooks
import { usePushWebSettingsStore } from '../storage/settings';

// Utils
import { parseDuration } from './parseDuration';
import { subscribePushWeb } from './subscribe';

const isSubscriptionExpired = (expiredSubscription: string) => {
  const currentTime = Date.now();
  const expiredDate = parseInt(expiredSubscription);

  return currentTime - expiredDate >= parseDuration(RECREATE_SUBSCRIPTION);
};

const updateExpiredSubscription = (timestamp: number) => {
  usePushWebSettingsStore.getState().handleSetSettings({
    expiredSubscription: String(timestamp),
  });
};

export const checkSubscription = async () => {
  try {
    const configurationJaiminho = usePushWebSettingsStore.getState();
    const { expiredSubscription } = configurationJaiminho.settings;

    if (!expiredSubscription) {
      updateExpiredSubscription(Date.now());
      return;
    }

    if (isSubscriptionExpired(expiredSubscription)) {
      await subscribePushWeb();
      updateExpiredSubscription(Date.now());
    }
  } catch (error) {
    console.log('Push Web: Subscription not renewed.', error);
  }
};
