import { lazy } from 'react';
import { Route, Navigate, useLocation } from 'react-router-dom';
import { pageNames } from '@common/routes/pageNames';

import useACPSignUpExperiment from '@common/abtest/experiments/useACPSignUpExperiment';
import useACPSignInExperiment from '@common/abtest/experiments/useACPSignInExperiment';

const ErrorKba = lazy(() => import('@admission/pages/Error/Kba'));
const ErrorKbaBigDataCorp = lazy(
  () => import('@admission/pages/Error/KbaBigDataCorp')
);
const ErrorMinor = lazy(() => import('@admission/pages/Error/Minor'));
const ErrorNoData = lazy(() => import('@admission/pages/Error/NoData'));
const PublicPerson = lazy(() => import('@admission/pages/Error/PublicPerson'));
const RecoveryPassword = lazy(
  () => import('@admission/pages/RecoveryPassword')
);
const SignIn = lazy(() => import('@admission/pages/Signin'));
const Signup = lazy(() => import('@admission/pages/Signup'));
const ImplicitSignIn = lazy(() => import('@admission/pages/ImplicitSignin'));
const AccountCanceled = lazy(() => import('@admission/pages/AccountCanceled'));
const RedefinePassword = lazy(
  () => import('@admission/pages/RedefinePassword')
);
const RedefinePasswordCP = lazy(
  () => import('@admission/pages/RedefinePasswordCP')
);
const RedefinePasswordLink = lazy(
  () => import('@admission/pages/RedefinePasswordLink')
);

//#region ACP
const ACPSignIn = lazy(() => import('@admission/pages/ACP/SignIn'));
const ACPSignUp = lazy(() => import('@admission/pages/ACP/SignUp'));
const ACPInvalidSignUpKBA = lazy(() => import('@libs/acp/pages/Error/InvalidSignUpKBA'));
const ACPBlockedSignUpKBA = lazy(() => import('@libs/acp/pages/Error/BlockedSignUpKBA'));
//#endregion ACP

export function Router() {
  const location = useLocation();
	const { search } = location;
	const searchParams = new URLSearchParams(search);
  const isImplicitSignUp = searchParams.get("a");
  const isEngageSignIn = searchParams.get("e");

	const { resultIsWithACPSignUp } = useACPSignUpExperiment();
	const { resultIsWithACPSignIn } = useACPSignInExperiment();

	const shouldUseACPSignUp = resultIsWithACPSignUp && !isImplicitSignUp;
	const shouldUseACPSignIn = resultIsWithACPSignIn && !isImplicitSignUp && !isEngageSignIn;

  return (
    <>
      <Route
        path={pageNames.home.path}
        element={
          <Navigate to={`${pageNames.signin.path}${location?.search}`} />
        }
      />

      <Route path={pageNames.signin.path} element={shouldUseACPSignIn ? <ACPSignIn /> : <SignIn />} />

      <Route
        path={pageNames.inplicitSignin.path}
        element={<ImplicitSignIn />}
      />

			{/* ACP START */}
      <Route path={pageNames.signup.path} element={
				shouldUseACPSignUp ? <ACPSignUp /> : <Signup />} />
      <Route path={pageNames.signupACPErrorKBA.path} element={<ACPInvalidSignUpKBA />} />
      <Route path={pageNames.signupACPBlockedKBA.path} element={<ACPBlockedSignUpKBA />} />
			{/* ACP END */}
			

      <Route
        path={pageNames.recoveryPassword.path}
        element={<RecoveryPassword />}
      />
      <Route
        path={pageNames.redefinePassword.path}
        element={<RedefinePassword />}
      />
      <Route
        path={pageNames.redefinePasswordCP.path}
        element={<RedefinePasswordCP />}
      />
      <Route
        path={pageNames.redefinePasswordLink.path}
        element={<RedefinePasswordLink />}
      />
      <Route
        path={pageNames.accountCanceled.path}
        element={<AccountCanceled />}
      ></Route>
      <Route path={pageNames.errorNoData.path} element={<ErrorNoData />} />
      <Route path={pageNames.errorMinor.path} element={<ErrorMinor />} />
      <Route
        path={pageNames.errorPublicPerson.path}
        element={<PublicPerson />}
      />
      <Route path={pageNames.errorKba.path} element={<ErrorKba />} />
      <Route
        path={pageNames.errorKbaBigDataCorp.path}
        element={<ErrorKbaBigDataCorp />}
      />
    </>
  );
}
