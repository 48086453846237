import {
  addSessionHeaders,
  addXAuthTokenHeaders,
} from '@common/apis/customAddHeaders';
import customStorageAdapter from '@common/apis/customStorageAdpter';
import { createAlova } from 'alova';
import GlobalFetch from 'alova/GlobalFetch';
import ReactHook from 'alova/react';

export const alovaInstanceOnboarding = createAlova({
  statesHook: ReactHook,
  requestAdapter: GlobalFetch(),
  baseURL: import.meta.env.VITE_AC_API || "https://ac-marketplace-api.acordocerto.de",
  storageAdapter: customStorageAdapter,
  responded: {
    onSuccess: async (response) => {
      const json = await response.json();
      if (response.status >= 400) {
        throw json;
      }

      return json;
    },
  },
  async beforeRequest(method) {
    await addXAuthTokenHeaders(method);
    await addSessionHeaders(method);
  },
});
