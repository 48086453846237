import { useEffect } from 'react';
import { Conditional } from '@consumidor-positivo/aurora';
import { Outlet, useLocation } from 'react-router-dom';

import useUserAuthentication from '@admission/hooks/useUserAuthentication';
import useApiLoginEnrichment from '@common/apis/useAPILoginEnrichment';
import PageLoader from '@common/components/PageLoader';
import constants from '@common/constants';
import { DebtsProvider } from '@common/contexts/DebtsContext/DebtsProvider';
import useAuth from '@common/hooks/useAuth';
import { useUserStore } from '@common/store/useUserStore';
import useGTM from '@common/hooks/useGTM';
import usePushNotification from '@common/hooks/usePushNotification';
import PrivatePageTemplate from '@common/templates/PrivatePage';

import { useRedline } from '@libs/redline';
import { getCookie } from '@libs/utils/helpers/getSafeCookies';
import { base64Decrypt } from '@libs/utils/helpers/base64';
import { JaiminhoClient } from '@libs/jaiminho';

import '../styles.scss';

import { RenegProvider } from '@common/contexts/RenegContext/RenegProvider';
import useEngageSignIn from '@common/hooks/useSignIn/useEngageSignIn';

export default function PrivateRoutes() {
	const { user } = useUserStore();

  const {
		addCookieRLTracksAuth,
		setRLTracksAuthCookie
	} = useUserAuthentication();

	const { engageSignIn } = useEngageSignIn();

  const { apiLoginEnrichment } = useApiLoginEnrichment();

  const { isAuthenticated } = useAuth();

  const { track: trackPushNotification } = usePushNotification();

  const { track: trackRedline } = useRedline();

	const location = useLocation();
	const { search } = location;
	const searchParams = new URLSearchParams(search);

	const engageSigninData = searchParams.get("e");

  const GTM = useGTM();

	async function callEngageSignIn() {
		await engageSignIn();
	}

	useEffect(() => {
		const hasTokenAuth = getCookie(constants.legacyAuth.TOKEN);
		if(!hasTokenAuth && engageSigninData && location.pathname === "/minhas-dividas") {
			callEngageSignIn();
		}
	}, [engageSigninData]);

  useEffect(() => {
    if (isAuthenticated) {
      trackPushNotification(user);
      GTM.trigger.loginWithSuccess();

      const authCookie = getCookie(constants.redline.rl_tracks_auth, false);
      if (authCookie) {
        const rLTracksAuthData = JSON.parse(base64Decrypt(authCookie));

        apiLoginEnrichment.send();

        trackRedline.userAuthentication.userAuthenticated({
          customerId: user.documento,
          correlationId: rLTracksAuthData.correlationId,
          authenticationType: rLTracksAuthData.authenticationType,
        });

        addCookieRLTracksAuth('cached-sign-in');

      } else {
        setRLTracksAuthCookie();
      }

      trackRedline.userAuthentication.userIdentified({
        document: user.documento,
      });
    }
  }, [isAuthenticated]);

  if (!isAuthenticated) {
    return <PageLoader />;
  }
  
  return (
    <Conditional
      condition={!!isAuthenticated}
      renderIf={
        <RenegProvider>
          <DebtsProvider>
            <PrivatePageTemplate
              firstName={user.primeiroNome}
              fullName={user.nome}
            >
              <Outlet />
              <JaiminhoClient
                apiKey={import.meta.env.VITE_PUSH_WEB_API_KEY}
                marketplaceApi={`${
                  import.meta.env.VITE_AC_API
                }/marketplace/v1/app/engage/create-entity`}
                urgencyInstall={false}
                applicationServerKey={import.meta.env.VITE_PUSH_WEB_SERVER_KEY}
                options={{
                  pushOnTimeout: 15000,
                  promptTitle: '',
                  promptMessage:
                    'Olá, você gostaria de ser avisado sobre novas ofertas?',
                  promptButton: 'Sim',
                  promptDismiss: 'Não',
                  bell: false,
                  button: false,
                  prompt: true,
                }}
              />
            </PrivatePageTemplate>
          </DebtsProvider>
        </RenegProvider>
      }
    />
  );
}
