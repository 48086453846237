// @ts-nocheck
import {
  getSessionIDAsync,
  getAnonymousIDAsync,
  getCrossSiteIDAsync,
  getInstanceIDAsync,
} from 'redline-client-sdk';

export const appsFlyerBridge = () => {
  const logEvent = async (event: string, data: Record<string, unknown>) => {
    if (!window?.isNativeApp || !window?.NativeFeatures?.AppsFlyer) return;

    const eventName = event.replace(/\./g, '_');
    const formatNestedData = (data: any): any => {
      if (Array.isArray(data)) {
        // Se for um array, percorre os elementos e aplica a formatação a cada um deles
        return data.map((item) => formatNestedData(item));
      } else if (typeof data === 'object' && data !== null) {
        // Se for um objeto, percorre as chaves e aplica a formatação recursivamente
        return Object.keys(data).reduce((acc: any, curr: any) => {
          const newKey = curr.replaceAll('.', '_'); // Substitui pontos por underscores

          if (typeof data[curr] === 'object' && data[curr] !== null) {
            // Recursão para objetos e arrays
            acc[newKey] = formatNestedData(data[curr]);
          } else if (!['string', 'number'].includes(typeof data[curr])) {
            // Se não for string ou número, atribui uma string vazia
            acc[newKey] = '';
          } else {
            // Caso contrário, mantém o valor original
            acc[newKey] = data[curr];
          }

          return acc;
        }, {});
      } else {
        // Se for um valor primitivo (string, número, etc.), retorna diretamente
        return data;
      }
    };

    const eventData = {
      identifiers: {
        sessionId: await getSessionIDAsync(),
        anonymousId: await getAnonymousIDAsync(),
        crossSiteId: await getCrossSiteIDAsync(),
        instanceId: await getInstanceIDAsync(),
      },
      ...formatNestedData(data),
    };

    const payload = {
      action: 'sendAppsFlyerEvent',
      payload: { eventName, data: eventData },
    };

    const stingfiedPayload = JSON.stringify(payload);
    window.ReactNativeWebView.postMessage(stingfiedPayload);
  };

  return { logEvent };
};
