import { useEffect, useRef } from 'react';

type UseOfferCardProps = {
  onViewOffer?: (
    entries: IntersectionObserverEntry[],
    observer: IntersectionObserver
  ) => void;
};

export function useOfferCard({ onViewOffer }: UseOfferCardProps) {
  const cardRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      async (entries, observer) => {
        if (entries[0].isIntersecting) {
          onViewOffer && onViewOffer(entries, observer);
          observer.unobserve(entries[0].target);
        }
      },
      {
        threshold: 0.5,
      }
    );

    if (cardRef.current) observer.observe(cardRef.current);
    return () => {
      if (cardRef.current) observer.unobserve(cardRef.current);
    };
  }, [cardRef]);

  return {
    cardRef,
  };
}
