import { AxiosResponse } from 'axios';
import {
  DecisionParams,
  SHDecideResponseProps,
  SHOffer,
  SHReqProps,
} from '../services/sortingHat/types';

import { getCookie } from '../helpers/getCookies';

const hasSHDebug = getCookie('DEBUG_SH');

//#region SH

type DebugSHSingleCallPerPathParams = {
  shouldForceCall: boolean;
  paths: string[];
  debugFor: 'TRACK' | 'DECIDE';
};
export function debugSHSingleCallPerPath({
  paths,
  shouldForceCall,
  debugFor,
}: DebugSHSingleCallPerPathParams) {
  if (!hasSHDebug) return;

  console.groupCollapsed(`[SH - SINGLE CALL PER PATH - ${debugFor}]`);
  console.log('[SHOULD_FORCE_CALL]: ', shouldForceCall);
  console.log('[CURRENT_PATH]: ', window?.location.href);
  console.log('[ALREADY_TRIGGER_PATHS]: ', paths);
  console.log(
    '[PATHS_INCLUDES_CURRENT]: ',
    paths.includes(window.location.href)
  );
  console.groupEnd();
}

type DebugSHCacheParams = {
  decideCount: number;
  numberOfCalls: number;
  offersFromCache: SHOffer[];
};
export function debugSHCache({
  decideCount,
  numberOfCalls,
  offersFromCache,
}: DebugSHCacheParams) {
  if (!hasSHDebug) return;

  console.groupCollapsed('[SH - DECISION CACHE]');
  console.log('[DECIDE_COUNT]: ', decideCount);
  console.log('[DECIDE_MAX-CALLS]: ', numberOfCalls);
  console.log('[CACHE_OFFERS]: ', offersFromCache);
  console.log('[CACHE_OFFERS_COUNT]: ', offersFromCache?.length);
  console.groupEnd();
}

type DebugSHDecideDetailsParams = {
  params: DecisionParams;
  offers: SHOffer[];
  offersFromCache: SHOffer[];
  response?: AxiosResponse<SHDecideResponseProps>;
};
export function debugSHDecideDetails({
  params,
  offers,
  offersFromCache,
  response,
}: DebugSHDecideDetailsParams) {
  if (!hasSHDebug) return;
  console.groupCollapsed('[SH - DECISION REQ-RES]');
  console.log('PARAMS.forceCall]: ', params.forceCall);
  console.log('PARAMS.ignoreToken]: ', params.ignoreToken);
  console.log('PARAMS.token]: ', params.token);
  console.log('PARAMS.delayTime]: ', params.delayTime);
  console.log('[API_RESPONSE]: ', response);
  console.log('[RESPONSE_COUNT]: ', response?.data?.result?.decisions?.length);
  console.log('[OFFERS_COUNT]: ', offers?.length);
  console.log('[CACHE_OFFERS]:', offersFromCache);
  console.log('[FORMATTED_OFFERS]: ', offers);
  console.groupEnd();
}

type DebugSHPayloadParams = {
  user: any;
  customIdentifier?: string;
  payloadFor: 'track' | 'decide';
  payload: Partial<SHReqProps>;
};
export function debugSHPayload({
  payloadFor,
  user,
  customIdentifier,
  payload,
}: DebugSHPayloadParams) {
  if (!hasSHDebug) return;

  console.groupCollapsed(
    `[SH - PAYLOAD FOR - ${payloadFor.toLocaleUpperCase()}]`
  );
  console.log('[PARAMS.user]: ', user);
  console.log('[PARAMS.customIdentifier]: ', customIdentifier);
  console.log('[PAYLOAD]: ', payload);
  console.groupEnd();
}

type DebugSHTimerParams = {
  status: 'start' | 'end';
  timerFor: string;
};
export function debugSHTimer({ status, timerFor }: DebugSHTimerParams) {
  if (!hasSHDebug) return;
  if (status === 'end') {
    return console.timeEnd(timerFor);
  }

  console.time(timerFor);
}

//#endregion
