const useLuizacredLogo = (
  identifier: string,
  partnerIdentifier: string,
  source: string | null,
  product: string | null
) => {
  const isItau = partnerIdentifier === 'itau' || source?.includes('Itaú');
  const productIsLuizaCred =
    product?.includes('LUIZACRED') || product?.includes('LUIZACRED');

  const showLogoMagalu = isItau && productIsLuizaCred;

  const currentLogoIdentifier = showLogoMagalu ? 'luizacred' : identifier;
  return {
    showLogoMagalu,
    currentLogoIdentifier,
  };
};

export default useLuizacredLogo;
