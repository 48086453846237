import { Conditional } from '@consumidor-positivo/aurora';
import { OfferSkeleton } from '@libs/creditkit';
import { useGetOffers } from '@hub/hooks/useGetOffers';

import { MainOfferCard } from '../MainOfferCard';
import { FeaturedOfferTemplate as Template } from './Template';
import { FeaturedOffersCarousel as Carousel } from './components/Carousel';
import { OfferFeaturedProps } from './types';

import './styles.scss';

export const OfferFeatured = ({
  location,
  forceDecide,
  onClickOffersCTA,
  blockTitle,
}: OfferFeaturedProps) => {
  const { redirectToOffers, shLoading, noOffer, decideDone, featuredOffers } =
    useGetOffers({ callSHDecide: forceDecide });

  const title = blockTitle
    ? blockTitle
    : 'Você tem ofertas liberadas te esperando';

  return (
    <section>
      <Template
        title={title}
        noOffer={noOffer}
        onClickMoreOffersBtn={() => {
          redirectToOffers();
          onClickOffersCTA && onClickOffersCTA();
        }}
      >
        <Conditional
          condition={shLoading || !decideDone}
          renderIf={
            <div className="offer-featured__skeleton">
              <OfferSkeleton />
            </div>
          }
          renderElse={
            <Carousel>
              {featuredOffers?.slice(0, 2).map((offer, index) => (
                <div
                  className="offer-featured__card-container"
                  key={`offer-filtered-card__${offer?.content?.slug}-${index}`}
                >
                  <MainOfferCard
                    index={index}
                    location={location}
                    offer={offer}
                  />
                </div>
              ))}
            </Carousel>
          }
        />
      </Template>
    </section>
  );
};
