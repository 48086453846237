import { useRequest } from 'alova';
import acInterceptor from '../acInterceptor';
import { getCookie } from '@libs/utils/helpers/getSafeCookies';
import constants from '@common/constants';

const baseURL = import.meta.env.VITE_AC_API;
const headers = {
  'Content-Type': 'application/json;',
};

const useApiAdmin = () => {
  const token = getCookie(constants.legacyAuth.A_TOKEN);
  const apiAdmin = useRequest(
    (document) =>
      acInterceptor({ baseURL, addXAuthToken: false, headersBeforeRequest: { 'X-Auth-Token': token } }).Post<any>(
        '/marketplace/v1/admin/auth/token',
        {
          customerId: document,
        },
        {
          headers,
        }
      ),
    {
      immediate: false,
    }
  );

  const apiCurrentCustomer = useRequest(
    (token) =>
      acInterceptor({
        baseURL,
        addXAuthToken: false,
        headersBeforeRequest: { 'X-Auth-Token': token },
      }).Get<any>('/marketplace/v1/app/customer', {
        headers,
      }),
    {
      immediate: false,
    }
  );

  return {
    apiAdmin,
    apiCurrentCustomer,
  };
};

export default useApiAdmin;
