import { getSessionID } from 'redline-client-sdk';

function encodeBase64(value: string) {
  return btoa(value);
}

function decodeBase64(value: string) {
  return atob(value);
}

function getCookie(key: string) {
  const cookies = document?.cookie?.split(';');
  for (let i = 0; i < cookies?.length; i++) {
    const cookie = cookies?.[i]?.trim();
    if (cookie?.startsWith(key + '=')) {
      return cookie?.substring(key?.length + 1);
    }
  }
  return null;
}

function setCookie(key: string, value: string, exp: any) {
  const expires = 'expires=' + exp.toUTCString();
  const cookieValue = `${key}=${value}; ${expires}; path=/`;
  document.cookie = cookieValue;
  return cookieValue;
}

function removeCookie(key: string) {
  document.cookie =
    key + '=; expires=' + new Date(0).toUTCString() + '; path=/;';
}

function getEventControlDataFromCookies(ctrlCookieKey: string) {
  try {
    const storedEventData = getCookie(ctrlCookieKey);
    if (storedEventData) {
      return JSON.parse(decodeBase64(storedEventData));
    } else {
      return {};
    }
  } catch (error) {
    console.error('Error parsing stored event control data:', error);
    return {};
  }
}

/**
 * Controls the sending of events based on a unique identifier and triggers a callback if the event is not already sent.
 *
 * @param {string} ctrlCookieKey - The key of the control cookie.
 * @param {string} eventIdentifier - A unique identifier for the event.
 * @param {Function} [eventCallback] - A callback function to be triggered when the event is not already sent.
 * @returns {void}
 * @example
 * controlEventSending("rl_ctrl_events-example", "eventName|id", () => {
 *    rl.track(event, { ...data });
 * });
 */
export function controlEventSending(
  ctrlCookieKey: string,
  eventIdentifier: string,
  eventCallback: () => void
) {
  const sessionId = getSessionID();
  const eventControlData = getEventControlDataFromCookies(ctrlCookieKey);

  if (!eventControlData?.[sessionId]?.[eventIdentifier]) {
    const newEvent = { [eventIdentifier]: 1 };

    const updatedEventControlData = {
      [sessionId]: {
        ...eventControlData?.[sessionId],
        ...newEvent,
      },
    };

    const currentTime = new Date();
    const cookieExpTime = new Date(currentTime.getTime() + 30 * 60000); // Adding 30 minutes in milliseconds

    setCookie(
      ctrlCookieKey,
      encodeBase64(JSON.stringify(updatedEventControlData)),
      cookieExpTime
    );

    return eventCallback?.();
  }
}

/**
 * Removes the control cookie when an error occurs during event handling.
 * @param {string} ctrlCookieKey - The key of the control cookie.
 * @param {string} eventName - The name of the event.
 * @param {any} err - The error that occurred.
 * @example
 * controlEventSending("rl_ctrl_events-example", "eventName|id", () => {
 *   rl.track(event, { ...data }).catch((err: Error) => {
 *     removeCtrlCookieOnEventError("rl_ctrl_events-example", "eventName|id", err)
 *   });
 * });
 */
export function removeCtrlCookieOnEventError(
  ctrlCookieKey: string,
  eventName: string,
  err: Error
) {
  console.error(err);
  removeCookie(ctrlCookieKey);
}
