const constantsLegacyAuth = {
  TOKEN: 'TOKEN',
  SUBJECT: 'SUBJECT',
  A_TOKEN: 'A_TOKEN',
  IMPLICIT_LOGIN: 'IMPLICIT_LOGIN',
  X_AUTH_TOKEN: 'X-Auth-Token',
  X_USER_CONTEXT: 'X-User-Context',
}

export default constantsLegacyAuth
