/**
 * Checks if the payment is made through a partner.
 *
 * @param {Object} params - Parameters of the function.
 * @param {Object} params.agreement - Agreement containing payment information.
 * @param {Object} params.currentInstallment - Details of the current installment.
 * @param {boolean} params.isDebitAccount - Indicates if the payment is from a debit account.
 * @returns {Object} - An object indicating if the payment is through a partner, along with title and message.
 */
const checkPartnerPayment = ({ agreement, currentInstallment, isDebitAccount }: any) => {
  const isFirstInstallment = currentInstallment?.installmentNumber === 1;
  const isAgreementCancelled = agreement?.situation === 'CANCELLED';
  const currentPartner = agreement?.partner?.identificador;
  const currentPartnerName = agreement?.partner?.nome;

  let isPaymentInPartner = false;
  let title = "";
  let message = "";

  if (isDebitAccount) {
    isPaymentInPartner = true;
    title = "Débito automático em breve";
    message = "O valor da parcela deve estar na conta cadastrada, antes do vencimento.";
  }

  const onInvoicePartners = ['nubank'];  // Partners that use invoice payments
  if (!isAgreementCancelled && !isFirstInstallment && onInvoicePartners.includes(currentPartner)) {
    isPaymentInPartner = true;
    title = "Pagamento na fatura";
    message = "Essa e as demais parcelas estarão disponíveis no aplicativo do seu banco.";
  }

  const companyPartners = ['riachuelo', 'credystem'];  // Partners that are companies
  if (!isAgreementCancelled && !isFirstInstallment && companyPartners.includes(currentPartner)) {
    isPaymentInPartner = true;
    title = "Pagamento com a empresa";
    message = `Essa e as demais parcelas de pagamento estarão disponíveis com a ${currentPartnerName}.`;
  }

  return {
    isPaymentInPartner,
    title,
    message
  };
};


export default checkPartnerPayment;
