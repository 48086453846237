type SBDatasource = {
  id: number;
  name: string;
  value: string;
};

export async function getSBDatasource(slug: string) {
  try {
    const token = 'mMivpQJY0SZRRQynhEs69Qtt';
    const apiUrl = `https://api.storyblok.com/v2/cdn/datasource_entries?datasource=${slug}&token=${token}`;
    const response = await fetch(apiUrl);
    const data = await response.json();
    return data?.datasource_entries as SBDatasource[];
  } catch (error) {
    console.error('[LIBS][SB][DATA_SOURCE]', error);
    return null;
  }
}
