import axios from 'axios';
import { formatFireboltPayload } from './formatPayload';
import { FireboltPayload } from './types';

export async function sendFireboltSoftProposal(payload: FireboltPayload) {
  const baseURL = import.meta.env['VITE_FIREBOLT_API_URL'];

  try {
    const url = `${baseURL}/proposal/soft/${payload.partner}`;
    const payloadFormatted = formatFireboltPayload(payload);
    const options = {
      headers: {
        'Content-Type': 'application/json;',
      },
    };

    const response = await axios.post(url, payloadFormatted, options);
    return response.data;
  } catch (error) {
    console.error('[ERROR ON FIREBOLT SOFT]', error);
  }
}
