const untilLoad = (
  action: () => any,
  timeoutTime?: number,
  intervalTime = 100
): Promise<any> => {
  return new Promise((res) => {
    let value = action();
    if (value) res(value);
    const interval = setInterval(() => {
      value = action();
      if (value) {
        clearInterval(interval);
        res(value);
      }
    }, intervalTime);

    if (timeoutTime) {
      const timeout = setTimeout(() => {
        clearInterval(interval);
        res(value);
        clearTimeout(timeout);
      }, timeoutTime);
    }
  });
};


export default untilLoad
