import { Card, IconGift, COLOR_INFO_50 } from '@consumidor-positivo/aurora';

type TagProps = {
  text?: string;
  type?: TypeTag;
};

type TypesTag = Record<
  TypeTag,
  {
    text: string;
    color: 'primary' | 'secondary';
    icon: string | JSX.Element;
  }
>;

type TypeTag = 'approved' | 'default';

export const Tag = ({
  text = '',
  type = 'default',
}: TagProps) => {
  const types: TypesTag = {
    approved: {
      text: 'Chance alta de aprovação',
      color: 'primary',
      icon: '🔥',
    },
    default: {
      text: text,
      color: 'secondary',
      icon: <IconGift rawColor={COLOR_INFO_50} size="small" />,
    },
  };

  return (
    <Card.Tag color={types[type].color} icon={types[type].icon}>
      {types[type].text}
    </Card.Tag>
  );
};
