import { getCookie } from './utils';

export enum AdFormat {
  RESPONSIVE = 'responsive',
  LEADERBOARD = 'leaderboard',
  ANCHOR = 'anchor',
  SKYSCRAPER = 'skyscraper',
  ARROBA = 'arroba',
  INTERCONTENT = 'intercontent',
  INTERLIST = 'interlist',
}

export enum OutOfPageTypes {
  REWARDED = 4,
  TOP_ANCHOR = 2,
  BOTTOM_ANCHOR = 3,
  INTERSTITIAL = 5,
  GAME_MANUAL_INTERSTITIAL = 7,
  LEFT_SIDE_RAIL = 8,
  RIGHT_SIDE_RAIL = 9,
}

const lazyLoadExperiment = getCookie('dx-ads-lazy-load');
const isNewConfig = Boolean(lazyLoadExperiment?.includes('new-config'));

export const LAZY_LOAD_SETTINGS = {
  fetchMarginPercent: isNewConfig ? 500 : 4,
  renderMarginPercent: isNewConfig ? 200 : 300,
  mobileScaling: 2.0,
};

// Size mapping for different ad formats
export const AD_SIZE_MAPPINGS: Record<AdFormat, googletag.SizeMappingArray> = {
  [AdFormat.RESPONSIVE]: [
    [
      [992, 0],
      [
        [970, 90],
        [750, 200],
        [728, 90],
      ],
    ],
    [
      [768, 0],
      [
        [336, 280],
        [300, 250],
        [250, 250],
      ],
    ],
    [
      [576, 0],
      [
        [336, 280],
        [300, 250],
        [250, 250],
      ],
    ],
    [
      [0, 0],
      [
        [336, 280],
        [300, 250],
        [250, 250],
      ],
    ],
  ],
  [AdFormat.LEADERBOARD]: [
    [[992, 0], [[0, 0]]], // Desktop - not displayed
    [[0, 0], [[320, 50]]], // Mobile
  ],
  [AdFormat.ANCHOR]: [
    [
      [992, 0],
      [
        [728, 90],
        [970, 90],
      ],
    ], // Desktop
    [[0, 0], [[320, 50]]], // Mobile
  ],
  [AdFormat.SKYSCRAPER]: [
    [
      [992, 0],
      [
        [120, 600],
        [160, 600],
        [300, 600],
        [336, 280],
        [300, 250],
      ],
    ], // Desktop
    [
      [0, 0],
      [
        [300, 250],
        [336, 280],
        [320, 50],
        [320, 100],
      ],
    ], // Mobile
  ],
  [AdFormat.ARROBA]: [
    [
      [992, 0],
      [
        [336, 280],
        [300, 250],
      ],
    ], // Desktop
    [
      [0, 0],
      [
        [300, 250],
        [336, 280],
        [320, 50],
        [320, 100],
      ],
    ], // Mobile
  ],
  [AdFormat.INTERCONTENT]: [
    [
      [992, 0],
      [
        [728, 90],
        [970, 90],
      ],
    ], // Desktop
    [
      [0, 0],
      [
        [300, 250],
        [336, 280],
        [320, 50],
        [320, 100],
      ],
    ], // Mobile
  ],
  [AdFormat.INTERLIST]: [
    [[992, 0], [[0, 0]]], // Desktop - assuming not displayed
    [[0, 0], [[320, 100]]], // Mobile
  ],
};
