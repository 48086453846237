import { addXAuthTokenHeaders } from '@common/apis/customAddHeaders';
import customStorageAdapter from '@common/apis/customStorageAdpter';
import { createAlova } from 'alova';
import GlobalFetch from 'alova/GlobalFetch';
import ReactHook from 'alova/react';

export const alovaInstanceLegacyOnboarding = createAlova({
  statesHook: ReactHook,
  requestAdapter: GlobalFetch(),
  baseURL: import.meta.env.VITE_AC_API_LEGACY,
  storageAdapter: customStorageAdapter,
  responded: {
    onSuccess: async (response) => {
      const stringifyResponse = JSON.stringify(response);
      const json = await JSON.parse(stringifyResponse);
      if (response.status >= 400) {
        throw json;
      }

      return json;
    },
  },
  async beforeRequest(method) {
    await addXAuthTokenHeaders(method);
  },
});
