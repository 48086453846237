import constants from '@common/constants';
import { pageNames, publicPagePaths } from '@common/routes/pageNames';
import { useUserStore } from '@common/store/useUserStore';
import { base64Decrypt, legacyBase64Decrypt } from '@libs/utils/helpers/base64';
import {
  addCookie,
  getCookie,
  removeCookie,
} from '@libs/utils/helpers/getSafeCookies';
import safestorage from '@libs/utils/helpers/getSafeStorage';
import { parseJwt } from '@libs/utils/helpers/parseJwt';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// import { useRedline } from '@libs/redline';
import { getParamsUrl } from '@libs/utils/helpers/getParamsUrl';
import { useSortingHatLib } from '../useSortingHatLib';

export default function useAuth() {
  const [isAuthenticated, setAuthenticated] = useState<boolean>(false);

	const [addAuthStore, addUserStore, userStore, authStore, resetStore] =
    useUserStore((state) => [
      state.addAuth,
      state.addUser,
      state.user,
      state.auth,
      state.resetStore,
    ]);
  // const { endSession } = useRedline();
  const navigate = useNavigate();
	const location = useLocation();
  const { resetSHContext } = useSortingHatLib();

	const { search } = location;
	const searchParams = new URLSearchParams(search);

	const engageSigninData = searchParams.get("e");
  function isExpiredToken(timestamp: number) {
    const currentTimestamp = Math.floor(Date.now() / 1000);
    return timestamp < currentTimestamp;
  }

  async function validToken() {
    // TODO: remove later, is only to migration support legacy project
    const legacyAuthToken = getCookie(constants.legacyAuth.TOKEN);
    const legacySubject = getCookie(constants.legacyAuth.SUBJECT);
    // END TODO
    const authUserToken = await safestorage.getItem<any>(
      constants.auth.AUTH_USER_TOKEN
    );

    if (!legacyAuthToken && authUserToken) {
      addLegacyUser({
        data: {
          user: userStore,
          auth: {
            access_token: authStore.token,
          },
        },
      });
    }

    // TODO: remove later, is only to migration support legacy project
    if (legacySubject && !authUserToken) {
      const decodeSubject = window.atob(String(legacySubject));
      const parseSubject = JSON.parse(decodeSubject);
      addUserStore(parseSubject);
    }

    if (legacyAuthToken) {
      const { exp } = parseJwt(legacyAuthToken);

      return !isExpiredToken(exp);
    }
    // END TODO

    if (authUserToken) {
      const { exp } = parseJwt(authUserToken.access_token);

      return !isExpiredToken(exp);
    }

    return false;
  }

  function addAuthUserLocalStorage(res: any) {
    safestorage.setItem(constants.auth.AUTH_USER_TOKEN, res.data.auth);
    safestorage.setItem(constants.auth.AUTH_USER_DATA, res.data.user);

    addAuthStore({
      isAuthenticated: !!res.data.auth?.access_token,
      token: res.data.auth?.access_token,
      exp: parseJwt(res.data.auth?.access_token).exp,
    });

		addLegacyUser(res); // TODO: remove later, is only to migration support legacy project
	}

  function updateAuthUserLocalStorage(res: any) {
    addLegacyUser(res); // TODO: remove later, is only to migration support legacy project
  }

  function addLegacyUser(res: any) {
    const expireDays = new Date();
    expireDays.setDate(expireDays.getDate() + 120);
    addCookie({
      key: constants.legacyAuth.TOKEN,
      value: res.data.auth.access_token,
      expires: expireDays,
    });
    addCookie({
      key: constants.legacyAuth.SUBJECT,
      value: res.data.user,
      expires: expireDays,
      encrypt: true,
    });
  }

  function logOut() {

    resetSHContext();

    // TODO: remove later is only to migration support legacy project
    removeCookie(constants.legacyAuth.TOKEN);
    removeCookie(constants.legacyAuth.SUBJECT);
    removeCookie(constants.legacyAuth.A_TOKEN);
    removeCookie(constants.legacyAuth.X_AUTH_TOKEN);
    removeCookie(constants.legacyAuth.X_USER_CONTEXT);
    removeCookie(constants.loans.canAccessNoverdeIntegratedFlow);
    removeCookie(constants.loans.canAccessSimplicIntegratedFlow);
    removeCookie(constants.loans.canAccessSupersimIntegratedFlow);
    removeCookie(constants.auth.KEEP_SESSION);

		removeCookie('ATENDE_TOKEN');
		removeCookie('ATENDE_SIGNUP');
    // endSession();
    localStorage.removeItem(constants.debts.SORTING_DEBTS_RES);

    const preserveKeys = ['ss_incoming_params', 'ss_webReferrer'] as const;
    const preserved = preserveKeys.reduce<Record<string, string | null>>(
      (acc, key) => {
        acc[key] = localStorage.getItem(key);
        return acc;
      },
      {}
    );

    localStorage.clear();
    safestorage.clear().then(() => {
      setTimeout(() => {
        Object.entries(preserved).forEach(([key, value]) => {
          if (value !== null) localStorage.setItem(key, value);
        });
      }, 100);
    });

  }

  function handleAuthenticatedFast() {
    const legacyAuthToken = getCookie(constants.legacyAuth.TOKEN);

		if (!legacyAuthToken) {
      handleNotAuthenticated();
      return;
    }

    const { exp } = parseJwt(legacyAuthToken || '');
    const isValidToken = !isExpiredToken(exp);

		if (!isValidToken) {
      handleNotAuthenticated();
      return;
    }

    const legacySubject = getCookie(constants.legacyAuth.SUBJECT);

		if (!legacySubject) {
      handleNotAuthenticated();
      return;
    }

    const decodeSubject = legacyBase64Decrypt(legacySubject);
    const storagedUser = JSON.parse(decodeSubject);

    const isLoginPath =  window.location.pathname.includes(pageNames.signin.path);
    const urlParams = getParamsUrl(window.location.search);
    const hash = urlParams['c'] || '';
    const decodedDocument = decodeURIComponent(hash);
    const documentFromHash = base64Decrypt(decodedDocument);

    if (!isLoginPath || (isLoginPath && !documentFromHash)) {
      addUserStore(storagedUser);
      setAuthenticated(true);
    }

    if (isLoginPath && documentFromHash) {
      if (documentFromHash === storagedUser.documento) {
        addUserStore(storagedUser);
        setAuthenticated(true);
      }

      if (documentFromHash !== storagedUser.documento) {
        handleNotAuthenticated();
      }
    }
  }

  function handleNotAuthenticated() {
		if(!engageSigninData) {
			setAuthenticated(false);
			logOut();
			redirectToSignin();
		}
  }

  function redirectToSignin() {
    const { pathname, search } = window.location;
    const isPublicPages = publicPagePaths.includes(pathname);

    if (!isPublicPages) {
      navigate(`${pageNames.signin.path}${search}`);
    }
  }

  useEffect(() => {
    if (isAuthenticated) {
      removeCookie(constants.auth.KEEP_SESSION);
    }
  }, [isAuthenticated]);

  useEffect(() => {
		handleAuthenticatedFast();
  }, []);

	useEffect(() => {
		if(authStore.isAuthenticated && window.location.pathname === "/minhas-dividas") {
			setAuthenticated(authStore.isAuthenticated);
		}
	}, [authStore.isAuthenticated]);

  return {
    logOut,
    isAuthenticated,
    addAuthUserLocalStorage,
    updateAuthUserLocalStorage,
    validToken,
  };
}
