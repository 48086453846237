import { Card, Skeleton, Conditional } from '@consumidor-positivo/aurora';

type SkeletonCardOfferProps = {
  smallCard?: boolean;
};

const OfferSkeleton = ({ smallCard = false }: SkeletonCardOfferProps) => {
  return (
    <div>
      <Conditional
        condition={smallCard}
        renderIf={
          <Card.Root maxWidth={288} border={false}>
            <Card.Container gap={16}>
              <Skeleton height={29} block />
              <Card.Container direction="row" alignItems="center" gap={16}>
                <Card.Container width={80}>
                  <Skeleton width={80} height={64} />
                </Card.Container>
                <Card.Container gap={8}>
                  <Skeleton block height={22} />
                  <Skeleton block height={40} />
                </Card.Container>
              </Card.Container>
              <Card.Container direction="row" alignItems="center" gap={8}>
                <Skeleton height={48} block shape="circle" />
                <Skeleton height={48} block shape="circle" />
              </Card.Container>
            </Card.Container>
          </Card.Root>
        }
        renderElse={
          <Card.Root maxWidth={308}>
            <Card.Container gap={16} alignItems="center">
              <Skeleton height={29} block />
              <Skeleton height={72} width={88} />
              <Card.Container alignItems="center">
                <Skeleton width={194} />
              </Card.Container>
              <Skeleton shape="square" height={111} block />
              <Card.Container gap={8}>
                <Skeleton height={48} block shape="circle" />
                <Skeleton height={48} block shape="circle" />
              </Card.Container>
            </Card.Container>
          </Card.Root>
        }
      />
    </div>
  );
};

export default OfferSkeleton;
