import { useState } from 'react';
import StepNewInfo from './StepNewInfo';
import StepVerificationCode from './StepVerificationCode';
import { Drawer, Conditional } from '@consumidor-positivo/aurora';

import './styles.scss';
interface UpdateContactDataModalProps {
  isOpen: boolean;
  setIsModalOpen: (isOpen: boolean) => void;
  currFlow: 'celular' | 'e-mail';
  setShowWarning: (isOpen: boolean) => void;
}
const UpdateContactDataModal = ({
  isOpen,
  setIsModalOpen,
  currFlow,
  setShowWarning,
}: UpdateContactDataModalProps) => {
  const [currentStep, setCurrentStep] = useState<
    'newInfo' | 'verificationCode'
  >('newInfo');

  const [newData, setNewData] = useState<string>('');

  const step = {
    newInfo: (
      <StepNewInfo
        setCurrentStep={setCurrentStep}
        currFlow={currFlow}
        setNewData={setNewData}
        isOpen={isOpen}
      />
    ),
    verificationCode: (
      <StepVerificationCode
        currFlow={currFlow}
        setIsModalOpen={setIsModalOpen}
        setShowWarning={setShowWarning}
        setCurrentStep={setCurrentStep}
        newData={newData}
      />
    ),
  };

  return (
    <div className="update-contact-data-modal">
      <Drawer
        isOpen={isOpen}
        handleOpen={() => setIsModalOpen(false)}
        renderHeader={<></>}
        renderContent={
          <Conditional
            condition={!!step[currentStep]}
            renderIf={<>{step[currentStep]}</>}
          />
        }
      />
    </div>
  );
};

export default UpdateContactDataModal;
