type getDeliveryIdParamProps = {
    startSourceUrl: string
}

export const getDeliveryIdParam = ({startSourceUrl}: getDeliveryIdParamProps): string => {
    const empty = '(delivery_id)';

    if(!startSourceUrl) return empty;

    const urlParams = new URLSearchParams(new URL(startSourceUrl).search);

    const deliveryId = urlParams.get('delivery_id');

    return deliveryId || empty;
}