import useApiAdmin from '@common/apis/admin/useApiAdmin';
import constants from '@common/constants';
import { pageNames } from '@common/routes/pageNames';
import { useUserStore } from '@common/store/useUserStore';
import { Alert, Button, Conditional, IconSearch, InputField, Text } from '@consumidor-positivo/aurora';
import { addCookie, getCookie } from '@libs/utils/helpers/getSafeCookies';
import { maskCpf } from '@libs/utils/masks/cpf';
import { cpf } from 'cpf-cnpj-validator';
import { useLayoutEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './styles.scss';

const Admin = () => {
  const [cpfValue, setCpf] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState<boolean>(false);
  const { apiAdmin, apiCurrentCustomer } = useApiAdmin();
  const user = useUserStore((state) => state.user);
  const navigage = useNavigate();

  function checkIsAdmin() {
    if (user && !user.admin) {
      navigage(pageNames.myDebts.path);
    }

    const aToken = getCookie(constants.legacyAuth.A_TOKEN);
    if (user && user.admin && !aToken) {
      const token = getCookie(constants.legacyAuth.TOKEN);
      const expireDays = new Date();
      expireDays.setDate(expireDays.getDate() + 1);
      addCookie({
        key: constants.legacyAuth.A_TOKEN,
        value: token || '',
        expires: expireDays,
      });
    }
  }

  function onSetValue(e: React.ChangeEvent<HTMLInputElement>) {
    const pureValue = e.target.value;
    const maskValue = maskCpf(pureValue);
    setCpf(maskValue);
    if (pureValue.length >= 1) {
      setError('');
    }
  }

  async function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setLoading(true);
    const isValidCpf = cpf.isValid(cpfValue);

    if (!isValidCpf) {
      setError('CPF inválido');
      return;
    }

    apiAdmin
      .send(cpfValue)
      .then((res) => {
        apiCurrentCustomer
          .send(res.data.access_token)
          .then((userResponse) => {
            const userAdmin = {
              admin: true,
              celular: null,
              cutOffValue: userResponse['cutOffValue'],
              dataNascimento: userResponse['dataNascimento'],
              documento: userResponse['documento'],
              email: null,
              id: userResponse['id'],
              newsletter: userResponse['newsletter'],
              nome: userResponse['nome'],
              customerIdHash: userResponse['customerIdHash'],
              novoCadastro: false,
              primeiroNome: userResponse['primeiroNome'],
              registrado: true,
              senhaCadastrada: userResponse['senhaCadastrada'],
              sexo: userResponse['sexo'],
              telefoneValidado: userResponse['telefoneValidado'],
              termsOfUse: userResponse['termsOfUse'],
              termsOfUseVersion: userResponse['termsOfUseVersion'],
              verificado: userResponse['verificado'],
            };

            const expireDays = new Date();
            expireDays.setDate(expireDays.getDate() + 1);
            addCookie({
              key: constants.legacyAuth.TOKEN,
              value: res.data.access_token,
              expires: expireDays,
            });
            addCookie({
              key: constants.legacyAuth.SUBJECT,
              value: JSON.stringify(userAdmin),
              encrypt: true,
              expires: expireDays,
            });
            setLoading(false);
            window.location.href = pageNames.myDebts.path;
          })
          .catch(() => {
            setError('Erro ao válidar o usuário');
            setLoading(false);
          });
      })
      .catch(() => {
        setError('Erro ao válidar o token');
        setLoading(false);
      });
  }

  useLayoutEffect(() => {
    checkIsAdmin();
  }, []);

  return (
    <div className="page-admin">
      <div className="page-admin__container">
        <Text as="h1" variant="heading-large" weight="bold">
          Consultar Dívida
        </Text>
        <form onSubmit={onSubmit}>
          <InputField
            maxLength={14}
            id="default-input"
            label="Digite o CPF"
            placeholder="000.000.000-00"
            requiredInput
            value={cpfValue}
            onChange={onSetValue}
          />
          <Conditional
            condition={!!error}
            renderIf={
              <Alert
                status="error"
                title={{
                  content: error,
                }}
              />
            }
          />
          <Button htmlType="submit" loading={loading} disabled={!cpf.isValid(cpfValue)}>
            <IconSearch /> Consultar
          </Button>
        </form>
      </div>
    </div>
  );
};

export default Admin;
