import { useEffect, useRef, useState } from 'react';

import { useRedline } from '@libs/redline';
import useApiBillet from '@common/apis/billet/useApiBillet';

import {
  legacyBase64Decrypt,
  legacyBase64Encrypt,
} from '@libs/utils/helpers/base64';
import { openPDFInBlob } from '@libs/utils/helpers/openPDFInBlob';

import constants from '@common/constants';

import { useBilletProps } from './types';
import { Billet } from '@dues/pages/Partner/ClosedAgreement/types';
import { pageNames } from '@common/routes/pageNames';

const useBillet = ({
  agreement, // my agreements

  installment,
  msUrl,
  closedAgreement, // closed agreement
  isMyAgreementsPage = false,
  refCopiedWarning,
}: useBilletProps | any) => {
  const { track: trackRedline } = useRedline();

  const { apiGenerateBillet } = useApiBillet(msUrl);

  const initialBilletData = {
    linhaDigitavel: '',
    informacaoComplementar: '',
    pdf: '',
    hasBillet: false,
  };

  const [buttonText, setButtonText] = useState<string>(
    'Copiar código do boleto'
  );
  const [billet, setBillet] = useState<Billet>(
    closedAgreement?.billetData
      ? closedAgreement?.billetData
      : initialBilletData
  );
  const [hasBillet, setHasBillet] = useState<boolean>(
    closedAgreement?.billetData?.hasBillet
  );

  const [loading, setLoading] = useState<boolean>(true);

  const [loadingDownloadBillet, setLoadingDownloadBillet] =
    useState<boolean>(false);
  const [loadingCopyTheBilletCode, setLoadingCopyTheBilletCode] =
    useState(false);

  const [isBilletLoading, setIsBilletLoading] = useState<boolean>(false);

  const [isBilletSuccess, setIsBilletSuccess] = useState<boolean>(false);
  const [isCodeCopied, setIsCodeCopied] = useState<boolean>(false);
  const [showSkeleton, setShowSkeleton] = useState<boolean>(false);

  const singlePixPaymentMethod =
    closedAgreement?.singlePaymentMethod &&
    closedAgreement?.paymentMethod?.id === 'PIX';

  const isPollingBilletActive = useRef(
    singlePixPaymentMethod ? false : !closedAgreement?.billetData?.hasBillet
  );

  const [showPollingBilletComp, setShowPollingBilletComp] = useState<boolean>(
    isPollingBilletActive?.current
  );

  const generateBillet = ({ payload, closedAgreement }: any) => {
    if (!isMyAgreementsPage && billet.linhaDigitavel && billet.pdf) return;

    apiGenerateBillet
      .send(payload)
      .then((response) => {
        setLoading(false);

        if (!isMyAgreementsPage) {
          // TODO: util  <<<
          const closedAgreementFromLocal =
            localStorage.getItem(constants.debts.CLOSED_AGREEMENT) || '';
          const descryptClosedAgreement = JSON.parse(
            legacyBase64Decrypt(closedAgreementFromLocal)
          );

          const updatedClosedAgreement = {
            ...descryptClosedAgreement,
            billetData: response,
          };
          const encryptClosedAgreement = legacyBase64Encrypt(
            JSON.stringify(updatedClosedAgreement)
          );

          localStorage.setItem(
            constants.debts.CLOSED_AGREEMENT,
            encryptClosedAgreement
          );
        }

        setBillet(response);
        setHasBillet(response?.hasBillet);
      })
      .catch((err) => {
        console.error('[ERROR GENERATING BILLET]', err);
        setHasBillet(false);
        setLoading(false);
      });
  };

  const getBilletCode = () => {
    const billetObject = installment?.properties?.find(
      (prop: any) => prop.key === 'boleto'
    );

    if (!billetObject) return billet.linhaDigitavel;

    return billetObject?.stringValue;
  };

  const handleCopyBilletCode = (codeToCopy: string) => {
    trackRedline.userTracking.elementClicked({
      name: 'Boleto: Copiar código do boleto',
      location: window.location.pathname,
      elementType: 'button',
    });

    const trackingData = {
      code: codeToCopy,
      codeType: 'BILLET',
      agreementId: agreement?.id || closedAgreement?.agreement?.id,
      debtId: agreement?.debt?.id || closedAgreement?.debt?.id,
      installmentId: installment?.id,
      installment: installment?.installmentNumber || installment?.parcela,
      installmentValue:
        installment?.installmentValue || installment?.valorParcela,
    };

    const msReturnDefaultButton = 2000;

    setIsBilletLoading(true);

    navigator.clipboard
      .writeText(codeToCopy)
      .then(() => {
        trackRedline.agreements.paymentCodeCopied(trackingData);

        setIsCodeCopied(true);
        setIsBilletLoading(false);
        setIsBilletSuccess(true);

        setLoadingCopyTheBilletCode(true);

        setButtonText('Código do boleto copiado');

        if (refCopiedWarning?.current) {
          refCopiedWarning.current.open({
            visible: true,
            typeCode: 'boleto',
          });
        }

        setTimeout(() => {
          setIsBilletLoading(false);
          setIsBilletSuccess(false);
          setIsCodeCopied(false);

          setLoadingCopyTheBilletCode(false);
          setButtonText('Copiar código do boleto');

          if (refCopiedWarning?.current) {
            refCopiedWarning.current.open({
              visible: false,
            });
          }
        }, msReturnDefaultButton);
      })
      .catch((error) => {
        setLoadingCopyTheBilletCode(false);
        trackRedline.agreements.paymentCodeCopyErrored({
          ...trackingData,
          errorMessage: error.message,
        });

        console.error('[COPY BILLET CODE]:', error);
      });
  };

  const handleBilletDownload = () => {
    trackRedline.userTracking.elementClicked({
      name: 'Boleto: Baixar boleto',
      location: window.location.pathname,
      elementType: 'button',
    });

    if (billet.pdf) {
      openPDFInBlob(billet.pdf);
    } else {
      const payload = {
        installmentCode: installment?.installmentCode,
        parameters: {
          partnerSystemIdentifier: agreement?.partner?.identificador,
          requestParameters: {
            dividaId: agreement?.debt?.id,
            opcaoPagamentoId: installment?.id,
          },
        },
      };

      setLoadingDownloadBillet(true);

      apiGenerateBillet
        .send(payload)
        .then((response) => {
          setLoadingDownloadBillet(false);

          setBillet(response);
          setHasBillet(response?.hasBillet);
          openPDFInBlob(response.pdf);
        })
        .catch((err) => {
          console.error(
            '[ERROR GENERATING BILLET] - [handleBilletDownload]',
            err
          );
          setHasBillet(false);
          setLoadingDownloadBillet(false);
          const billetDownloadError = {
            agreement,
            isMyAgreementsPage,
          };
          localStorage.setItem(
            constants.debts.BILLET_NOT_FOUND,
            JSON.stringify(billetDownloadError)
          );
          window.open(pageNames.billetNotFoundError.path, '_blank');
        });
    }
  };

  const stopPollingGetBillet = () => {
    isPollingBilletActive.current = false;
    setShowPollingBilletComp(false);
  };

  //const ctrlFirstRequestBillet = useRef(true);
  const pollingGetBillet = (handleGenerateBillet: () => void) => {
    // if (isMyAgreementsPage && billet.pdf) {
    if (isMyAgreementsPage && getBilletCode()) {
      // test
      stopPollingGetBillet();
      return;
    }

    const timer = setTimeout(() => {
      stopPollingGetBillet();
    }, 10000);

    // if (ctrlFirstRequestBillet.current) {
    //   handleGenerateBillet();
    //   setTimeout(() => {
    //     ctrlFirstRequestBillet.current = false;
    //   }, 2050);
    // };

    const intervalBilletCode = setInterval(() => {
      //if (!ctrlFirstRequestBillet.current) handleGenerateBillet();
      handleGenerateBillet();

      if (
        (hasBillet && billet.linhaDigitavel) ||
        !isPollingBilletActive.current
      ) {
        stopPollingGetBillet();
        setShowSkeleton(true);

        clearTimeout(timer);
        clearInterval(intervalBilletCode);
      }
    }, 2000);

    return () => {
      clearInterval(intervalBilletCode);
      clearTimeout(timer);
    };
  };

  // >>>>>>>>>>> refactor all
  const handleGenerateBillet = ({
    agreementFormatted,
    currentInstallment,
  }: any) => {
    const isItau = agreement.partner.identificador === 'itau';
    const isFirstInstallment = currentInstallment.installmentNumber === 1;
    const currentPaymentIsPix = agreementFormatted?.paymentMethod === 'PIX'; // o.o
    const isBradesco = agreementFormatted?.partner.identificador === 'bradesco';

    const hasInstallments = agreementFormatted?.debt.detalhes?.[0].qtdParcelas;
    const hasEntryForDebitInAcc =
      (agreementFormatted?.properties?.find(
        (prop: { key: string }) => prop.key === constants.debts.PROPERTIES.ENTRY
      )?.decimalValue ?? 1) > 0;

    if (isBradesco && !hasEntryForDebitInAcc && hasInstallments) return;

    const ruleItauBillet = isItau && !isFirstInstallment && currentPaymentIsPix;
    const isBilletSelected = agreementFormatted?.paymentMethod === 'BILLET';

    const bCode = billet.pdf ? getBilletCode() : false;
    const shouldGenerateBillet = (isBilletSelected && !bCode) || ruleItauBillet;

    if (shouldGenerateBillet) {
      const payload = {
        installmentCode: currentInstallment.installmentCode,
        parameters: {
          partnerSystemIdentifier: agreementFormatted?.partner?.identificador,
          requestParameters: {
            dividaId: agreementFormatted?.debt.id,
            opcaoPagamentoId: currentInstallment.id,
          },
        },
      };

      generateBillet({ payload });
    }
  };

  useEffect(() => {
    if (showSkeleton) {
      setTimeout(() => {
        setShowSkeleton(false);
      }, 2000);
    }
  }, [showSkeleton]);

  return {
    buttonText,
    handleCopyBilletCode,
    handleBilletDownload,

    billetCode: getBilletCode(),
    billetPDF: billet?.pdf,

    generateBillet,
    pollingGetBillet,
    showPollingBilletComp: !getBilletCode() ? showPollingBilletComp : false,

    hasBillet,
    setHasBillet,

    billet,
    setBillet,

    loading,
    loadingDownloadBillet,
    setLoading,

    loadingCopyTheBilletCode,

    isCodeCopied,
    isBilletLoading,
    isBilletSuccess,
    showSkeleton,

    handleGenerateBillet,
  };
};
export default useBillet;
