import constants from '@common/constants';

export const getDebtCardTagDiscountDrop = (debt: any) => {
  const localDebtsCardTagDiscountDrop = localStorage.getItem(constants.debts.DEBTS_CARD_TAG_DISCOUNT_DROP) || '';

  if (localDebtsCardTagDiscountDrop) {
    let debtsCardTagDiscountDrop;
    try {
      debtsCardTagDiscountDrop = JSON.parse(localDebtsCardTagDiscountDrop);

      const partnerValues = debtsCardTagDiscountDrop?.partnerSystems
        .map((partner: { debts: any[]; }) => {
          return partner.debts.filter((debtItem: any) => debtItem.id === debt.id);
        })
        .flat();

      if (partnerValues.length > 0) {
        const discountDropValue = partnerValues[0]?.paymentOptions?.[0]?.differenceValue;
        const isDiscountDrop = partnerValues[0]?.isShowTag && discountDropValue > 1;
        return {
          discountDropValue,
          isDiscountDrop,
        };
      }
    } catch (error) {
      return {
        discountDropValue: 0,
        isDiscountDrop: false,
      };
    }
  }

  return {
    discountDropValue: 0,
    isDiscountDrop: false,
  };
};
