import { getCookie } from '@libs/utils/helpers/getSafeCookies';


const smsProviderExperiment = () => {
  const testCookieName = 'dx-pontal-zenvia';

  const variants = {
    '0': 'dx-zenvia',
    '1': 'dx-pontal'
  };

  const resultIsPontalVariant = () => {
    const currentResult = getCookie(testCookieName, false);

    if (!currentResult) return false;

    return currentResult.includes(variants[1]);
  };

  return {
    resultIsPontalVariant,
  };
};

export default smsProviderExperiment;
