import classNames from 'classnames';
import { ReactNode } from 'react';
import ReportText from './Text';
import ReportIcon from './Icon';
import './styles.scss';

interface ReportProps {
  type?: 'error' | 'success' | 'warning' | 'disabled' | 'info';
  size?: '1x' | '2x';
  border?: 'less' | 'more';
  className?: string;
  children?: ReactNode;
}

const Report = ({
  children,
  type = 'disabled',
  size = '1x',
  border = 'more',
  className = '',
}: ReportProps) => {
  const messageClassName = classNames('ac-report', {
    [`ac-report--type-${type}`]: !!type,
    [`ac-report--size-${size}`]: !!size,
    [`ac-report--border-${border}`]: !!border,
    [`${className}`]: !!className,
  });

  return <div className={`${messageClassName}`}>{children}</div>;
};

export default {
  Root: Report,
  Text: ReportText,
  Icon: ReportIcon,
};
