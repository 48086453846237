import { SHContent } from '../services/sortingHat/types';
import { getApp } from '@libs/utils/helpers/getApplication';

export const getUrlCurrentApp = ({
  links,
  preApproved,
}: {
  links: SHContent['links'];
  preApproved?: boolean;
}) => {
  const applicationLinksSlug =
    getApp() === 'acordocerto' ? 'ac-links' : 'cp-logged-links';

  const safeLinks = links || [];
  const appLinks = safeLinks.find(
    (link: any) => link.application === applicationLinksSlug
  );

  if (!appLinks) return;

  const link = preApproved
    ? appLinks?.link_pre_approved
    : appLinks?.link_without_debts;

  return link.url;
};
