import {
  Conditional,
  Text,
  IconClock,
  Button,
  COLOR_WARNING_50,
  COLOR_INFO_50,
  TokenField,
} from '@consumidor-positivo/aurora';
import { Field, Form } from 'houseform';
import useStepVerificationCode from './hooks';
import { z } from 'zod';

interface StepVerificationCodeProps {
  setIsModalOpen: (visible: boolean) => void;
  setShowWarning: (visible: boolean) => void;
  currFlow: string;
  newData: string;
  setCurrentStep: (step: 'newInfo' | 'verificationCode') => void;
}
const StepVerificationCode = ({
  currFlow,
  setIsModalOpen,
  setShowWarning,
  newData,
  setCurrentStep,
}: StepVerificationCodeProps) => {
  const {
    timer,
    dataHint,
    verifyVericationCode,
    handleResentToken,
    wrongTokenErrorMessage,
    codeFormRef,
    isButtonDisabled,
    onCompleteField,
    isLoading
  } = useStepVerificationCode({
    setIsModalOpen,
    setShowWarning,
    currFlow,
    newData,
    setCurrentStep,
  });

  return (
    <div className="update-contact-data-modal__step">
      <div>
        <Text variant="heading-small" weight="bold">
          Por segurança, precisamos verificar seu {currFlow}
        </Text>
        <Text color="secondary">
          Insira o código de 4 dígitos que enviamos para seu {currFlow}:
        </Text>

        <div className="update-contact-data-modal__parcial-data">
          {dataHint}
        </div>

        <Form onSubmit={verifyVericationCode} ref={codeFormRef}>
          {({ submit }) => (
            <form
              onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
                e.preventDefault();
                submit();
              }}
            >
              <div>
                <Field name="token" onChangeValidate={z.string().min(4, '-')}>
                  {({ setValue, isDirty }) => {
                    return (
                      <TokenField
                        label="Código de verificação"
                        size={4}
                        type="number"
                        showOptionalLabel={false}
                        timer={timer}
                        error={isDirty && !!wrongTokenErrorMessage}
                        errorMessage={wrongTokenErrorMessage}
                        onChange={setValue}
                        onComplete={onCompleteField}
                      />
                    );
                  }}
                </Field>
                <Conditional
                  condition={timer > 0}
                  renderIf={
                    <div className="timer-container">
                      <div className="timer">
                        <IconClock rawColor={COLOR_WARNING_50} />

                        <Text weight="bold" className="timer-seconds">
                          {timer}s
                        </Text>
                      </div>

                      <Text as="p" weight="regular">
                        Aguarde esse tempo antes de solicitar um novo código
                      </Text>
                    </div>
                  }
                  renderElse={
                    <div className="timer-container resent">
                      <div className="timer">
                        <IconClock rawColor={COLOR_INFO_50} />
                      </div>

                      <Text as="p" weight="regular">
                        Não recebeu o código de verificação?
                        <br />
                        <Button type="ghost" onClick={handleResentToken}>
                          Reenviar código
                        </Button>
                      </Text>
                    </div>
                  }
                />
              </div>
            </form>
          )}
        </Form>
      </div>
      <Button
        htmlType="submit"
        onClick={verifyVericationCode}
        disabled={isButtonDisabled}
        loading={isLoading}
      >
        Continuar
      </Button>
    </div>
  );
};

export default StepVerificationCode;
