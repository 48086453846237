export function getParamsUrl(url: string): Record<string, string> {
  const queryString = url.split('?')[1];
  let params = {};

  if (queryString) {
    const pairs = queryString.split('&');
    pairs.forEach((pair) => {
      const [key, value] = pair.split('=');

      params = {
        ...params,
        [key]: decodeURIComponent(value),
      };
    });

  }
  return params;
}
