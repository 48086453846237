// hooks
import { usePushWebSettingsStore } from '../storage/settings';

// Utils
import { getMarketplaceAuthToken } from './getMarketplaceAuthToken';

export const sendPushDataToMarketplace = async (data: object) => {
  try {
    const configurationJaiminho = usePushWebSettingsStore.getState();
    if (configurationJaiminho.settings.marketplaceApi === '') {
      return console.error('Push web: Api not defined.');
    }

    const authToken = getMarketplaceAuthToken();
    const headers: { [x: string]: string } = {
      'Content-Type': 'application/json',
    };

    if (authToken.includes('Bearer')) {
      headers['Authorization'] = authToken;
    } else {
      headers['X-Auth-Token'] = authToken;
    }

    await fetch(`${configurationJaiminho.settings.marketplaceApi}`, {
      method: 'POST',
      headers,
      body: JSON.stringify(data),
    });
  } catch (error) {
    console.error('Push Web: Send push data', error);
  }
};
