// Hooks
import { usePushWebSettingsStore } from '../storage/settings';

export const setUserId = (userId: string) => {
  const configurationJaiminho = usePushWebSettingsStore;

  configurationJaiminho.getState().handleSetSettings({
    userId,
  });

  // swRegistration.pushManager
  //   .getSubscription()
  //   .then((subscription) => {
  //     if (subscription) {
  //       var body = {
  //         userId,
  //         subscription,
  //       };
  //     }
  //   })
  //   .catch((err) => {
  //     console.error('Error during getSubscription()', err);
  //   });
};
