import { DebtProperty } from "@common/apis/sorting-debt/types";

export interface TypesCieloInfos {
  hasPropertiesCielo?: boolean;
  title?: string;
  propertiesCielo?: any;
}

export interface CurrentPartner {
  isItau: boolean;
  isBancodobrasil: boolean;
  isBradesco: boolean;
  isRiachuelo: boolean;
  isNubank: boolean;
  isCarrefour: boolean;
  isCielo: boolean;
}

export const getCurrentPartner = (partner: string): CurrentPartner => {
  const currentPartner: Partial<CurrentPartner> = {};
  const safePartner = partner || ""

  const partnerList: string[] = [
      "Itau",
      "Bancodobrasil",
      "Bradesco",
      "Riachuelo",
      "Nubank",
      "Cielo",
      "Carrefour"
  ];

  partnerList.forEach((item) => {
      currentPartner[`is${item}` as keyof CurrentPartner] = item.toLowerCase() === safePartner.toLowerCase();
  });

  return currentPartner as CurrentPartner;
};

/**
 *
 * @param properties - The array of properties to search for the given propertyName.
 * @param propertyName - The name of the property to filter by.
 * @description This method iterates over an array of properties and extracts an array of string values for a specific property specified by the propertyName.
 * @returns An array of string values found for the given propertyName.
 */
export const getProperties = (
  properties: any[],
  propertyName: string,
  propertyKey = "chave",
  hasDefault = true,
): any => {
  const result = properties?.filter((property) => property?.[propertyKey] === propertyName);
  const hasProps = result?.length > 0;
  const defaultResult = hasDefault ? [] : undefined;

  return hasProps ? result : defaultResult;
}

export const getInstallmentDueDate = (properties: any[], simulationDate: string) => {
  const firstInstallmentDueDate = getProperties(
    properties, "FIRST_INSTALLMENT_DUE_DATE"
  )?.[0]?.valorString;

  const dateOfUse = firstInstallmentDueDate || simulationDate;
  const dateObj = new Date(dateOfUse);
  const day = dateObj.getDate();
  return day;
};


export const getInfosCielo = (properties: DebtProperty[] | any) => {
  const titleKey = "DESCRIPTION_TITLE";
  const descKey = "DESCRIPTION_ITEM";

  const title = properties?.find((
    { key, chave }: { key: string, chave: string }
  ) => key === titleKey || chave === titleKey);

  const propsKey = getProperties(properties, descKey, "key", false);
  const propsChave = getProperties(properties, descKey);
  const items = propsKey || propsChave;

  return {
    hasPropertiesCielo: items?.length > 0,
    title: (title?.valueString || title?.valorString) ?? {},
    propertiesCielo: items,
  };
}

/**
 * Checks if the sorting debt cache is still valid by comparing the provided date with the current date.
 * @param {string | Date} date - The date to be compared with the current date. It can be either a string in a date format or a Date object.
 * @returns {boolean} - Returns true if the cache is still valid (current date is 1 hour greater than the provided date), otherwise false.
 */
export function cacheSortingDebtIsValid(date: string | Date) {
  const currentDate: number = Date.now();
  const expTimeCache: number = new Date(date).getTime();
  const sixtyMinutesInMilliseconds: number = 3600000;

  return currentDate - expTimeCache <= sixtyMinutesInMilliseconds;
}

