import { getSBDatasource } from '@libs/creditkit';

type fillUrlParametersProps = {
  marketplace: 'cp' | 'ac';
  startSourceUrl: string;
};

export const getSiteIdParam = async ({
  marketplace,
  startSourceUrl,
}: fillUrlParametersProps) => {
  if (!marketplace || !startSourceUrl) return;

  const urlParams = new URLSearchParams(new URL(startSourceUrl).search);

  const utmSource = urlParams.get('utm_source');
  const utmMedium = urlParams.get('utm_medium');
  const utmCampaign = urlParams.get('utm_campaign') || '0';
  const utmTest = urlParams.toString().match(/test-(\w+)/);
  const hasTest = utmTest ? `_${utmTest[0]}` : '';

  const datasource = await getSBDatasource('offer-url-parameters');
  if (!datasource || datasource?.length < 1) return;

  const mkt = datasource.find(
    (item) => item.name === `mkt_${marketplace}`
  )?.value;
  const source =
    datasource.find((item) => item.name === `so_${utmSource}`)?.value || '0';
  const medium =
    datasource.find((item) => item.name === `me_${utmMedium}`)?.value || '0';

  const siteIdParam = `mkt-${mkt}_so-${source}_me-${medium}_ca-${utmCampaign}${hasTest}`;

  return siteIdParam;
};
