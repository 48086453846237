import { useEffect, useState } from 'react';
import { baseFilters, partnersFilters } from '../filters';
import { useSearchParams } from 'react-router-dom';
import { FormattedDebt, DebtFilter } from '../types';
import { UserResponseProps } from '@admission/apis/onboarding/types';
import constants from '@common/constants';

const debtFilterParam = 'debts_filter';

interface DebtsFilteringProps {
  fullDebtList: FormattedDebt[];
  isStatusPartial: boolean;
}

export default function useDebtsFiltering({
  fullDebtList,
  isStatusPartial,
}: DebtsFilteringProps) {
  const [filteredDebts, setFilteredDebts] = useState(fullDebtList);
  const [filterList, setFilterList] = useState<DebtFilter[]>([]);
  const [hasFilter, setHasFilter] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState<DebtFilter | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [notFoundDebtsText, setNotFoundDebtsText] = useState<string>();

  useEffect(loadFilterFromUrl, []);
  useEffect(applyDebtFilters, [selectedFilter, fullDebtList]);

  function loadFilterFromUrl() {
    const urlFilter = searchParams.get(debtFilterParam) || '';
    const allFilters = [...baseFilters, ...partnersFilters];
    const selectedFilter = allFilters.find((filter) => filter.id === urlFilter);
    if (selectedFilter) {
      const isPartnerFilter = partnersFilters.includes(selectedFilter);
      const displayedFilters = isPartnerFilter
        ? [...baseFilters, selectedFilter]
        : baseFilters;
      setFilterList(displayedFilters);
      setHasFilter(true);
      setSelectedFilter(selectedFilter);
    }
  }

  function applyDebtFilters() {
    if (!selectedFilter) return;

    const filteredDebts = selectedFilter.filterFn(fullDebtList);
    const hasFilteredDebts = filteredDebts.length;

    if (hasFilteredDebts) {
      setFilteredDebts(filteredDebts);
      setNotFoundDebtsText('');
    } else if (!isStatusPartial) {
      const notFoundFilteredDebtsTxt = `Infelizmente <strong>não encontramos dívidas na categoria “${selectedFilter.label}”</strong>, mas você tem outras dívidas com propostas imperdíveis.`;
      setNotFoundDebtsText(notFoundFilteredDebtsTxt);
      clearFilter();
    }
  }

  function selectFilter(filter: DebtFilter) {
    setSelectedFilter(filter);
    searchParams.set(debtFilterParam, filter.id);
    setSearchParams(searchParams);
  }

  function clearFilter() {
    if (selectedFilter) {
      searchParams.delete(debtFilterParam);
      setSearchParams(searchParams);
      setSelectedFilter(null);
    }
  }

  function clearNotFoundDebtsMsg() {
    setNotFoundDebtsText(undefined);
  }

  return {
    filteredDebts,
    filterList,
    clearFilter,
    selectFilter,
    selectedFilter,
    hasFilter,
    notFoundDebtsText,
    clearNotFoundDebtsMsg,
  };
}
