// Hooks
import { usePushWebSettingsStore } from '../storage/settings';
import { unsubscribePushWeb } from './unsubscribe';

declare const window: {
  pwRegistration?: ServiceWorkerRegistration;
} & Window;

export const logoutPushWeb = async () => {
  const configurationJaiminho = usePushWebSettingsStore.getState();

  await unsubscribePushWeb();
  configurationJaiminho.resetStore();
};
