import React, { createContext } from 'react';

import useAgreementsContext from './agreements/hooks';
import { AgreementsContextTypes, initialValuesAgreementsContext } from './agreements/hooks';


interface RenegContextTypes { agreementsContext: AgreementsContextTypes; }
interface RenegProviderProps { children: React.ReactNode; }

export const RenegContext = createContext<RenegContextTypes>(initialValuesAgreementsContext);

export const RenegProvider: React.FC<RenegProviderProps> = ({ children }) => {
  const agreementsContext = useAgreementsContext();

  return (
    <RenegContext.Provider value={{ agreementsContext }}>
      {children}
    </RenegContext.Provider>
  );
};
