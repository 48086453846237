import { addXAuthTokenHeaders } from '@common/apis/customAddHeaders';
import customStorageAdapter from '@common/apis/customStorageAdpter';
import { createAlova } from 'alova';
import GlobalFetch from 'alova/GlobalFetch';
import ReactHook from 'alova/react';

export const alovaInstancePartners = (msUrl: string, isBlob = false) =>
  createAlova({
    statesHook: ReactHook,
    requestAdapter: GlobalFetch(),
    baseURL: msUrl,
    storageAdapter: customStorageAdapter,
    responded: {
      onSuccess: async (response) => {
        try {
          if (isBlob) {
            const blob = await response.blob();
            if (blob) {
              return blob;
            }
          }

          const json = await response.json();
          if (response.status >= 400) {
            throw json;
          }

          return json;
        } catch (err) {
          const stringifyResponse = JSON.stringify(response);
          const json = await JSON.parse(stringifyResponse);
          if (response.status >= 400) {
            throw json;
          }
          return json;
        }
      },
    },
    async beforeRequest(method) {
      if (msUrl?.includes('geminio')) return;

      await addXAuthTokenHeaders(method);
    },
  });
