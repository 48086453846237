import { useContext } from 'react';
import { RedlineContext } from '../context/';

export function useRedline() {
  const trackRedline = useContext(RedlineContext);

  return {
    ...trackRedline,
  };
}
