export default function handlePreloadError(e: Event) {
  const error = (e as any)?.payload as Error;
  const errorMsgSegments = (error.message || '').split(' ');
  const erroredCSSModule = errorMsgSegments[errorMsgSegments.length - 1] || '';

  if (!erroredCSSModule || !erroredCSSModule.endsWith('.css')) return;

  if (!hasResourceTriggeredReload(erroredCSSModule)) {
    e?.preventDefault(); //Stop propagate the "Error: Unable to preload CSS for"
    markAssetAsAlreadyRetried(erroredCSSModule);
    window.location.reload();
  }

  console.log(`Already retried to load asset ${erroredCSSModule}`);
}

const reloadControlKey = 'app-crash-reload';

function hasResourceTriggeredReload(resource: string) {
  const retryMap = getLoadRetryMap();
  return retryMap[resource];
}

function markAssetAsAlreadyRetried(assetPath: string) {
  const currentMap = getLoadRetryMap();
  const newMap = {
    ...currentMap,
    [assetPath]: 'true',
  };

  localStorage.setItem(reloadControlKey, JSON.stringify(newMap));
}

function getLoadRetryMap(): Record<string, string> {
  const currentMap = localStorage.getItem(reloadControlKey) || '{}';
  return JSON.parse(currentMap) as Record<string, string>;
}
