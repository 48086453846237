export const getMarketplaceAuthToken = () => {
  const cookies = document.cookie;
  const cookies_map: { [x: string]: string } = {};

  cookies.split(';').forEach((cookie) => {
    const str_value = cookie.trim();
    const [key, value] = str_value.split('=');
    cookies_map[key] = value;
  });

  let token = cookies_map['TOKEN'];

  if (!token) {
    const getTokenCP = JSON.parse(
      localStorage.getItem('newco/cp_tk') || `{}`
    ) as {
      access_token: string;
      refresh_token: string;
    };

    token = `Bearer ${getTokenCP.access_token}`;
  }

  return token;
};
