// Hooks
import { usePushWebSettingsStore } from '../storage/settings';

// Util
import { unsubscribePushWeb } from './unsubscribe';
import { getApplicationServerKey } from './getApplicationServerKey';
import { sendPushDataToMarketplace } from './sendPushDataToMarketplace';

declare const window: {
  pwRegistration: ServiceWorkerRegistration;
} & Window;

export const subscribePushWeb = async () => {
  try {
    const configurationJaiminho = usePushWebSettingsStore;
    const appKey = getApplicationServerKey();

    const swRegistration = window.pwRegistration;
    const appKeyFormatted = appKey as string;
    if (!swRegistration || typeof swRegistration === 'string') {
      console.warn(
        'Push Web: SwRegistration no window was not found or is not registered.'
      );
      return;
    }

    const subscription = await swRegistration.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: appKeyFormatted,
    });

    configurationJaiminho.getState().handleSetSettings({
      isSubscribed: true,
    });

    window.localStorage.setItem('_hmsls', String(Date.now()));

    sendPushDataToMarketplace({
      slug: 'push-web',
      payload: {
        ...subscription.toJSON()['keys'],
        endpoint: subscription.endpoint,
      },
    });
  } catch (error) {
    console.error(`Push Web: Subscribe ${error}`);
    await unsubscribePushWeb();
  }
};
