import isClient from './isClient';

export const isAndroid = () => {
  if (!isClient()) return;

  const userAgent = window.navigator.userAgent || window.navigator.vendor;

  return /android/i.test(userAgent);
};

export const isIOS = () => {
  if (!isClient()) return;

  const isIPadOniOS13 =
    window.navigator.userAgent.includes('Mac') && 'ontouchend' in document;

  return (
    [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod',
    ].includes(navigator.platform) || isIPadOniOS13
  );
};
