import { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useSH } from '@libs/creditkit';
import { delay } from '@libs/utils/helpers/delay';
import safestorage from '@libs/utils/helpers/getSafeStorage';
import { getCookie } from '@libs/utils/helpers/getSafeCookies';

import { useUserStore } from '@common/store/useUserStore';
import useTrackOffer from '@common/hooks/useTrackOffer';
import constants from '@common/constants';

import { UserTokenProps } from '@admission/apis/onboarding/types';

type UseSortingHat = {
  immediate?: boolean;
  decideDelayInSeconds?: number;
  forceCall?: boolean;
};

type SHRequestParams = {
  payload?: {
    documento?: string;
    [key: string]: any;
  };
  forceCall?: boolean;
  ignoreToken?: boolean;
};

export const useSortingHatLib = ({
  immediate,
  decideDelayInSeconds,
  forceCall,
}: UseSortingHat = {}) => {
  const user = useUserStore((state) => state.user);
  const location = useLocation();

  const {
    callSHTrack,
    callSHDecision,
    decisionsList,
    trackDone,
    decideDone,
    SHLoading: shLoading,
    SHError,
    resetContext: resetSHContext,
  } = useSH();

  const {
    trackDecisionEnriched,
    trackDecisionRequested,
    trackDecisionOutcomeReceived,
  } = useTrackOffer();

  const disabledSHPages = ['/confirmacao', '/carrinho', '/acordo-fechado'];
  const chooseDisabledSHPages = (pages: string[]): boolean => {
    return pages.some((pageName) => location.pathname.includes(pageName));
  };

  const hasADisabledSHPage = chooseDisabledSHPages(disabledSHPages);

  useEffect(() => {
    if (immediate && !hasADisabledSHPage) {
      if (!trackDone || forceCall) {
        shTrack({ forceCall: forceCall });
      }

      if (trackDone) {
        shDecision({ forceCall: forceCall });
      }
    }
  }, [user, trackDone, immediate, hasADisabledSHPage]);

  const shTrack = useCallback(
    (customPayload?: SHRequestParams) => {
      try {
        const safePayload = {
          ...user,
          ...customPayload?.payload,
        };
        callSHTrack({
          user: safePayload,
          decisionEnrichedEvent: trackDecisionEnriched,
          forceCall: customPayload?.forceCall || false,
        });
      } catch (error) {
        console.error('[SH][TRACK]: ', error);
      }
    },
    [user, hasADisabledSHPage]
  );

  const shDecision = useCallback(
    async (customPayload?: SHRequestParams) => {
      try {
        if (decideDelayInSeconds) {
          const milliseconds = decideDelayInSeconds * 1000;
          if (milliseconds > 0) {
            await delay(milliseconds);
          }
        }

        const safePayload = {
          ...user,
          ...customPayload?.payload,
        };

        const cookieToken = getCookie(constants.legacyAuth.TOKEN);
        const storageToken = await safestorage.getItem<UserTokenProps>(
          constants.auth.AUTH_USER_TOKEN
        );

        const token = storageToken?.access_token || cookieToken;
        const result = await callSHDecision({
          user: safePayload,
          decisionRequestEvent: trackDecisionRequested,
          decisionOutcomeReceivedEvent: trackDecisionOutcomeReceived,
          forceCall: customPayload?.forceCall || false,
          token,
          ignoreToken: customPayload?.ignoreToken,
        });

        return result;
      } catch (error) {
        console.error('[SH][DECIDE] ', error);
      }
    },
    [user, trackDone, decideDelayInSeconds]
  );

  const retryDecide = useCallback(
    async (ignoreToken = false) => {
      if (decisionsList.length === 0) {
        if (trackDone) {
          await delay(1000);
          await shDecision({ forceCall: true, ignoreToken });
        } else if (!hasADisabledSHPage) {
          shTrack({ forceCall: true });
        }
      }
    },
    [decisionsList, trackDone, hasADisabledSHPage]
  );

  return {
    shTrack,
    shDecision,
    decisionsList,
    decideDone,
    shLoading,
    trackDone,
    retryDecide,
    resetSHContext,
    SHError,
  };
};
