import acInterceptor from '../acInterceptor';
import { useRequest } from 'alova';

import { EngageEventTrackRequest, EngageEventTrackResponse } from '@common/apis/engage-track/types';

const baseURL = import.meta.env.VITE_AC_API

const useApiEngage = () => {
  const apiEngageTrackEvent = useRequest(
    (payload: EngageEventTrackRequest) =>
      acInterceptor({ baseURL }).Post<EngageEventTrackResponse>(
        '/marketplace/v1/app/engage/track-event',
        payload,
      ),
    {
      immediate: false,
    }
  );

  return { apiEngageTrackEvent };

};

export default useApiEngage;
