import { useState } from 'react';

import { sendFireboltSoftProposal } from '../services/firebolt';

import { addAutofillParam } from '@libs/utils/helpers/addAutofillParam';
import { isDev } from '@libs/utils/helpers/isDev';
import { getApp } from '@libs/utils/helpers/getApplication';
import { redirect } from '../helpers/redirect';
import { getUrlCurrentApp } from '../helpers/getUrlCurrentApp';
import { fillUrlParams } from '../helpers/fillUrlParams';

import { SHOffer } from '../services/sortingHat/types';
import { ExtraFunctionsType, UserType } from '../types';

type UseOfferCardProps = {
  offer: SHOffer;
  user: UserType;
  extraFunctions?: ExtraFunctionsType;
  hasTestAB?: boolean;
};

export function useOfferRedirect({
  offer,
  user,
  extraFunctions,
  hasTestAB,
}: UseOfferCardProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isRedirectModalOpen, setIsRedirectModalOpen] = useState(false);

  async function handleClickOffer() {
    setIsLoading(true);
    setIsRedirectModalOpen(true);
    extraFunctions?.beforeSendProposal?.();

    const { generalInfo } = offer;
    const { slug, partner } = generalInfo;
    const isAc = partner === 'acordocerto';

    if (isAc) return;

    const proposalResponse = await sendFireboltSoftProposal({
      ...user,
      chosenCard: slug,
      partner,
    });

    const fireboltId = proposalResponse?.firebolt_id;
    const fireboltSourceUrl = proposalResponse?.webhookResult?.redirectUrl;
    const storyblokSourceUrl = await getUrl({ offer, fireboltId });

    extraFunctions?.afterSendProposal?.(fireboltId);

    // region TEST AB - New BB-Ourocard Flow
    const isBBTest = slug === 'cards-bancodobrasil-ourocardfacil' && hasTestAB;
    const replaceBBUrl = (url: string) => {
      const newUrl = new URL(url.replace('produto', 'produtos'));
      newUrl.searchParams.set('new-flow', 'bb');

      return newUrl.toString();
    };
    const bbUrl =
      isBBTest && storyblokSourceUrl ? replaceBBUrl(storyblokSourceUrl) : null;
    // endregion

    const url = bbUrl || fireboltSourceUrl || storyblokSourceUrl;

    extraFunctions?.beforeRedirect?.(fireboltId, url);

    redirect(url);
    setIsRedirectModalOpen(false);
    setIsLoading(false);

    extraFunctions?.afterRedirect?.(fireboltId, url);
  }

  async function getUrl({
    offer,
    fireboltId,
  }: {
    offer: SHOffer;
    fireboltId: string;
  }) {
    const { content, generalInfo } = offer;
    const { slug, links, full_funnel } = content;
    const { preApproved } = generalInfo;

    const url = getUrlCurrentApp({ links, preApproved });

    if (!url) return console.error(`${slug} offer URL not found`);

    let completedUrl = await fillUrlParams({
      url,
      fireboltId,
      idUser: user?.id,
    });

    if (full_funnel) {
      completedUrl = addAutofillParam({
        productSlug: slug,
        url: completedUrl,
        user,
      });

      if (isDev()) {
        const domain = getApp();
        completedUrl = completedUrl
          .replace(domain, `dev.${domain}`)
          .replace('www.', '');
      }
    }

    return completedUrl;
  }

  return {
    isLoading,
    handleClickOffer,
    isRedirectModalOpen,
  };
}
