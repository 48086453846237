import { legacyBase64Decrypt } from "@libs/utils/helpers/base64";
import constants from "@common/constants";

/**
 * Retrieves and decrypts agreements data from local storage.
 *
 * This function checks if there is cached agreements data in local storage
 * under the specified key. If found, it decrypts the data using
 * `legacyBase64Decrypt` and parses it as JSON.
 *
 * @returns {Object|undefined} The parsed agreements data if available,
 *                             or undefined if no data is found.
 *
 * @throws {Error} Will throw an error if the decryption or parsing fails.
 *
 * @example
 * const agreements = getAgreementsFromLS();
 * if (agreements) {
 *   // Process the agreements data
 * }
 */
const getAgreementsFromLS = () => {
  const agreementsDataCache = localStorage.getItem(constants.debts.AGREEMENTS_RES);

  if (agreementsDataCache) {
    const descryptedAgreements = legacyBase64Decrypt(agreementsDataCache);
    const agreeementsData = JSON.parse(descryptedAgreements);

    return agreeementsData;
  }

  return undefined;
};

export default getAgreementsFromLS;

