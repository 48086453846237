import classNames from 'classnames';
import { ReactNode } from 'react';

interface ReportTextProps {
  children?: ReactNode;
}

const ReportText = ({ children }: ReportTextProps) => {
  const messageClassName = classNames('ac-report-text', {});

  return <div className={messageClassName}>{children}</div>;
};

export default ReportText;
