import { z } from 'zod';
import { Field, Form } from 'houseform';
import { isValidCellphone } from '@libs/utils/validators/cellphone';
import { maskCellphone } from '@libs/utils/masks/cellphone';
import { isValidEmail } from '@libs/utils/validators/email';
import {
  Button,
  Conditional,
  InputField,
  Text,
} from '@consumidor-positivo/aurora';
import useStepNewInfo from './hooks';

interface StepNewInfoProps {
  setCurrentStep: (step: 'newInfo' | 'verificationCode') => void;
  currFlow: 'celular' | 'e-mail';
  setNewData: (newData: string) => void;
  isOpen: boolean;
}
const StepNewInfo = ({
  setCurrentStep,
  currFlow,
  setNewData,
  isOpen,
}: StepNewInfoProps) => {
  const {
    handleDataUpdate,
    isLoading,
    inputErrorMessage,
    formRef,
    isUpdateButtonDisabled,
    isConfirmationInputValid,
  } = useStepNewInfo({
    setCurrentStep,
    currFlow,
    setNewData,
    isOpen,
  });
  return (
    <div className="update-contact-data-modal__step">
      <div>
        <Text variant="heading-small" weight="bold">
          Alterar {currFlow} cadastrado
        </Text>

        <Text color="secondary">
          Você vai receber lembretes, ofertas e atualizações nesses contatos.
        </Text>

        <Conditional
          condition={currFlow === 'celular'}
          renderIf={
            <Form onSubmit={handleDataUpdate} ref={formRef}>
              {({ submit }) => (
                <form
                  onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
                    e.preventDefault();
                    submit();
                  }}
                >
                  <Field
                    name="phone"
                    onChangeValidate={z.string().min(15, '-')}
                    onBlurValidate={isValidCellphone}
                    preserveValue={true}
                  >
                    {({ value, setValue, onBlur, errors }) => {
                      function onSetValue(e: any) {
                        const maskValue = maskCellphone(e.target.value);
                        setValue(maskValue);
                      }

                      return (
                        <InputField
                          value={value}
                          onBlur={onBlur}
                          onChange={onSetValue}
                          type="text"
                          error={
                            (errors.length > 0 && errors[0] !== '-') ||
                            !!inputErrorMessage
                          }
                          errorMessage={errors[0] || inputErrorMessage}
                          label="Celular"
                          placeholder="Ex.: (11) 987654321"
                          inputMode="numeric"
                          showOptionalLabel={false}
                        />
                      );
                    }}
                  </Field>

                  <Field
                    name="cellphone-confirmation"
                    listenTo={['phone']}
                    preserveValue={true}
                    onBlurValidate={isConfirmationInputValid}
                  >
                    {({ value, setValue, onBlur, errors, isTouched }) => {
                      function onSetValue(e: any) {
                        const maskValue = maskCellphone(e.target.value);
                        setValue(maskValue);
                      }

                      return (
                        <InputField
                          value={value}
                          onBlur={onBlur}
                          onChange={onSetValue}
                          type="text"
                          error={
                            errors.length > 0 && errors[0] !== '-' && isTouched
                          }
                          errorMessage={errors[0]}
                          label="Confirmar novo celular"
                          placeholder="Ex.: (11) 987654321"
                          inputMode="numeric"
                          showOptionalLabel={false}
                        />
                      );
                    }}
                  </Field>
                </form>
              )}
            </Form>
          }
        />

        <Conditional
          condition={currFlow === 'e-mail'}
          renderIf={
            <Form onSubmit={handleDataUpdate} ref={formRef}>
              {({ submit }) => (
                <form
                  onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
                    e.preventDefault();
                    submit();
                  }}
                >
                  <Field
                    name="email"
                    onChangeValidate={z.string().email('')}
                    onBlurValidate={isValidEmail}
                  >
                    {({ value, setValue, onBlur, errors }) => {
                      function onSetValue(
                        e: React.ChangeEvent<HTMLInputElement>
                      ) {
                        setValue(e.target.value);
                      }

                      return (
                        <InputField
                          value={value}
                          onBlur={onBlur}
                          onChange={onSetValue}
                          name="email"
                          label="E-mail"
                          type="email"
                          placeholder="Digite o seu e-mail"
                          showOptionalLabel={false}
                          error={
                            (errors.length > 0 && errors[0] !== '-') ||
                            !!inputErrorMessage
                          }
                          errorMessage={errors[0] || inputErrorMessage}
                        />
                      );
                    }}
                  </Field>
                  <Field
                    name="email-confirmation"
                    onBlurValidate={isConfirmationInputValid}
                    listenTo={['email']}
                  >
                    {({ value, setValue, onBlur, errors, isTouched }) => {
                      function onSetValue(
                        e: React.ChangeEvent<HTMLInputElement>
                      ) {
                        setValue(e.target.value);
                      }

                      return (
                        <InputField
                          value={value}
                          onBlur={onBlur}
                          onChange={onSetValue}
                          name="email-confirmation"
                          label="Confirmar novo e-mail"
                          type="email"
                          placeholder="Digite seu e-mail novamente"
                          showOptionalLabel={false}
                          error={
                            errors.length > 0 && errors[0] !== '-' && isTouched
                          }
                          errorMessage={errors[0]}
                        />
                      );
                    }}
                  </Field>
                </form>
              )}
            </Form>
          }
        />
      </div>
      <Button
        disabled={isUpdateButtonDisabled}
        htmlType="submit"
        onClick={handleDataUpdate}
        loading={isLoading}
      >
        Atualizar
      </Button>
    </div>
  );
};

export default StepNewInfo;
