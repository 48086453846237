import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { SHDecisionGeneralInfo, SHOffer } from '@libs/creditkit';

import { useUserStore } from '@common/store/useUserStore';
import { offerCategory } from '@common/constants/offerCategory';
import { pageNames } from '@common/routes/pageNames';
import useTrackOffer from '@common/hooks/useTrackOffer';
import { useSortingHatLib } from '@common/hooks/useSortingHatLib';

type GetOffersType = {
  callSHDecide?: boolean;
  forceCall?: boolean;
};

export function useGetOffers({ callSHDecide, forceCall }: GetOffersType = {}) {
  const navigate = useNavigate();
  const { trackElementClicked } = useTrackOffer();
  const { decisionsList, decideDone, shLoading } = useSortingHatLib({
    immediate: callSHDecide,
    decideDelayInSeconds: 0,
    forceCall,
  });

  const { myDebt } = useUserStore((state) => state);
  const [featuredOffers, setFeaturedOffers] = useState<SHOffer[]>([]);
  const [preApprovedOffers, setPreApprovedOffers] = useState<SHOffer[]>([]);
  const [filteredOffers, setFilteredOffers] = useState<SHOffer[]>([]);
  const [offers, setOffers] = useState<SHOffer[]>([]);

  const location = useLocation();

  const initialCategory = location?.state?.key
    ? location.state.key
    : offerCategory.ALL;

  const noOffer = !shLoading && decideDone && decisionsList.length <= 0;

  const setStateOffers = (offers: SHOffer[]) => {
    const filteredFeaturedOffers = filterByType(offers, 'featured');
    const filteredPreAapprovedOffers = filterByPath(filteredFeaturedOffers);
    const filteredFeaturedByPath = filterByPath(filteredFeaturedOffers);

    setOffers(offers);
    setFeaturedOffers(filteredFeaturedByPath);
    setPreApprovedOffers(filteredPreAapprovedOffers);
  };

  const filterByPath = (offers: SHOffer[]) => {
    const filteredResult = [
      'ac-destaque-dashboard',
      'ac-destaque-ofertas',
      'ac-monitoramento-financeiro-destaque-ofertas',
      'ac-ofertas-pre-aprovadas-lista-ofertas',
    ].reduce((acc: any, curr: string) => {
      const filteredSlug = offers.filter(({ generalInfo }) => {
        const safeFeatured = Array.isArray(generalInfo['featured'])
          ? generalInfo['featured']
          : [];
        return safeFeatured?.includes(curr);
      });

      return acc
        ? {
            ...acc,
            [curr]: filteredSlug,
          }
        : acc;
    }, {});

    const slugsByPath: Record<string, string> = {
      '/minhas-dividas': 'ac-destaque-dashboard',
      [`/${myDebt?.partner?.identifier}/acordo-fechado`]:
        'ac-destaque-dashboard',
      '/ofertas': 'ac-destaque-ofertas',
      '/ofertas/ofertas-pre-aprovadas':
        'ac-ofertas-pre-aprovadas-lista-ofertas',
      '/monitoramento/financeiro':
        'ac-monitoramento-financeiro-destaque-ofertas',
    };

    return filteredResult[slugsByPath[location.pathname]];
  };

  const filterByType = (
    offers: SHOffer[],
    type: keyof SHDecisionGeneralInfo
  ) => {
    return offers.filter(({ generalInfo }) => generalInfo[type]);
  };

  const filterByCategory = (categoryToFilter: string) => {
    if (categoryToFilter === offerCategory.ALL) {
      setFilteredOffers(offers);
      return offers;
    } else {
      const filtered = offers.filter(
        ({ content }) => content?.category === categoryToFilter
      );
      setFilteredOffers(filtered);
      return filtered;
    }
  };

  const numberOfOffers = (exception?: keyof SHDecisionGeneralInfo) => {
    if (exception) {
      const offersWithExceptions = offers?.filter(
        ({ generalInfo }) => !generalInfo?.[exception]
      );

      return offersWithExceptions.length;
    } else {
      return offers.length;
    }
  };

  function redirectToOffers() {
    trackElementClicked({
      location: 'ac-minhas-dividas-destaque-ofertas',
      elementType: 'button',
      name: pageNames.offers.path,
      text: 'Ver ofertas',
    });
    navigate(pageNames.offers.path, { state: { key: offerCategory.ALL } });
  }

  useEffect(() => {
    filterByCategory(initialCategory);
  }, [offers]);

  useEffect(() => {
    if (decisionsList?.length > 0) {
      setStateOffers(decisionsList);
    }
  }, [decisionsList]);

  return {
    offers,
    featuredOffers,
    preApprovedOffers,
    filterByCategory,
    filteredOffers,
    initialCategory,
    numberOfOffers,
    redirectToOffers,
    decideDone,
    shLoading,
    noOffer,
  };
}
