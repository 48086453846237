import { COLOR_ERROR_50, IconMeh, Text } from '@consumidor-positivo/aurora';
import './styles.scss';

export const OfferEmptyState = () => {
  return (
    <div className="offer-empty-state">
      <IconMeh rawColor={COLOR_ERROR_50} />
      <div>
        <Text variant="body-medium" variantDesk="body-large" weight="bold">
          Ops, algo deu errado e não encontramos ofertas no momento
        </Text>
        <Text variant="body-small" variantDesk="body-medium">
          Já estamos trabalhando para resolver e pedimos que tente novamente
          mais tarde, por favor.
        </Text>
      </div>
    </div>
  );
};
