const checkIsValidInstallmentDueDate = (currentInstallment: any) => {
  const { dueDate = new Date() } = currentInstallment;
  const currDate = new Date();
  const due = new Date(dueDate);

  // D-15
  const expirationPeriod = 15;
  const msExpirationTime = expirationPeriod * 24 * 60 * 60 * 1000;
  const msExpirationDate = currDate.getTime() - msExpirationTime;
  const expirationDate = new Date(msExpirationDate);

  // D+10
  const limitPeriod = 10;
  const mslimitTime = limitPeriod * 24 * 60 * 60 * 1000;
  const mslimitDate = currDate.getTime() + mslimitTime;
  const limitDate = new Date(mslimitDate);

  return due >= expirationDate && due <= limitDate;
};

export default checkIsValidInstallmentDueDate;
