import { alovaInstanceLegacyOnboarding } from '@admission/apis/onboarding/alovaInstanceLegacyOnboarding';
import { alovaInstanceOnboarding } from '@admission/apis/onboarding/alovaInstanceOnboarding';
import { useRequest } from 'alova';

import {
  transformSingupPayload,
  transformSigninPayload,
  transformMFASigninPayload,
  transformKbaRegisterPayload,
  transformCheckCodePayload,
  transformGenerateCodePayload,
} from './transformPayload';
import { getFbAvailableCookies } from '@libs/utils/helpers/getFacebookPixel';
import { removeMask } from '@libs/utils/masks/cpf';
import {
  CustomerResponseProps,
  ImplicitAccessProps,
  ImplicitSigninPayloadProps,
  RecoveryPasswordKbaResponseProps,
  RecoveryPasswordKbaAnswersResponseProps,
  SigninPayloadProps,
  SignupPayloadProps,
  SubscriptionPayloadProps,
  UserResponseProps,
  ValidateCurrentPasswordResponseProps,
  CheckPhoneResponseProps,
  MFASigninPayloadProps,
  ResetPasswordCheckCodeResponseProps,
  GenerateCodePayload,
  CheckCodePayload,
  OnboardingTrackResponseProps,
  KbaRegisterPayloadProps,
  KbaRegisterResponseProps,
  CheckGeneratedTokenResponseProps,
  GenerateTokenResponseProps,
} from './types';
import useGTM from '@common/hooks/useGTM';
import smsProviderExperiment from '@common/abtest/experiments/smsProviderExperiment';

const { resultIsPontalVariant } = smsProviderExperiment();

const headers = {
  'Content-Type': 'application/json;charset=UTF-8',
  'Access-Control-Allow-Origin': '*',
};

const useApiOnboarding = () => {
  const fbCookies = getFbAvailableCookies();
  const GTM = useGTM();
  const gclid = GTM.getGclid();
  const additionalParameters = {
    ...fbCookies,
    ...(gclid ?? {}),
  };

  const apiOnboardingTrack = useRequest(
    (payload) =>
      alovaInstanceOnboarding.Post<OnboardingTrackResponseProps>(
        '/marketplace/v1/public/customer/track',
        payload,
        {
          headers: headers,
        }
      ),
    {
      immediate: false,
    }
  );

  const apiOnboardingSignup = useRequest(
    (payload: SignupPayloadProps) =>
      alovaInstanceOnboarding.Post<UserResponseProps>(
        '/marketplace/v1/public/auth/signup',
        {
          ...transformSingupPayload(payload, additionalParameters),
        },
        {
          headers: headers,
        }
      ),
    {
      immediate: false,
      retry: 2,
    }
  );

  const apiOnboardingKbaRegister = useRequest(
    (payload: KbaRegisterPayloadProps) =>
      alovaInstanceOnboarding.Post<KbaRegisterResponseProps>(
        '/marketplace/v1/public/kba/register',
        {
          ...transformKbaRegisterPayload(payload),
        },
        {
          headers: headers,
        }
      ),
    {
      immediate: false,
      retry: 2,
    }
  );

  const apiOnboardingSignin = useRequest(
    (payload: SigninPayloadProps) =>
      alovaInstanceOnboarding.Post<UserResponseProps>(
        '/marketplace/v1/public/auth/signin',
        {
          ...transformSigninPayload(payload),
        },
        {
          headers: headers,
        }
      ),
    {
      immediate: false,
      retry: 2,
    }
  );

  const apiOnboardingMFASignin = useRequest(
    (payload: MFASigninPayloadProps) =>
      alovaInstanceOnboarding.Post<UserResponseProps>(
        '/marketplace/v1/public/auth/signin/mfa',
        {
          ...transformMFASigninPayload(payload),
        },
        {
          headers: {
            ...headers,
            'x-auth-token': payload?.token,
          },
        }
      ),
    {
      immediate: false,
      retry: 2,
    }
  );

  const apiOnboardingSigninEngage = useRequest(
    (payload: ImplicitAccessProps) =>
      alovaInstanceOnboarding.Post<any>(
        '/marketplace/v1/engage/signin',
        {},
        {
          headers: {
            ...headers,
            'x-auth-token': payload.token,
          },
        }
      ),
    {
      immediate: false,
    }
  );

  const apiOnboardingImplicitAccess = useRequest(
    (payload: ImplicitAccessProps) =>
      alovaInstanceOnboarding.Post<any>(
        '/marketplace/v1/public/auth/signup/implicit',
        payload,
        {
          headers: headers,
        }
      ),
    {
      immediate: false,
    }
  );

  const apiOnboardingCancelAccout = useRequest(
    () =>
      alovaInstanceOnboarding.Delete('/marketplace/v1/app/customer/deactive'),
    {
      immediate: false,
    }
  );

  const apiOnboardingResetPassword = useRequest(
    (payload) =>
      alovaInstanceOnboarding.Post(
        '/marketplace/v1/public/auth/resetpassword',
        {
          code: payload.code,
          password: payload.password,
          document: payload.customerId,
        },
        {
          headers: headers,
        }
      ),
    {
      immediate: false,
    }
  );

  const apiOnboardingResetPasswordSendCode = useRequest(
    (payload) =>
      alovaInstanceOnboarding.Post(
        '/marketplace/v1/public/auth/resetpassword/code',
        {
          document: payload.document,
        },
        {
          headers: headers,
        }
      ),
    {
      immediate: false,
    }
  );

  const apiOnboardingResetPasswordCheckCode = useRequest(
    (payload) =>
      alovaInstanceOnboarding.Post<ResetPasswordCheckCodeResponseProps>(
        '/marketplace/v1/public/auth/resetpassword/check-code',
        {
          token: payload.token,
        },
        {
          headers: headers,
        }
      ),
    {
      immediate: false,
    }
  );

  const apiOnboardingUpdatePassword = useRequest(
    (payload) =>
      alovaInstanceOnboarding.Put(
        '/marketplace/v1/app/customer/password',
        {
          password: payload,
        },
        {
          headers: headers,
        }
      ),
    {
      immediate: false,
    }
  );

  const apiOnboardingLegacyUpdatePassword = useRequest(
    (payload) =>
      alovaInstanceLegacyOnboarding.Put(
        '/api/v2/password/authenticated',
        {
          password: payload.password,
        },
        {
          headers: headers,
        }
      ),
    {
      immediate: false,
    }
  );

  const apiOnboardingValidateCurrentPassword = useRequest(
    (payload) =>
      alovaInstanceOnboarding.Post<ValidateCurrentPasswordResponseProps>(
        '/marketplace/v1/app/customer/validate-password',
        payload
      ),
    {
      immediate: false,
    }
  );

  const apiOnboardingCustomer = useRequest(
    () =>
      alovaInstanceOnboarding.Get<CustomerResponseProps>(
        '/marketplace/v1/app/customer',
        {
          headers: {
            ...headers,
          },
          localCache: null,
        }
      ),
    {
      immediate: false,
    }
  );

  const apiOnboardingCustomerUpdate = useRequest(
    (payload) =>
      alovaInstanceOnboarding.Put(
        '/marketplace/v1/app/customer/update',
        payload
      ),
    {
      immediate: false,
    }
  );

  const apiOnboardingVerificationToken = useRequest(
    (request) =>
      alovaInstanceLegacyOnboarding.Post(
        `/api/v2/customer/verification-token`,
        request
      ),
    {
      immediate: false,
    }
  );

  // e = true is a flag to indicate that we are using our new KBA service instead of bigData
  const apiOnboardingGetRecoveryPasswordKba = useRequest(
    (payload) =>
      alovaInstanceOnboarding.Post<RecoveryPasswordKbaResponseProps>(
        '/marketplace/v1/public/kba/password-reset/questions',
        {
          document: payload.document,
          'g-recaptcha-response': payload['g-recaptcha-response'],
          'g-recaptcha-action': payload['g-recaptcha-action'],
          e: true,
        },
        {
          headers: headers,
        }
      ),
    {
      immediate: false,
    }
  );

  const apiOnboardingSendRecoveryPasswordKbaAnswers = useRequest(
    (payload) =>
      alovaInstanceOnboarding.Post<RecoveryPasswordKbaAnswersResponseProps>(
        '/marketplace/v1/public/kba/password-reset/answers',
        payload,
        {
          headers: headers,
        }
      ),
    {
      immediate: false,
    }
  );

  const apiOnboardingValidateTokenUpdateUser = useRequest(
    (payload) =>
      alovaInstanceLegacyOnboarding.Get(
        `/api/v2/customer/verification-token/${payload.kind}/${payload.token}`
      ),
    {
      immediate: false,
    }
  );

  const apiOnboardingUpdateNotifications = useRequest(
    (payload: SubscriptionPayloadProps) =>
      alovaInstanceLegacyOnboarding.Post(`/api/v2/subscription/`, payload),
    {
      immediate: false,
    }
  );

  const apiOnboardingImplicitSignin = useRequest(
    (payload: ImplicitSigninPayloadProps) =>
      alovaInstanceLegacyOnboarding.Post(
        `/api/v2/consumidorpositivo/token`,
        payload
      ),
    {
      immediate: false,
    }
  );

  const apiOnboardingCheckPhone = useRequest(
    (payload) =>
      alovaInstanceOnboarding.Post<CheckPhoneResponseProps>(
        '/marketplace/v1/public/auth/check-phone',
        {
          document: removeMask(payload.document),
          phone: removeMask(payload.phone),
        },
        {
          headers: headers,
        }
      ),
    {
      immediate: false,
    }
  );

  const apiOnboardingGeneratePhoneCode = useRequest(
    (payload: GenerateCodePayload) =>
      alovaInstanceOnboarding.Post(
        '/marketplace/v1/public/auth/generatecode',
        {
          ...transformGenerateCodePayload(payload),
          b: resultIsPontalVariant() ? '1' : '0',
        },
        {
          headers: headers,
        }
      ),
    {
      immediate: false,
    }
  );

  const apiOnboardingGenerateCodeReset = useRequest(
    (payload: GenerateCodePayload) =>
      alovaInstanceOnboarding.Post(
        '/marketplace/v1/public/auth/generatecode/verify',
        {
          ...transformGenerateCodePayload(payload),
        },
        {
          headers: headers,
        }
      ),
    {
      immediate: false,
    }
  );

  const apiOnboardingCheckPhoneCode = useRequest(
    (payload: CheckCodePayload) =>
      alovaInstanceOnboarding.Post(
        '/marketplace/v1/public/auth/generatecode/check',
        { ...transformCheckCodePayload(payload) },
        { headers: headers }
      ),
    {
      immediate: false,
    }
  );

  const apiOnboardingGenerateOTP = useRequest(
    (payload: { document: string }) =>
      alovaInstanceOnboarding.Post(
        '/marketplace/v1/public/auth/signin/code',
        {
          document: payload.document,
        },
        {
          headers: headers,
        }
      ),
    {
      immediate: false,
    }
  );

  const apiOnboardingGenerateSigninMFACode = useRequest(
    (payload: GenerateCodePayload) =>
      alovaInstanceOnboarding.Post(
        '/marketplace/v1/public/auth/signin/mfa-code',
        {
          ...transformGenerateCodePayload(payload),
          b: resultIsPontalVariant() ? '1' : '0',
        },
        {
          headers: headers,
        }
      ),
    {
      immediate: false,
    }
  );

  const apiCheckGeneratedTokenCode = useRequest(
    (payload) =>
      alovaInstanceOnboarding.Post<CheckGeneratedTokenResponseProps>(
        '/marketplace/v1/app/customer/generate-code/check',
        payload,
        {
          headers: headers,
        }
      ),
    {
      immediate: false,
    }
  );

  const apiGenerateTokenCode = useRequest(
    (payload) =>
      alovaInstanceOnboarding.Post<GenerateTokenResponseProps>(
        '/marketplace/v1/app/customer/generate-code',
        payload,
        {
          headers: headers,
        }
      ),
    {
      immediate: false,
    }
  );

  return {
    apiOnboardingCancelAccout,
    apiOnboardingCustomer,
    apiOnboardingCustomerUpdate,
    apiOnboardingImplicitSignin,
    apiOnboardingSigninEngage,
    apiOnboardingKbaRegister,
    apiOnboardingResetPassword,
    apiOnboardingResetPasswordSendCode,
    apiOnboardingResetPasswordCheckCode,
    apiOnboardingSignin,
    apiOnboardingSignup,
    apiOnboardingUpdateNotifications,
    apiOnboardingUpdatePassword,
    apiOnboardingValidateCurrentPassword,
    apiOnboardingGetRecoveryPasswordKba,
    apiOnboardingSendRecoveryPasswordKbaAnswers,
    apiOnboardingValidateTokenUpdateUser,
    apiOnboardingVerificationToken,
    apiOnboardingImplicitAccess,
    apiOnboardingGeneratePhoneCode,
    apiOnboardingGenerateCodeReset,
    apiOnboardingCheckPhoneCode,
    apiOnboardingCheckPhone,
    apiOnboardingMFASignin,
    apiOnboardingGenerateOTP,
    apiOnboardingTrack,
    apiOnboardingGenerateSigninMFACode,
    apiGenerateTokenCode,
    apiCheckGeneratedTokenCode,
  };
};

export default useApiOnboarding;
