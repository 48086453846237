import { useEffect, useRef, useState } from 'react';
import { useUserStore } from '@common/store/useUserStore';
import useApiOnboarding from '@admission/apis/onboarding/useApiOnboarding';
import { useRedline } from '@libs/redline';
import useAuth from '@common/hooks/useAuth';
import { unmask } from 'remask';
import { removeMask } from '@libs/utils/masks/cpf';
import { FormInstance } from 'houseform';

interface useStepVerificationCodeProps {
  setIsModalOpen: (visible: boolean) => void;
  setShowWarning: (visible: boolean) => void;
  currFlow: string;
  newData: string;
  setCurrentStep: (step: 'newInfo' | 'verificationCode') => void;
}

const useStepVerificationCode = ({
  setIsModalOpen,
  setShowWarning,
  currFlow,
  newData,
  setCurrentStep,
}: useStepVerificationCodeProps) => {
  const {
    apiOnboardingCustomerUpdate,
    apiCheckGeneratedTokenCode,
    apiOnboardingCustomer,
    apiGenerateTokenCode,
  } = useApiOnboarding();
  const codeFormRef = useRef<FormInstance>(null);
  const { updateAuthUserLocalStorage } = useAuth();
  const [wrongTokenErrorMessage, setWrongTokenErrorMessage] =
    useState<string>('');
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [timer, setTimer] = useState<number>(60);

  useEffect(() => {
    let currentSecond = timer;

    const handle40SecondsTimer = setInterval(() => {
      currentSecond--;
      setTimer(currentSecond);
    }, 1000);

    if (currentSecond === 0) {
      clearInterval(handle40SecondsTimer);
    }

    return () => {
      clearInterval(handle40SecondsTimer);
    };
  }, [timer]);

  const { addUser: addUserStore, user } = useUserStore();
  const { track: trackRedline } = useRedline();

  const updateUserStore = () => {
    apiOnboardingCustomer.send().then((customer) => {
      const userWithUpdatedContact = {
        ...user,
        email: customer.email,
        celular: unmask(customer.celular),
      };

      addUserStore(userWithUpdatedContact);
      updateAuthUserLocalStorage(userWithUpdatedContact);
    });
  };

  const handleSubmitPhone = (code: string) => {
    apiCheckGeneratedTokenCode
      .send({ code })
      .then((_) => {
        apiOnboardingCustomerUpdate
          .send({
            securityToken: code,
            phone: {
              value: newData,
            },
          })
          .then((_) => {
            trackRedline.userAuthentication.userAttributeUpdated({
              devedorId: user.id,
              customer_id: user.documento,
              customer_email: user.email,
              key: 'phone',
              newValue: newData,
              oldValue: user.celular,
            });
            setIsLoading(false);
            updateUserStore();
            setShowWarning(true);
            setIsModalOpen(false);
            setCurrentStep('newInfo');
          })
          .catch((error) => {
            console.error('[ERROR UPDATING PHONE]', error);
          });
      })
      .catch((err) => {
        setWrongTokenErrorMessage(
          'Código incorreto. Confira os digítos e tente novamente.'
        );
        setIsLoading(false);
        setIsButtonDisabled(true);
      });
  };

  const handleSubmitEmail = (code: string) => {
    apiCheckGeneratedTokenCode
      .send({ code })
      .then((_) => {
        apiOnboardingCustomerUpdate
          .send({
            securityToken: code,
            email: {
              value: newData,
            },
          })
          .then((_) => {
            trackRedline.userAuthentication.userAttributeUpdated({
              devedorId: user.id,
              customer_id: user.documento,
              customer_email: user.email,
              key: 'email',
              newValue: newData,
              oldValue: user.email,
            });
            setIsLoading(false);
            updateUserStore();
            setShowWarning(true);
            setIsModalOpen(false);
            setCurrentStep('newInfo');
          })
          .catch((error) => {});
      })
      .catch((err) => {
        setWrongTokenErrorMessage(
          'Código incorreto. Confira os digítos e tente novamente.'
        );
        setIsButtonDisabled(true);
        setIsLoading(false);
      });
  };

  const verifyVericationCode = () => {
    setIsLoading(true);
    const tokenField = codeFormRef?.current?.getFieldValue('token')?.value;
    currFlow === 'celular'
      ? handleSubmitPhone(tokenField)
      : handleSubmitEmail(tokenField);
  };

  function getPhoneHint(phone: string) {
    const phoneHint = phone.replace(/^(\(\d{2}\))\s\d{5}/, '(**) *****');
    return phoneHint;
  }

  function getEmailHint(email: string) {
    const splittedEmail = email.split('@');
    const username = splittedEmail[0];
    const emailProvider = splittedEmail[1];

    const emailHint = `${username.charAt(0)}${'*'.repeat(
      username.length - 1
    )}@${emailProvider.charAt(0)}${'*'.repeat(username.length - 1)}`;
    return emailHint;
  }

  const dataHint =
    currFlow === 'celular' ? getPhoneHint(newData) : getEmailHint(newData);

  const handleResentToken = () => {
    const payload = {
      ...(currFlow === 'celular'
        ? { phone: removeMask(newData) }
        : { email: newData }),
    };
    apiGenerateTokenCode
      .send({
        ...payload,
      })
      .then(() => {
        setTimer(60);
      });
  };

  const onCompleteField = () => {
    setIsButtonDisabled(false);
  };

  return {
    timer,
    dataHint,
    verifyVericationCode,
    handleResentToken,
    wrongTokenErrorMessage,
    codeFormRef,
    isButtonDisabled,
    onCompleteField,
    isLoading
  };
};

export default useStepVerificationCode;
