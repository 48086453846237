import axios from 'axios';
import { SHApiProps, SHRequestEncoded, SHDecideResponseProps } from '../types';

export function useApiSortingHat({ baseURL, shKey }: SHApiProps) {
  const headers = {
    'Content-Type': 'application/json;',
    'x-api-key': shKey,
  };

  async function SHTrack(payload: SHRequestEncoded) {
    try {
      const url = `${baseURL}/track`;
      const options = { headers };

      const response = await axios.post(url, payload, options);
      return response;
    } catch (error) {
      console.error('[ERROR ON SH TRACK]', error);
    }
  }

  async function SHDecide({ data, token }: SHRequestEncoded) {
    try {
      const url = `${baseURL}/decide`;
      const payload = { data };

      const header = token
        ? { ...headers, Authorization: `Bearer ${token}` }
        : headers;

      const options = { headers: header };

      const response = await axios.post<SHDecideResponseProps>(
        url,
        payload,
        options
      );
      return response;
    } catch (error) {
      console.error('[ERROR ON SH DECIDE]', error);
    }
  }

  return {
    SHTrack,
    SHDecide,
  };
}
