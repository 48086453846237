import useApiOnboarding from '@admission/apis/onboarding/useApiOnboarding';
import { useUserStore } from '@common/store/useUserStore';
import useApiIdentity from '@common/apis/identity/useApiIdentity';
import { removeMask } from '@libs/utils/masks/cpf';
import { useEffect, useRef, useState } from 'react';
import { FormInstance } from 'houseform';
import { z } from 'zod';

interface StepNewInfoProps {
  setCurrentStep: (step: 'newInfo' | 'verificationCode') => void;
  currFlow: 'celular' | 'e-mail';
  setNewData: (newData: string) => void;
  isOpen: boolean;
}
const useStepNewInfo = ({
  setCurrentStep,
  currFlow,
  setNewData,
  isOpen,
}: StepNewInfoProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [inputErrorMessage, setInputErrorMessage] = useState('');
  const [isUpdateButtonDisabled, setIsUpdateButtonDisabled] = useState(true);

  const { user } = useUserStore();
  const { apiOnboardingCheckPhone, apiGenerateTokenCode } = useApiOnboarding();
  const { apiIdentityCheckEmail } = useApiIdentity();

  const formRef = useRef<FormInstance>(null);

  const isConfirmationInputValid = z
    .string()
    .refine((val) => fieldsMatch(val), {
      message: `Esse ${currFlow} é inválido. Verifique e digite novamente`,
    });

  const fieldsMatch = (val: string) => {
    const field = currFlow === 'celular' ? 'phone' : 'email'
    const formValue = formRef.current?.getFieldValue(field)?.value;

    const fieldsAreEmpty = val === '' || formValue === '';

    if (!fieldsAreEmpty && val === formValue) {
      setIsUpdateButtonDisabled(false);
      return true;
    }
    setIsUpdateButtonDisabled(true);
    return false;
  };

  const checkPhone = () => {
    const cellphone = formRef.current?.getFieldValue('phone')?.value;

    apiOnboardingCheckPhone
      .send({ document: user.documento, phone: cellphone } as any)
      .then(() => {
        apiGenerateTokenCode
          .send({
            phone: removeMask(cellphone),
          })
          .then(() => {
            setNewData(cellphone);
            setCurrentStep('verificationCode');
          });
      })
      .catch((err) => {
        const isAlreadyInUse =
          err.fields?.[0].message.includes('já cadastrado');
        if (isAlreadyInUse)
          setInputErrorMessage('Este celular já está cadastrado.');
        setIsLoading(false);
      });
  };

  const checkEmail = () => {
    const email = formRef.current?.getFieldValue('email')?.value;

    apiIdentityCheckEmail
      .send({ document: user.documento, email: email } as any)
      .then(() => {
        apiGenerateTokenCode
          .send({
            email: email,
          })
          .then(() => {
            setNewData(email);
            setCurrentStep('verificationCode');
          });
      })
      .catch((err) => {
        const isAlreadyInUse =
          err.fields?.[0].message.includes('já cadastrado');
        if (isAlreadyInUse)
          setInputErrorMessage('Este e-mail já está cadastrado.');
        setIsLoading(false);
      });
  };

  const handleDataUpdate = () => {
    setIsLoading(true);
    currFlow === 'celular' ? checkPhone() : checkEmail();
  };

  useEffect(() => {
    if (!isOpen) {
      formRef.current?.reset();
    }
  }, [isOpen]);

  return {
    handleDataUpdate,
    isLoading,
    inputErrorMessage,
    formRef,
    isConfirmationInputValid,
    isUpdateButtonDisabled,
  };
};

export default useStepNewInfo;
