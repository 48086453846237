import constants from "@common/constants";
import currentInstallmentIsDebitAccount from "./currentInstallmentIsDebitAccount";

const getPaymentDisplayOptions = ({
  agreement,
  installment,

  paymentStatus,
  singlePaymentMethod,
  pixCode,
}: any) => {
  const isDebitAccount = currentInstallmentIsDebitAccount(
    agreement,
    installment
  );

  const pixPending = paymentStatus === 'pending';
  const pixPaid = paymentStatus === 'pixPaid';
  const isFirstInstallment = installment?.installmentNumber === 1;

  const { identificador } = agreement?.partner || {};
  const agreementProperties = agreement?.properties || [];

  const isItau = identificador === "itau";
  const isRecovery = identificador === "recovery";
  const isCarrefour = identificador === "carrefour";
  const isBradesco = identificador === 'bradesco';
  const isTim = identificador === 'tim';
  const isPortoSeguro = identificador === 'portoseguro';

  const currentMethodIsPix = agreement?.paymentMethod === 'PIX';
  const currentMethodIsBillet = agreement?.paymentMethod === 'BILLET';

  const hasEntryDebitAcc = (agreementProperties?.find(({ key }: any) => key === constants.debts.PROPERTIES.ENTRY)?.decimalValue ?? 1) > 0;
  const ruleBradesco = !hasEntryDebitAcc && isBradesco;

  const partnerWithExternalPix = isItau || isRecovery || isCarrefour || isTim || isPortoSeguro;
  const isOnlyPixPayment = singlePaymentMethod && currentMethodIsPix;

  const currentDueDate = new Date(installment.dueDate);
  const isExpiredInstallment = currentDueDate < new Date();

  const validDateForDisplayingPix = partnerWithExternalPix || !isExpiredInstallment;

  const showPix = pixPending && validDateForDisplayingPix && (
    (isOnlyPixPayment && isFirstInstallment && isItau) ||
    (isOnlyPixPayment && !isItau) ||
    (!singlePaymentMethod && !!pixCode)
  );

  const showBillet = (
    (!pixPaid && !ruleBradesco && isFirstInstallment && !singlePaymentMethod) ||
    (!pixPaid && !isFirstInstallment && !singlePaymentMethod) ||
    (singlePaymentMethod && currentMethodIsBillet) ||
    (isItau && currentMethodIsPix && !isFirstInstallment)
  );

  return {
    showPix: showPix && !isDebitAccount,
    showBillet: showBillet && !isDebitAccount,
    isDebitAccount
  };
};

export default getPaymentDisplayOptions;
