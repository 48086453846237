import { lazy, useEffect } from 'react';
import { Route, useNavigate } from 'react-router-dom';
import { pageNames } from '@common/routes/pageNames';
import { offerCategory } from '@common/constants/offerCategory';

const Offers = lazy(() => import('@hub/pages/Offers'));
const Details = lazy(() => import('@hub/pages/Details'));

const LoansFallback = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate(pageNames.offers.path, { state: { key: offerCategory.LOANS } });
  }, []);
  return <></>;
};

export function Router() {
  return (
    <>
      <Route path={pageNames.offers.path} element={<Offers />} />
      <Route path={pageNames.loans.path} element={<LoansFallback />} />
      <Route path={pageNames.details.path} element={<Details />} />;
    </>
  );
}
