import isMobile from '@libs/utils/helpers/isMobile';
import './styles.scss';

const PaymentSectionSkeleton = () => {
  const deskSkeletonWidth = [215, 520, 398, 214, 520, 398];
  const mobileSkeletonWidth = [215, '100%', '100%', 214, '100%', '100%'];

  const widths = isMobile() ? mobileSkeletonWidth : deskSkeletonWidth;

  return widths.map((skeletonWidth, index) => {
    const hasBiggerMargin = index === 2;
    return (
      <div
        key={`skeleton-key-${skeletonWidth}-${index}`}
        className="polling-skeleton"
        style={{
          width: skeletonWidth,
          ...(hasBiggerMargin && { marginBottom: '24px' }),
        }}
      />
    );
  });
};

export default PaymentSectionSkeleton;
