import React, { useEffect, CSSProperties, useState, useRef } from 'react';
import { useAds } from '../../useAds';
import { OutOfPageTypes } from '../../constants';
import { debounce } from '../../utils';

// Define device types
type DeviceType = 'desktop' | 'tablet' | 'mobile' | 'all';

interface UnderBannerSlotProps {
  path: string;
  className?: string;
  styles?: CSSProperties;
  showOn?: DeviceType | DeviceType[];
}

const UnderBannerSlotComponent = ({
  path,
  className,
  styles,
  showOn = 'all',
}: UnderBannerSlotProps) => {
  const [currentDevice, setCurrentDevice] = useState<DeviceType>('desktop');

  const slotRef = useRef<googletag.Slot | null>(null);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  const { createOutOfPageSlot, destroySlot } = useAds();

  // Determine if ad should be shown on current device
  const shouldShowAd = (): boolean => {
    if (showOn === 'all') return true;
    if (Array.isArray(showOn)) return showOn.includes(currentDevice);
    return showOn === currentDevice;
  };

  // Detect device type based on window width
  useEffect(() => {
    const detectDevice = debounce(() => {
      console.log('detectDevice');
      const width = window.innerWidth;
      if (width < 768) setCurrentDevice('mobile');
      else if (width < 1024) setCurrentDevice('tablet');
      else setCurrentDevice('desktop');
    }, 250);

    // Initial detection
    detectDevice();

    // Update on resize
    window.addEventListener('resize', detectDevice);
    return () => window.removeEventListener('resize', detectDevice);
  }, []);

  useEffect(() => {
    // Clean up existing resources
    const cleanupResources = () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }

      if (slotRef.current) {
        destroySlot(slotRef.current);
        slotRef.current = null;
      }
    };

    // If we shouldn't show the ad, clean up and return early
    if (!shouldShowAd()) {
      cleanupResources();
      return;
    }

    // Clean up before creating a new slot
    cleanupResources();

    let adPath = path;
    if (import.meta.env.VITE_ENV === 'dev')
      adPath = '/6355419/Travel/Europe/France/Paris';

    const slot = createOutOfPageSlot(adPath, OutOfPageTypes.BOTTOM_ANCHOR);
    if (!slot) return;

    // Store slot reference
    slotRef.current = slot;

    // Retry to get element until it's available
    intervalRef.current = setInterval(() => {
      const element = document.getElementById(slot.getSlotElementId());

      if (element) {
        // Apply custom class if provided
        if (className) element.className = className;

        // Apply custom styles if provided
        if (styles) {
          Object.entries(styles).forEach(([property, value]) => {
            element.style[property as any] = value as string;
          });
        }

        if (intervalRef.current) {
          clearInterval(intervalRef.current);
          intervalRef.current = null;
        }
        return;
      }
    }, 1000);

    // Cleanup on unmount or dependencies change
    return cleanupResources;
  }, [currentDevice]);

  return null;
};

export const UnderBannerSlot = React.memo(UnderBannerSlotComponent);
