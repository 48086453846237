import React, { useEffect, useRef, useState } from 'react';
import useApiAgreements from '@dues/apis/agreements/useApiAgreements';
import { useNavigate } from 'react-router-dom';
import { useRedline } from '@libs/redline';
import { pageNames } from '@common/routes/pageNames';
import { legacyBase64Encrypt } from '@libs/utils/helpers/base64';
import { AgreementResponseProps } from '@dues/apis/agreements/types';
import constants from '@common/constants';

import * as AS from '../services';

type AgreementsType = AgreementResponseProps[] | [] | undefined;
export type AgreementsServices = typeof AS;

export const initialValuesAgreementsContext = {
  agreementsContext: {
    agreements: undefined as AgreementsType,
    agreementsLoading: false,
    hasActiveAgreement: false,
    errorListingAgreements: false,
    refCopiedWarning: { current: null } as React.MutableRefObject<HTMLElement | null>,
    hooks: {
      getAgreements: () => {},
      redirectToAgreementDetails: (agreement: any) => {},
    },
    services: AS as AgreementsServices,
  },
}

export type AgreementsContextTypes = typeof initialValuesAgreementsContext.agreementsContext;

const useAgreementsContext = (): AgreementsContextTypes => {
  const navigate = useNavigate();

  const { track: trackRedline } = useRedline();
  const { apiGetAgreements } = useApiAgreements();

  const [agreements, setAgreements] = useState<AgreementsType>(AS.getAgreementsFromLS());
  const [agreementsLoading, setAgreementsLoading] = useState<boolean>(!AS.getAgreementsFromLS());
  const [errorListingAgreements, setErrorListingAgreements] = useState<boolean>(false);
  const [hasActiveRequest, setHasActiveRequest] = useState<boolean>(false)

  const refCopiedWarning = useRef<HTMLElement | null>(null);

  const [hasActiveAgreement, sethasActiveAgreement] = useState<boolean>(() => {
    const agreementsInCache = AS.getAgreementsFromLS();
    return agreementsInCache && AS.hasActiveAgreement(agreementsInCache)
  })

  const [usedTheAgreementsCache, setUsedTheAgreementsCache] = useState(false);

  const getAgreements = () => {
    if (agreements && usedTheAgreementsCache && !errorListingAgreements) return;

    if (!usedTheAgreementsCache && agreements) {
      setUsedTheAgreementsCache(true)
    } else {
      setAgreementsLoading(true);
    };

    if(hasActiveRequest) return
    setHasActiveRequest(true)

    apiGetAgreements
      .send()
      .then((responseData: AgreementResponseProps[]) => {
        const isResponseArray = Array.isArray(responseData);
        const agreementsList = isResponseArray ? responseData : [];

        const agreementsWithUpdatedSituations = AS.updateAgreementsSituations(agreementsList);
        const sortedAgreements = AS.sortAgreementsByActive(agreementsWithUpdatedSituations);

        const hasActiveAgreement = AS.hasActiveAgreement(sortedAgreements);

        setAgreements(sortedAgreements);
        sethasActiveAgreement(hasActiveAgreement);
        setAgreementsLoading(false);
        trackRedline.agreements.agreementsListLoaded(sortedAgreements)

        const responseEncrypt = legacyBase64Encrypt(JSON.stringify(sortedAgreements));
        localStorage.setItem(constants.debts.AGREEMENTS_RES, responseEncrypt);

        setErrorListingAgreements(false);
        setHasActiveRequest(false)
      })
      .catch((error: any) => {
        trackRedline.agreements.agreementListingErrored({
          errorDetails: error?.statusText,
          errorMessage: error?.message,
          errorType: error?.status,
        });

        console.error('[GET AGREEMENTS]:', error);

        setHasActiveRequest(false)
        setErrorListingAgreements(true);
        setAgreementsLoading(false);
      });
  };

  const redirectToAgreementDetails = (
    currentAgreement: AgreementResponseProps
  ) => {
    trackRedline.agreements.agreementClicked(currentAgreement).finally(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      navigate(`${pageNames.myAgreementsDetail.path.replace(':agreementId', `${currentAgreement.id}`)}`)
    });
  };

  useEffect(() => {
    const triggerOnlyOnPaths = ['minhas-dividas', 'meus-acordos'];
    const currentPath = window?.location?.pathname;
    const currentUrlisValidForTrigger = triggerOnlyOnPaths.some((path) => currentPath.includes(path));

    if (currentUrlisValidForTrigger) getAgreements();

  }, [window?.location?.pathname]);


  return {
    agreements,
    agreementsLoading,
    hasActiveAgreement,
    errorListingAgreements,
    refCopiedWarning,

    hooks: {
      getAgreements,
      redirectToAgreementDetails,
    },

    services: AS,
  }
}

export default useAgreementsContext
