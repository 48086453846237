import { Button, LazyImage } from '@consumidor-positivo/aurora';
import NotFound404 from '@common/assets/not-found.svg';

import { pageNames } from '@common/routes/pageNames';
import './styles.scss';

type SomethingIsWrongProps = {
  image?: string;
  imageAlt?: string;
  iluSize?: number;
};

export default function SomethingIsWrong({
  image = NotFound404,
  imageAlt = 'Ilustração de várias pessoas em frente a um número 404, simbolizando que houve um erro e a página não pôde ser encontrada',
  iluSize = 380,
}: SomethingIsWrongProps) {
  return (
    <div className="something-is-wrong">
      <div className="something-is-wrong__infos">
        <h2>Ops!</h2>
        <h3>Algo deu errado!</h3>
        <Button as="a" href={pageNames.myDebts.path}>
          Voltar para pagina inicial
        </Button>
      </div>
      <div className="something-is-wrong__image">
        <LazyImage src={image} alt={imageAlt} width={iluSize} />
      </div>
    </div>
  );
}
