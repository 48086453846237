import { unmask } from 'remask';
import { SortingDebtReqProps } from './types';
import { getAnonymousID, getSessionID } from 'redline-client-sdk';

export const transformTrackPayload = (
  { customerIdHash, document }: SortingDebtReqProps,
) => ({
  customerIdHash: customerIdHash,
  documento: unmask(document),
  project: 'react',
  session_id: getSessionID(),
  anonymous_id: getAnonymousID(),
  application_source: 'ac-app',
});
