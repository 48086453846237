import { dateToLocale } from '@libs/utils/helpers/dateToLocale';
import {
  currencyToLocale,
  separateValueParts,
} from '@libs/utils/helpers/currencyToLocale';
import { getPartnerImage } from '@libs/utils/helpers/getPartnerImage';

import constants from '@common/constants';
import { CurrentPartner, getCurrentPartner } from './helpers';

import * as T from './types';
import {
  Propriedade,
  SimulatedDebt,
  SimulatedPaymentMethod,
  SimulatedPaymentOption,
} from '@common/apis/simulated-debt/types';
import {
  ARC4U_IDENTIFIER,
  ARC4U_PARTNER_NAME,
  MAGALU_PARTNER_NAME,
} from '@common/constants/hard-coded-partners';
import useLuizacredLogo from '../useLuizacredLogo';

export const useDebts = () => {
  const handlePaymentMeth = (
    debt: SimulatedDebt,
    paymentMethod: SimulatedPaymentMethod,
    qtdParcela: number,
    currentPartner: CurrentPartner,
    paymentOption: SimulatedPaymentOption
  ) => {
    const { CHANGE_MODALITY_AVAILABLE } = constants.debts.PROPERTIES;
    const { PARTNERS_WITH_SINGLE_PAY_METHOD } = constants.debts;
    const { bankAccounts, propriedades: debtProperties, partner } = debt || {};
    const paymentOptionProperties = paymentOption?.propriedades || []

    const debitInAccSelected = paymentMethod?.id === 'DEBIT_ACCOUNT';
    const hasInstallments = qtdParcela > 1;
    const hasBankAccount = bankAccounts?.length > 0;

    const hasDebtInAccount =
      debtProperties?.find(
        (prop) => prop.chave === constants.debts.PROPERTIES.DEBIT_ACCOUNT
      )?.valorBoolean ?? false;

    const hasEntryForDebitInAcc =
      (paymentOptionProperties?.find(
        (prop) => prop.chave === constants.debts.PROPERTIES.ENTRY
      )?.valorDecimal ?? 1) > 0;

    const singlePaymentMethod = PARTNERS_WITH_SINGLE_PAY_METHOD?.includes(
      partner?.partnerIdentifier
    );

    const isDebitInAccSelected = debitInAccSelected && hasInstallments;
    const debitInAccountRequired = hasDebtInAccount && hasInstallments;
    const hasBanksForDebitInAccount = hasBankAccount && hasInstallments;

    const showDebitInAccSelector =
      isDebitInAccSelected ||
      debitInAccountRequired ||
      hasBanksForDebitInAccount;

    const isBradesco = currentPartner.isBradesco;
    const isDebitInAccountAndNotBradesco =
      (debitInAccountRequired && !isBradesco) ||
      (hasBanksForDebitInAccount && !isBradesco);
    const hasDebitInAccountEntry =
      debitInAccountRequired && hasEntryForDebitInAcc;

    const { valorBoolean: changeModalityAvailable = false } =
      paymentOptionProperties?.find(
        (prop) => prop.chave === CHANGE_MODALITY_AVAILABLE
      ) || {};

    return {
      hasBankAccount,
      hasEntryForDebitInAcc,
      singlePaymentMethod,

      debitInAccountRequired,
      showDebitInAccSelector,

      isDebitInAccountAndNotBradesco,
      hasDebitInAccountEntry,

      changeModalityAvailable,
    };
  };

  // neon
  const getSecondInstallmentValue = (
    propriedades: Propriedade[],
    valorParcela: number
  ): any => {
    const neonInstallmentObj = propriedades?.find(
      (prop: any) =>
        prop.chave === constants.debts.PROPERTIES.FIRST_INSTALLMENT_VALUE
    );

    if (neonInstallmentObj) {
      const valueString = neonInstallmentObj.valorString;
      const numericValue = Number(valueString);

      const valueToBeUsed =
        numericValue >= valorParcela ? numericValue : valorParcela;

      if (!isNaN(numericValue)) {
        return separateValueParts(valueToBeUsed);
      }
    }

    return undefined;
  };

  const createInstallmentsDesc = (option: SimulatedPaymentOption) => {
    const { qtdParcela, valorEntrada, valorParcela, propriedades } =
      option || {};

    const secondInstallmentNeon = getSecondInstallmentValue(
      propriedades,
      valorParcela
    );
    const hasEntry = valorParcela !== valorEntrada;
    const valueFormat = currencyToLocale(valorParcela);

    const valueFormatToBeUsed = secondInstallmentNeon
      ? secondInstallmentNeon.valuePTBR
      : valueFormat;

    if (qtdParcela === 1) {
      return `À vista ${valueFormat}`;
    }

    const installmentCount = hasEntry ? qtdParcela - 1 : qtdParcela;
    return `${installmentCount}x de ${valueFormatToBeUsed}`;
  };

  const getNumberInstallments = ({
    hasEntry,
    qtdParcela,
  }: {
    hasEntry: boolean;
    qtdParcela: number;
  }): number => {
    if (hasEntry) return qtdParcela - 1;

    return qtdParcela;
  };

  const createDebtData = ({
    debt,
    paymentOption,
    paymentMethod,
  }: T.PropsCreateDebtData): T.DebtData => {
    const currentPartner = getCurrentPartner(debt?.partner?.partnerIdentifier);
    const props = debt?.propriedades || [];

    const { showLogoMagalu, currentLogoIdentifier } = useLuizacredLogo(
      debt?.partner?.identifier,
      debt?.partner?.partnerIdentifier,
      debt?.origem,
      debt?.produto,
    );

    const isArc = props.some((prop) => prop.chave === 'ARC_DEBT');
    const currentPartnerName = showLogoMagalu
      ? MAGALU_PARTNER_NAME
      : debt?.partner?.name;
    const name = isArc ? ARC4U_PARTNER_NAME : currentPartnerName;

    const partnerImage = getPartnerImage(
      isArc ? ARC4U_IDENTIFIER : currentLogoIdentifier
    );

    const {
      currentDebtValue,
      valorEntrada,
      valorParcela,
      qtdParcela,
      percentualDesconto,
      propriedades,
      valorAcordo,
    } = paymentOption || {};

    const percentage = percentualDesconto === 1 ? 0.99 : percentualDesconto;
    const formattedPercentage = Math.floor(percentage * 100);
    const isDiscountTenOrMore = formattedPercentage >= 10;

    const secondInstallmentNeon = getSecondInstallmentValue(
      propriedades,
      valorParcela
    );
    const valueFormat = separateValueParts(valorParcela);

    const valueFormatToBeUsed = secondInstallmentNeon
      ? secondInstallmentNeon
      : valueFormat;

    const hasEntry = valorEntrada !== valorParcela;
    const hasAbatement = debt?.typeSelected === 'with_abatement';

    const numberInstallments = getNumberInstallments({
      hasEntry,
      qtdParcela,
    });

    const visibleContract =
      !debt?.debtIsNegative && !debt?.origem && !!debt?.contrato;
    const visibleSource = !debt?.debtIsNegative && !!debt?.origem;

    const {
      hasBankAccount,
      hasEntryForDebitInAcc,
      singlePaymentMethod,
      debitInAccountRequired,
      showDebitInAccSelector,
      isDebitInAccountAndNotBradesco,
      hasDebitInAccountEntry,
      changeModalityAvailable,
    } = handlePaymentMeth(
      debt,
      paymentMethod,
      qtdParcela,
      currentPartner,
      paymentOption
    );

    return {
      currentPartner,

      identifier: debt?.partner?.identifier,
      partnerIdentifier: debt?.partner?.partnerIdentifier,
      debtIsNegative: debt?.debtIsNegative,

      documents: debt?.documents,
      msUrl: debt?.partner?.msUrl,

      visibleContract,
      visibleSource,

      display: {
        name: name,
        product: debt?.produto,
        contract: debt?.contrato,
        source: debt?.origem,
        partnerImage,
      },

      paymentOpt: {
        hasDicountVisible: isDiscountTenOrMore,
        hasEntry,
        hasPreviousValue: currentDebtValue !== valorParcela,
        isSinglePayment: qtdParcela === 1,
        hasInstallments: qtdParcela > 1,
        dueDate: dateToLocale(debt?.dataSimulacao),
        qtyInstallment: qtdParcela,
        valueAgreement: valorAcordo,

        display: {
          currentDebtValue: currencyToLocale(paymentOption?.currentDebtValue),
          entryValue: currencyToLocale(paymentOption?.valorEntrada),
          discount: {
            label: hasAbatement ? 'abatimento ' : 'desconto ',
            percentage: `${formattedPercentage}%`,
          },
          installments: {
            desc: createInstallmentsDesc(paymentOption),
            value: valueFormatToBeUsed,
            number: numberInstallments,
          },
          originalDebtValue: currencyToLocale(paymentOption?.valorDividaOriginal)
        },
      },
      paymentMeth: {
        id: paymentMethod?.id,
        name: paymentMethod?.name,

        hasBankAccount,
        hasEntryForDebitInAcc,
        singlePaymentMethod,

        showPixSelector: singlePaymentMethod, // TODO: remove
        debitInAccountRequired,
        showDebitInAccSelector,

        isDebitInAccountAndNotBradesco,
        hasDebitInAccountEntry,

        changeModalityAvailable,
      },
    };
  };

  return {
    createDebtData,
    createInstallmentsDesc,
  };
};
