export const dateToLocale = (value?: string) => {
  if (value) {
    const options: Intl.DateTimeFormatOptions = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      timeZone: 'UTC',
    };

    const dateObject = new Date(value);

    if (!isNaN(dateObject.getTime())) {
      const dateFormatted = new Intl.DateTimeFormat('pt-BR', options).format(
        dateObject
      );
      return dateFormatted;
    } else {
      console.error('Invalid date string provided:', value);
    }
  }

  return new Date().toLocaleDateString('pt-BR');
};
