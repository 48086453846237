import Cookies from 'js-cookie';
import Base64 from 'crypto-js/enc-base64';

export const getCookie = (
  key: string,
  decrypt?: boolean
): string | undefined => {
  const valueEncoded = Cookies.get(key);

  return decrypt && valueEncoded
    ? Base64.parse(valueEncoded).toString()
    : valueEncoded;
};
