import { AgreementResponseProps } from "@dues/apis/agreements/types";

/**
 * Checks if there are any active agreements in the provided list.
 *
 * @param {AgreementResponseProps[]} agreements - The list of agreements to check.
 * @returns {boolean} True if there is at least one active agreement, false otherwise.
 */
const hasActiveAgreement = (agreements: AgreementResponseProps[]): boolean => {
  return agreements?.some(agreement => agreement?.situation === "ACTIVE") || false;
}

export default hasActiveAgreement;
