import { useLocation } from "react-router-dom";

import useAuth from "@common/hooks/useAuth";
import useGTM from "@common/hooks/useGTM";

import { useUserStore } from "@common/store/useUserStore";

import { getCookie } from "@libs/utils/helpers/getSafeCookies";

const useSuccessSignIn = () => {
  const location = useLocation();
	
	const { search } = location;
  const searchParams = new URLSearchParams(search);
	
	const {
		addUser: addUserStore,
		resetStore
	} = useUserStore();

	const { addAuthUserLocalStorage, logOut } = useAuth();

	const GTM = useGTM();

	const handleSuccessSignIn = (res: any) => {
		resetStore();
		logOut();
	
		if (res.data.user) {
			addUserStore(res.data.user);
		}
	
		addAuthUserLocalStorage(res);
	
		GTM.trigger.loginWithSuccess();
	
		return res;
	}

	return { 
		handleSuccessSignIn
	}
}

export default useSuccessSignIn;