import getAgreementInstallments from "./getAgreementInstallments";
import { AgreementResponseProps } from "@dues/apis/agreements/types";

/**
 * Sorts a list of agreements, prioritizing active agreements first,
 * then sorting active agreements by the current installment due date in descending order,
 * and inactive agreements by agreement date in descending order.
 *
 * @param {AgreementResponseProps[]} agreementsList - The list of agreements to be sorted.
 * @returns {AgreementResponseProps[]} The sorted list of agreements, or the original list if there are no agreements.
 */
const sortAgreementsByActive = (agreementsList: AgreementResponseProps[]) => {
  if (!agreementsList || agreementsList.length === 0) return agreementsList;

  return agreementsList.sort((a, b) => {
    const isActiveA = a?.situation === 'ACTIVE';
    const isActiveB = b?.situation === 'ACTIVE';

    // Prioritize ACTIVE agreements
    if (isActiveA && !isActiveB) return -1; // a is active, b is inactive
    if (!isActiveA && isActiveB) return 1;  // b is active, a is inactive

    if (isActiveA && isActiveB) {
      // Both are ACTIVE, sort by current installment due date (most recent first)
      const { currentInstallment: currentInstallmentA } = getAgreementInstallments(a);
      const { currentInstallment: currentInstallmentB } = getAgreementInstallments(b);
      return new Date(currentInstallmentB?.dueDate).getTime() - new Date(currentInstallmentA?.dueDate).getTime();
    }

    // Both are INACTIVE, sort by agreement date (most recent first)
    return new Date(b?.agreementDate).getTime() - new Date(a?.agreementDate).getTime();
  });
};




export default sortAgreementsByActive;
