import acInterceptor from '../acInterceptor';

import { useRequest } from 'alova';

import {
  ScoreKbaResponse,
  ScoreKbaQuestionResponseData,
  ScoreKbaAnswerResponseData,
  ScoreKbaAnswerReqProps,
  ScoreKbaValidateData,
  CheckEmailProps,
} from '@common/apis/identity/types';

const baseURL_AC_API = import.meta.env.VITE_AC_API;
const baseURL_AC_API_LEGACY = import.meta.env.VITE_AC_API_LEGACY;

const useApiIdentity = () => {
  const apiIdentityKbaScoreStatus = useRequest(
    acInterceptor({ baseURL: baseURL_AC_API }).Get<ScoreKbaResponse<ScoreKbaValidateData>>(
      '/marketplace/v1/app/kba/score/status',
      {
        localCache: null,
      }
    ),
    {
      immediate: false,
      force: true,
    },
  );

  const apiIdentityKbaScoreQuestion = useRequest(
    () =>
      acInterceptor({ baseURL: baseURL_AC_API }).Post<ScoreKbaResponse<ScoreKbaQuestionResponseData>>(
        '/marketplace/v1/app/kba/score',
      ),
    {
      immediate: false,
    }
  );

  const apiIdentityKbaScoreAnswer = useRequest(
    (payload: ScoreKbaAnswerReqProps) =>
      acInterceptor({ baseURL: baseURL_AC_API }).Post<ScoreKbaResponse<ScoreKbaAnswerResponseData>>(
        '/marketplace/v1/app/kba/score/answer',
        payload,
      ),
    {
      immediate: false,
    }
  );

  const apiIdentityCheckEmail = useRequest(
    (payload: CheckEmailProps) =>
      acInterceptor({ baseURL: baseURL_AC_API }).Post(
        '/marketplace/v1/public/customer/check-email',
        payload,
      ),
    {
      immediate: false,
    }
  );


  return {
    apiIdentityKbaScoreAnswer,
    apiIdentityKbaScoreQuestion,
    apiIdentityCheckEmail,
    apiIdentityKbaScoreStatus,
  };
};

export default useApiIdentity;
