import { useContext } from 'react';
import { SHContext } from './SHContext';

export function useSH() {
  const trackSH = useContext(SHContext);

  return {
    ...trackSH,
  };
}
