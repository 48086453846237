export function isDatadogInitialized(): boolean {
  return !!(
    typeof window !== 'undefined' &&
    window.DD_RUM &&
    typeof window.DD_RUM.addFeatureFlagEvaluation === 'function'
  );
}

export function addFeatureFlagEvaluation(
  name: string,
  variant: string,
  metadata?: Record<string, string>
) {
  if (isDatadogInitialized()) {
    window.DD_RUM?.addFeatureFlagEvaluation(name, variant, metadata);
  }
}
