import { DebtFilter, FormattedDebt } from '@common/contexts/DebtsContext/types';

// TODO - remove after migration
export const formatSDResponseCache = (response: any = {}) => {
  const logoNameMapper = {
    bancodobrasil: 'banco-do-brasil',
    'claro-fatura': 'claro',
    'claro-residencial': 'claro',
    clarogevenue: 'claro',
    credigy: 'crediativos',
    anhangueraead: 'anhanguera-ead',
    pagbank: 'pag-bank',
    portoseguro: 'porto-seguro',
    'riachuelo-v2': 'riachuelo',
    bvfinanceira: 'bv-financeira',
    dscards: 'di-santinni',
    'itau-rdhi': 'itau',
    brk: 'brk-ambiental',
    lojasrenner: 'lojas-renner',
    bancopan: 'banco-pan',
    'bradesco-eavm': 'bradesco',
    'bradescard-p1': 'bradescard',
    vivocyber: 'vivo',
  };
  const partnerSystems = response?.partnerSystems || [];
  const newPartnerSystems = partnerSystems.map((ps: any = {}) => {
    const identifier = ps?.partnerIdentifier;
    const logo = (logoNameMapper as any)?.[identifier] || identifier || '';
    return {
      ...ps,
      logo,
    };
  });

  return {
    ...response,
    partnerSystems: newPartnerSystems,
  };
};

export function formatDiscountFromMsg(msg: string) {
  const [discount] = msg?.match(/(\d{2})/) || [];
  if (discount) return +discount / 100;
  return 0;
}

export function getDebtDiscount(debt: FormattedDebt): number {
  const isRedirectPartner = !!debt?.partner?.isLeadRedirect;
  const paymentOption = debt?.paymentOptions?.[0];
  const discount = isRedirectPartner
    ? formatDiscountFromMsg(debt?.partner?.promoMessage)
    : paymentOption?.discountPercentage;

  const formattedDiscount = (discount || 0) * 100;

  return formattedDiscount;
}

/**
 * the filter id used in the url must match the partnerIdentifier used in the debts
 */
export function createPartnerFilter(id: string, label: string): DebtFilter {
  return {
    id,
    label,
    filterFn: (debts) => {
      return debts.filter((debt) => debt.partner.partnerIdentifier === id);
    },
  };
}
